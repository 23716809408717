import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Colors } from 'modules/theme';

const Text = styled.h2`
  display: flex;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-size: 22px;
  padding-top: ${props => props.paddingTop || 0}px;
  margin-top: ${props => props.marginTop || 0}px;
  margin-bottom: ${props => props.marginBottom || 0}px;
  margin-right: ${props => props.marginRight || 0}px;
  margin-left: ${props => props.marginLeft || 0}px;
  line-height: ${props => (props.lineHeight ? props.lineHeight : 'initial')};
  color: ${props => props.color || Colors.black};
  font-weight: ${props => props.weight || '350'};
  ${props => props.uppercase && 'text-transform: uppercase'};
  text-align: ${props => props.textAlign || 'initial'};
`;

const H2 = props => <Text {...props}>{props.children}</Text>;

H2.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
};

export default H2;
