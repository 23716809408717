import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Colors } from 'modules/theme';
import Input from 'components/InputText';
import Textarea from 'components/Textarea';
import Select from 'components/Select';
import ButtonText from 'components/ButtonText';
import H4 from 'components/H4';
import FormError from 'components/FormError';
import Line from 'components/Line';
import PropTypes from 'prop-types';

const Container = styled.div``;

const InputContainer = styled.div`
  margin: 20px 0px;
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  flex-direction: column;
`;

const Section = styled.div`
  margin-top: 25px;
  max-height: ${props => (props.isVisible ? '2000px' : '0')};
  opacity: ${props => (props.isVisible ? '1' : '0')};
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.4s ease-in-out;
`;

// New component for device type selection buttons at the top
const DeviceSelectionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 30px;
`;

// Better styling for the chlorine/chloramine selection
const ToggleButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${Colors.gray2};
  border-radius: 8px;
  padding: 4px;
  width: fit-content;
  margin-top: 10px;
`;

const ToggleButton = styled.button`
  background-color: ${props => (props.active ? Colors.white : 'transparent')};
  color: ${props => (props.active ? Colors.blue : Colors.gray8)};
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: ${props => (props.active ? '600' : '400')};
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: ${props => (props.active ? '0px 2px 4px rgba(0, 0, 0, 0.1)' : 'none')};
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${props => (props.active ? Colors.white : Colors.gray3)};
  }

  svg,
  i {
    margin-right: 6px;
  }
`;

// Add a new styled component for the fixed footer
const FixedFooter = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: white;
  padding: 15px 30px;
  border-top: 1px solid ${Colors.gray2};
  display: flex;
  justify-content: flex-end;
  z-index: 10;
`;

// Update the FilterCard component with more styling and a position indicator
const FilterCard = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  padding: 16px;
  margin-bottom: 12px;
  border-left: 4px solid ${Colors.lightBlue};
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
  
  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }
  
  &::before {
    content: "${props => props.position || ''}";
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${Colors.lightBlue};
    color: white;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 8px;
    border-bottom-left-radius: 8px;
  }
`;

const FilterTitle = styled.div`
  color: ${Colors.black};
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 6px;
  padding-right: 40px; /* Make room for the position indicator */
`;

const FilterCapacity = styled.div`
  color: ${Colors.gray8};
  font-size: 13px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px dashed #f0f0f0;

  i {
    margin-right: 6px;
    color: ${Colors.lightBlue};
    font-size: 12px;
  }
`;

// Add a new styled component for filter cards grid
const FilterCardsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
  margin-top: 20px;
`;

const FilterCountBadge = styled.div`
  background-color: ${Colors.blue};
  color: white;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 8px;
  border-radius: 12px;
  display: inline-block;
  margin-left: 10px;
`;

export default class ConfigurationForm extends Component {
  state = {
    waterFilterHeads: [],
    reverseOsmosisFilterHeads: [],
    showWaterFilter: false,
    showReverseOsmosis: false,
    showWaterSoftener: false,
  };

  componentDidMount() {
    const { initialValues } = this.props;
    const { reverseOsmosis, waterFilter, waterSoftener } = initialValues;
    if (waterFilter.water_filter_config_id) {
      let index = 0;
      const configurations = this.props.waterFilterConfigurationsForFilter;
      const currentConfig = configurations.find(
        config => config.value === waterFilter.water_filter_config_id,
      );

      const waterFilterHeads = { ...currentConfig.waterFilters };

      waterFilterHeads.flowMeters = waterFilterHeads.SpotsWaterFilters.map(
        ({ id, Catridge, position }) => ({
          spot_water_filter_head_id: id,
          position,
          catridge_name: Catridge.name,
          chlorine_capacity: Catridge.chlorine_capacity,
          chloramine_capacity: Catridge.chloramine_capacity,
          type: '',
          name: '',
          key: index++,
        }),
      );

      this.setState({
        waterFilterHeads,
        showWaterFilter: true,
      });
    }

    if (reverseOsmosis.ro_filter_config_id) {
      let index = 0;
      const configurations = this.props.reverseOsmosisConfigurationsForFilter;
      const currentConfig = configurations.find(
        config => config.value === reverseOsmosis.ro_filter_config_id,
      );

      const reverseOsmosisFilterHeads = { ...currentConfig.reverseOsmosisFilters };

      reverseOsmosisFilterHeads.flowMeters = reverseOsmosisFilterHeads.SpotsReverseOsmosisFilters.map(
        ({ id, Catridge, position }) => ({
          spot_reverse_osmosis_filter_id: id,
          position,
          catridge_name: Catridge.name,
          chlorine_capacity: Catridge.chlorine_capacity,
          chloramine_capacity: Catridge.chloramine_capacity,
          type: 'N/A',
          name: `${position} - ${Catridge.name}`,
          key: index++,
        }),
      );
      this.setState({ reverseOsmosisFilterHeads, showReverseOsmosis: true });
    }
    if (waterSoftener.softener_configuration_id) {
      this.setState({ showWaterSoftener: true });
    }
  }

  submit = values => {
    const { onSubmit } = this.props;
    const nextTab = 'Installer';
    onSubmit({ ...values }, nextTab);
  };

  backStep = values => {
    const { onSubmit } = this.props;
    const nextTab = 'Details';
    onSubmit({ ...values }, nextTab);
  };

  getBrineDrumId = evt => {
    const { softenerConfigurationsForFilter } = this.props;
    const value = parseInt(evt.target.value, 10);

    const currentConfig = softenerConfigurationsForFilter.find(config => config.value === value);

    return currentConfig ? currentConfig.softener.brine_drum_id : null;
  };

  setFlowMeters = (evt, field, configuration) => {
    const value = parseInt(evt.target.value, 10);
    const configurations = this.props[configuration];

    const currentConfig = configurations.find(config => config.value === value);

    this.setState({
      [field]: currentConfig,
    });

    if (field === 'waterFilterHeads') {
      let index = 0;
      const waterFilterHeads = { ...currentConfig.waterFilters };

      waterFilterHeads.flowMeters = waterFilterHeads.SpotsWaterFilters.map(
        ({ id, Catridge, position }) => ({
          spot_water_filter_head_id: id,
          position,
          catridge_name: Catridge.name,
          chlorine_capacity: Catridge.chlorine_capacity,
          chloramine_capacity: Catridge.chloramine_capacity,
          type: '',
          name: '',
          key: index++,
        }),
      );

      this.setState({ waterFilterHeads });

      return waterFilterHeads.flowMeters.map(({ spot_water_filter_head_id, type, name }) => ({
        spot_water_filter_head_id,
        type,
        name,
      }));
    }

    if (field === 'reverseOsmosisFilterHeads') {
      let index = 0;
      const reverseOsmosisFilterHeads = { ...currentConfig.reverseOsmosisFilters };
      reverseOsmosisFilterHeads.flowMeters = reverseOsmosisFilterHeads.SpotsReverseOsmosisFilters.map(
        ({ id, Catridge, position }) => ({
          spot_reverse_osmosis_filter_id: id,
          position,
          catridge_name: Catridge.name,
          chlorine_capacity: Catridge.chlorine_capacity,
          chloramine_capacity: Catridge.chloramine_capacity,
          type: 'N/A',
          name: `${position} - ${Catridge.name}`,
          key: index++,
        }),
      );

      this.setState({ reverseOsmosisFilterHeads });

      return reverseOsmosisFilterHeads.flowMeters.map(
        ({ spot_reverse_osmosis_filter_id, type, name }) => ({
          spot_reverse_osmosis_filter_id,
          type,
          name,
        }),
      );
    }
    return [];
  };

  renderWaterFilterHeads = (
    handleChange,
    { flowMeterOfWaterFilters, chlorine_or_chloramine },
    errors = {},
  ) => {
    const { waterFilterHeads } = this.state;
    const { waterFilterDisabled, reverseOsmosisDisabled, softenerDisabled } = this.props;

    const disabledStyle = { pointerEvents: 'none', opacity: 0.5 };
    const disabledStylesWf =
      waterFilterDisabled || reverseOsmosisDisabled || softenerDisabled ? disabledStyle : {};

    // Auto-populate fields with values for each flowMeter
    if (waterFilterHeads?.flowMeters?.length > 0) {
      waterFilterHeads.flowMeters.forEach((flowMeter, index) => {
        // Set default 'N/A' for type if not already set
        if (!flowMeterOfWaterFilters[flowMeter.key]?.type) {
          flowMeterOfWaterFilters[flowMeter.key].type = 'N/A';
        }

        // Set filter name based on position and cartridge name if not already set
        if (!flowMeterOfWaterFilters[flowMeter.key]?.name) {
          flowMeterOfWaterFilters[
            flowMeter.key
          ].name = `${flowMeter.position} - ${flowMeter.catridge_name}`;
        }
      });
    }

    const filterCount = waterFilterHeads.flowMeters ? waterFilterHeads.flowMeters.length : 0;

    return (
      <Container>
        <H4 color={Colors.gray8} style={{ marginTop: '20px', marginBottom: '5px' }}>
          Filter Cartridges <FilterCountBadge>{filterCount}</FilterCountBadge>
        </H4>
        <FilterCardsGrid>
          {waterFilterHeads.flowMeters.map(
            ({ position, catridge_name, chlorine_capacity, chloramine_capacity, key }, index) => (
              <FilterCard key={index} position={position}>
                <FilterTitle>{catridge_name}</FilterTitle>
                <FilterCapacity>
                  <i className="fas fa-tint" />
                  {`Capacity: ${
                    chlorine_or_chloramine === 'Chlorine' ? chlorine_capacity : chloramine_capacity
                  } gal.`}
                </FilterCapacity>
                {/* Hidden inputs to maintain form data structure */}
                <input
                  type="hidden"
                  name={`waterFilter.flowMeterOfWaterFilters.${key}.type`}
                  value="N/A"
                />
                <input
                  type="hidden"
                  name={`waterFilter.flowMeterOfWaterFilters.${key}.name`}
                  value={`${position} - ${catridge_name}`}
                />
              </FilterCard>
            ),
          )}
        </FilterCardsGrid>
      </Container>
    );
  };

  renderReverseOsmosisFilterHeads = (
    handleChange,
    { flowMeterOfROFilters, chlorine_or_chloramine },
    errors = {},
  ) => {
    const { reverseOsmosisFilterHeads } = this.state;
    const { waterFilterDisabled, reverseOsmosisDisabled, softenerDisabled } = this.props;

    const disabledStyle = { pointerEvents: 'none', opacity: 0.5 };
    const disabledStylesRo =
      waterFilterDisabled || reverseOsmosisDisabled || softenerDisabled ? disabledStyle : {};

    // Auto-populate fields with values for each flowMeter
    if (reverseOsmosisFilterHeads?.flowMeters?.length > 0) {
      reverseOsmosisFilterHeads.flowMeters.forEach((flowMeter, index) => {
        // Set default 'N/A' for type if not already set
        if (!flowMeterOfROFilters[flowMeter.key]?.type) {
          flowMeterOfROFilters[flowMeter.key].type = 'N/A';
        }

        // Set filter name based on position and cartridge name if not already set
        if (!flowMeterOfROFilters[flowMeter.key]?.name) {
          flowMeterOfROFilters[
            flowMeter.key
          ].name = `${flowMeter.position} - ${flowMeter.catridge_name}`;
        }
      });
    }

    const filterCount = reverseOsmosisFilterHeads.flowMeters
      ? reverseOsmosisFilterHeads.flowMeters.length
      : 0;

    return (
      <Container>
        <H4 color={Colors.gray8} style={{ marginTop: '20px', marginBottom: '5px' }}>
          RO Filter Cartridges <FilterCountBadge>{filterCount}</FilterCountBadge>
        </H4>
        <FilterCardsGrid>
          {reverseOsmosisFilterHeads.flowMeters.map(
            ({ position, catridge_name, chlorine_capacity, chloramine_capacity, key }, index) => (
              <FilterCard key={index} position={position}>
                <FilterTitle>{catridge_name}</FilterTitle>
                <FilterCapacity>
                  <i className="fas fa-tint" />
                  {`Capacity: ${
                    chlorine_or_chloramine === 'Chlorine' ? chlorine_capacity : chloramine_capacity
                  } gal.`}
                </FilterCapacity>
                {/* Hidden inputs to maintain form data structure */}
                <input
                  type="hidden"
                  name={`reverseOsmosis.flowMeterOfROFilters.${key}.type`}
                  value="N/A"
                />
                <input
                  type="hidden"
                  name={`reverseOsmosis.flowMeterOfROFilters.${key}.name`}
                  value={`${position} - ${catridge_name}`}
                />
              </FilterCard>
            ),
          )}
        </FilterCardsGrid>
      </Container>
    );
  };

  render() {
    const {
      initialValues,
      waterFilterConfigurationsForFilter,
      reverseOsmosisConfigurationsForFilter,
      softenerConfigurationsForFilter,
      waterFilterDisabled,
      reverseOsmosisDisabled,
      softenerDisabled,
    } = this.props;
    const { showWaterFilter, showWaterSoftener, showReverseOsmosis } = this.state;
    const disabledStyle = { pointerEvents: 'none', opacity: 0.5 };
    const disabledStylesWf =
      waterFilterDisabled || reverseOsmosisDisabled || softenerDisabled ? disabledStyle : {};
    const disabledStylesRo =
      waterFilterDisabled || reverseOsmosisDisabled || softenerDisabled ? disabledStyle : {};
    const disabledStylesSo =
      waterFilterDisabled || reverseOsmosisDisabled || softenerDisabled ? disabledStyle : {};

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          reverseOsmosis:
            showReverseOsmosis &&
            Yup.object().shape({
              chlorine_or_chloramine: Yup.string().required('Required'),
              flowMeterOfROFilters: Yup.array().of(
                Yup.object().shape({
                  name: Yup.string().required('Required'),
                  spot_reverse_osmosis_filter_id: Yup.string().required('Required'),
                  type: Yup.string().required('Required'),
                }),
              ),
              low_inlet_pressure: Yup.number().required('Required'),
              ro_filter_config_id: Yup.string().required('Required'),
            }),
          waterFilter:
            showWaterFilter &&
            Yup.object().shape({
              chlorine_or_chloramine: Yup.string().required('Required'),
              flowMeterOfWaterFilters: Yup.array().of(
                Yup.object().shape({
                  name: Yup.string().required('Required'),
                  spot_water_filter_head_id: Yup.string().required('Required'),
                  type: Yup.string().required('Required'),
                }),
              ),
              water_filter_config_id: Yup.string().required('Required'),
            }),
          waterSoftener:
            showWaterSoftener &&
            Yup.object().shape({
              description: Yup.string().required('Required'),
              softener_configuration_id: Yup.string().required('Required'),
            }),
        })}
        onSubmit={this.submit}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => {
          const resetWaterFilter = () => {
            this.setState({ showWaterFilter: false });
            setFieldValue('waterFilter.water_filter_config_id', '');
            setFieldValue('waterFilter.chlorine_or_chloramine', 'Chlorine');
            setFieldValue('waterFilter.flowMeterOfWaterFilters', []);
          };

          const resetReverseOsmosis = () => {
            this.setState({ showReverseOsmosis: false });
            setFieldValue('reverseOsmosis.ro_filter_config_id', '');
            setFieldValue('reverseOsmosis.chlorine_or_chloramine', 'Chlorine');
            setFieldValue('reverseOsmosis.flowMeterOfROFilters', []);
          };
          const resetSoftener = () => {
            this.setState({ showWaterSoftener: false });
            setFieldValue('waterSoftener.softener_configuration_id', '');
            setFieldValue('waterSoftener.description', '');
            setFieldValue('waterSoftener.brine_drum_id', null);
          };

          return (
            <Form style={{ margin: '30px', paddingBottom: '80px' }}>
              {/* Device selection buttons in a row at the top */}
              <DeviceSelectionRow>
                <ButtonText
                  width="auto"
                  text={showWaterFilter ? 'Water Filter Added' : 'Add Water Filter'}
                  type="button"
                  theme={showWaterFilter ? 'success' : 'primary'}
                  icon={showWaterFilter ? 'check-circle' : 'plus-circle'}
                  onClick={() =>
                    showWaterFilter ? resetWaterFilter() : this.setState({ showWaterFilter: true })
                  }
                  customStyles={
                    waterFilterDisabled || reverseOsmosisDisabled || softenerDisabled
                      ? disabledStyle
                      : {}
                  }
                />

                <ButtonText
                  width="auto"
                  text={showReverseOsmosis ? 'Reverse Osmosis Added' : 'Add Reverse Osmosis'}
                  type="button"
                  theme={showReverseOsmosis ? 'success' : 'primary'}
                  icon={showReverseOsmosis ? 'check-circle' : 'plus-circle'}
                  onClick={() =>
                    showReverseOsmosis
                      ? resetReverseOsmosis()
                      : this.setState({ showReverseOsmosis: true })
                  }
                  customStyles={
                    waterFilterDisabled || reverseOsmosisDisabled || softenerDisabled
                      ? disabledStyle
                      : {}
                  }
                />

                <ButtonText
                  width="auto"
                  text={showWaterSoftener ? 'Water Softener Added' : 'Add Water Softener'}
                  type="button"
                  theme={showWaterSoftener ? 'success' : 'primary'}
                  icon={showWaterSoftener ? 'check-circle' : 'plus-circle'}
                  onClick={() =>
                    showWaterSoftener ? resetSoftener() : this.setState({ showWaterSoftener: true })
                  }
                  customStyles={
                    waterFilterDisabled || reverseOsmosisDisabled || softenerDisabled
                      ? disabledStyle
                      : {}
                  }
                />
              </DeviceSelectionRow>

              {/* Water Filter Section */}
              <Section isVisible={showWaterFilter}>
                <Row>
                  <Column style={{ paddingRight: '30px', width: '33.33%' }}>
                    <H4 inputLabel color={Colors.gray8}>
                      Configuration type
                    </H4>
                    <InputContainer>
                      <Select
                        placeholder="Configuration type"
                        name="waterFilter.water_filter_config_id"
                        options={waterFilterConfigurationsForFilter}
                        value={values.waterFilter.water_filter_config_id}
                        style={disabledStylesWf}
                        onChange={evt => {
                          setFieldValue(
                            'waterFilter.flowMeterOfWaterFilters',
                            this.setFlowMeters(
                              evt,
                              'waterFilterHeads',
                              'waterFilterConfigurationsForFilter',
                            ),
                          );
                          handleChange(evt);
                        }}
                      />

                      <FormError
                        text={
                          errors.waterFilter &&
                          errors.waterFilter.water_filter_config_id &&
                          touched.waterFilter &&
                          touched.waterFilter.water_filter_config_id &&
                          errors.waterFilter.water_filter_config_id
                        }
                      />
                    </InputContainer>
                  </Column>
                </Row>

                <Row>
                  <Column style={{ width: '33.33%' }}>
                    <H4 inputLabel color={Colors.gray8}>
                      Water Type
                    </H4>
                    <ToggleButtonsContainer>
                      <ToggleButton
                        type="button"
                        active={values.waterFilter.chlorine_or_chloramine === 'Chlorine'}
                        onClick={() =>
                          setFieldValue('waterFilter.chlorine_or_chloramine', 'Chlorine')
                        }
                        disabled={waterFilterDisabled || reverseOsmosisDisabled || softenerDisabled}
                      >
                        <i
                          className="fas fa-check-circle"
                          style={{
                            opacity:
                              values.waterFilter.chlorine_or_chloramine === 'Chlorine' ? 1 : 0.3,
                          }}
                        />
                        Chlorine
                      </ToggleButton>
                      <ToggleButton
                        type="button"
                        active={values.waterFilter.chlorine_or_chloramine === 'Chloramine'}
                        onClick={() =>
                          setFieldValue('waterFilter.chlorine_or_chloramine', 'Chloramine')
                        }
                        disabled={waterFilterDisabled || reverseOsmosisDisabled || softenerDisabled}
                      >
                        <i
                          className="fas fa-check-circle"
                          style={{
                            opacity:
                              values.waterFilter.chlorine_or_chloramine === 'Chloramine' ? 1 : 0.3,
                          }}
                        />
                        Chloramine
                      </ToggleButton>
                    </ToggleButtonsContainer>
                  </Column>
                </Row>
                {values.waterFilter.flowMeterOfWaterFilters.length > 0 &&
                  this.renderWaterFilterHeads(handleChange, values.waterFilter, errors.waterFilter)}
              </Section>

              {/* Reverse Osmosis Section */}
              <Section isVisible={showReverseOsmosis}>
                <Row>
                  <Column style={{ paddingRight: '30px', width: '33.33%' }}>
                    <H4 inputLabel color={Colors.gray8}>
                      Configuration type
                    </H4>
                    <InputContainer>
                      <Select
                        placeholder="Configuration type"
                        name="reverseOsmosis.ro_filter_config_id"
                        options={reverseOsmosisConfigurationsForFilter}
                        value={values.reverseOsmosis.ro_filter_config_id}
                        onChange={evt => {
                          setFieldValue(
                            'reverseOsmosis.flowMeterOfROFilters',
                            this.setFlowMeters(
                              evt,
                              'reverseOsmosisFilterHeads',
                              'reverseOsmosisConfigurationsForFilter',
                            ),
                          );
                          handleChange(evt);
                        }}
                        style={disabledStylesRo}
                      />
                      <FormError
                        text={
                          errors.reverseOsmosis &&
                          errors.reverseOsmosis.ro_filter_config_id &&
                          touched.reverseOsmosis &&
                          touched.reverseOsmosis.ro_filter_config_id &&
                          errors.reverseOsmosis.ro_filter_config_id
                        }
                      />
                    </InputContainer>
                  </Column>
                  <Column style={{ marginRight: '30px', width: '33.33%' }}>
                    <InputContainer>
                      <H4 inputLabel color={Colors.gray8}>
                        Low inlet pressure
                      </H4>
                      <Input
                        placeholder="Low inlet pressure"
                        name="reverseOsmosis.low_inlet_pressure"
                        type="number"
                        value={values.reverseOsmosis.low_inlet_pressure}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <FormError
                        text={
                          errors.reverseOsmosis &&
                          errors.reverseOsmosis.low_inlet_pressure &&
                          touched.reverseOsmosis &&
                          touched.reverseOsmosis.low_inlet_pressure &&
                          errors.reverseOsmosis.low_inlet_pressure
                        }
                      />
                    </InputContainer>
                  </Column>
                </Row>

                <Row>
                  <Column style={{ width: '33.33%' }}>
                    <H4 inputLabel color={Colors.gray8}>
                      Water Type
                    </H4>
                    <ToggleButtonsContainer>
                      <ToggleButton
                        type="button"
                        active={values.reverseOsmosis.chlorine_or_chloramine === 'Chlorine'}
                        onClick={() =>
                          setFieldValue('reverseOsmosis.chlorine_or_chloramine', 'Chlorine')
                        }
                        disabled={waterFilterDisabled || reverseOsmosisDisabled || softenerDisabled}
                      >
                        <i
                          className="fas fa-check-circle"
                          style={{
                            opacity:
                              values.reverseOsmosis.chlorine_or_chloramine === 'Chlorine' ? 1 : 0.3,
                          }}
                        />
                        Chlorine
                      </ToggleButton>
                      <ToggleButton
                        type="button"
                        active={values.reverseOsmosis.chlorine_or_chloramine === 'Chloramine'}
                        onClick={() =>
                          setFieldValue('reverseOsmosis.chlorine_or_chloramine', 'Chloramine')
                        }
                        disabled={waterFilterDisabled || reverseOsmosisDisabled || softenerDisabled}
                      >
                        <i
                          className="fas fa-check-circle"
                          style={{
                            opacity:
                              values.reverseOsmosis.chlorine_or_chloramine === 'Chloramine'
                                ? 1
                                : 0.3,
                          }}
                        />
                        Chloramine
                      </ToggleButton>
                    </ToggleButtonsContainer>
                  </Column>
                </Row>

                {values.reverseOsmosis.flowMeterOfROFilters.length > 0 &&
                  this.renderReverseOsmosisFilterHeads(
                    handleChange,
                    values.reverseOsmosis,
                    errors.reverseOsmosis,
                  )}
              </Section>

              {/* Water Softener Section */}
              <Section isVisible={showWaterSoftener}>
                <Row>
                  <Column style={{ paddingRight: '30px', width: '33.33%' }}>
                    <H4 inputLabel color={Colors.gray8}>
                      Configuration type
                    </H4>
                    <InputContainer>
                      <Select
                        placeholder="Configuration type"
                        name="waterSoftener.softener_configuration_id"
                        options={softenerConfigurationsForFilter}
                        value={values.waterSoftener.softener_configuration_id}
                        onChange={evt => {
                          handleChange(evt);
                          setFieldValue('waterSoftener.brine_drum_id', this.getBrineDrumId(evt));
                        }}
                        style={disabledStylesSo}
                      />

                      <FormError
                        text={
                          errors.waterSoftener &&
                          errors.waterSoftener.softener_configuration_id &&
                          touched.waterSoftener &&
                          touched.waterSoftener.softener_configuration_id &&
                          errors.waterSoftener.softener_configuration_id
                        }
                      />
                    </InputContainer>
                  </Column>
                </Row>
                <Row>
                  <Column style={{ marginRight: '30px', paddingRight: '30px', width: '66.66%' }}>
                    <H4 inputLabel color={Colors.gray8}>
                      Description
                    </H4>
                    <InputContainer>
                      <Textarea
                        value={values.waterSoftener.description}
                        onChange={value => setFieldValue('waterSoftener.description', value)}
                        higlights={[]}
                        onBlur={handleBlur}
                        customStyles={disabledStylesSo}
                      />

                      <FormError
                        text={
                          errors.waterSoftener &&
                          errors.waterSoftener.description &&
                          touched.waterSoftener &&
                          touched.waterSoftener.description &&
                          errors.waterSoftener.description
                        }
                      />
                    </InputContainer>
                  </Column>
                </Row>
              </Section>

              <FixedFooter>
                <ButtonText
                  text="Back"
                  type="button"
                  theme="default"
                  width="120px"
                  customStyles={{ marginRight: '20px' }}
                  onClick={() => this.backStep(values)}
                />
                <ButtonText text="Next" theme="primary" type="submit" width="120px" />
              </FixedFooter>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

ConfigurationForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  reverseOsmosisConfigurationsForFilter: PropTypes.array,
  reverseOsmosisDisabled: PropTypes.bool,
  softenerConfigurationsForFilter: PropTypes.array,
  softenerDisabled: PropTypes.bool,
  waterFilterConfigurationsForFilter: PropTypes.array,
  waterFilterDisabled: PropTypes.bool,
};
