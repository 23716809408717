import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Colors } from 'modules/theme';

const Text = styled.h5`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-size: 14px;
  overflow: hidden;
  text-align: ${props => props.textAlign || 'center'};
  margin-top: ${props => props.marginTop || 0}px;
  margin-bottom: ${props => props.marginBottom || 0}px;
  margin-left: ${props => props.marginLeft || 0}px;
  margin-right: ${props => props.marginRight || 0}px;
  line-height: ${props => (props.lineHeight ? props.lineHeight : 'initial')};
  color: ${props => props.color || Colors.black};
  font-weight: ${props => props.weight || '350'};
  cursor: ${props => props.cursor || 'auto'};
  ${props => props.uppercase && 'text-transform: uppercase'};
`;

const H5 = props => <Text {...props}>{props.children}</Text>;

H5.propTypes = {
  children: PropTypes.node,
};

export default H5;
