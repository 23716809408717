import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

const IconButton = ({
  name,
  size = 33,
  fontSize = 15,
  color = '#333333',
  backgroundColor = '#F8F8F8',
  borderColor = '#E8E8E8',
  borderRadius = 9,
  onClick,
  hoverBackgroundColor = '#EFEFEF',
  style,
  ...restProps
}) => (
  <div
    style={{
      width: `${size}px`,
      height: `${size}px`,
      borderRadius: `${borderRadius}px`,
      backgroundColor,
      border: `1px solid ${borderColor}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      flexShrink: 0,
      transition: 'all 0.2s ease',
      ...style,
    }}
    onClick={onClick}
    onMouseOver={e => {
      e.currentTarget.style.backgroundColor = hoverBackgroundColor;
      e.currentTarget.style.transform = 'translateY(-1px)';
    }}
    onMouseOut={e => {
      e.currentTarget.style.backgroundColor = backgroundColor;
      e.currentTarget.style.transform = 'translateY(0)';
    }}
    {...restProps}
  >
    <Icon name={name} color={color} fontSize={fontSize} />
  </div>
);

IconButton.propTypes = {
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  borderRadius: PropTypes.number,
  color: PropTypes.string,
  fontSize: PropTypes.number,
  hoverBackgroundColor: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.number,
  style: PropTypes.object,
};

export default IconButton;
