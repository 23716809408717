import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import ButtonText from 'components/ButtonText';
import { InstallationStatus } from 'constants/index';
import H3 from 'components/H3';
import H4 from 'components/H4';
import H5 from 'components/H5';
import H2 from 'components/H2';
import Col from 'components/Col';
import Row from 'components/Row';
import Line from 'components/Line';
import Filter, { FilterGroup, FilterContainer } from 'components/Filter';
import Icon from 'components/Icon';
import IconButton from 'components/IconButton';
import BG from 'components/BG';
import ListEmpty from 'components/ListEmpty';
import Modal from 'components/Modal';
import PaginationButtons from 'components/PaginationButtons';
import PropTypes from 'prop-types';
import WF from './WF';
import WS from './WS';
import RO from './RO';
import ModalCsvFiles from './ModalCsvFiles';

const Container = styled.div``;

// Drawer components
const DrawerOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease, visibility 0.3s ease;
  will-change: opacity, visibility;
`;

const DrawerContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  height: 100%;
  background-color: #f9f9f9;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  z-index: 1055;
  transform: translateX(${props => (props.isVisible ? '0' : '100%')});
  transition: transform 0.5s ease-in-out;
  will-change: transform;
  overflow-y: auto;
`;

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #fefefe;
  border-bottom: 1px solid ${Colors.gray2};
`;

const DrawerContent = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
`;

const CardSection = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
`;

const CardTitle = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-top: 0;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f0f0;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
`;

const InfoRowWithToggle = styled(InfoRow)`
  .toggle-container {
    display: flex;
    align-items: center;
  }
`;

const InfoLabel = styled.span`
  color: ${Colors.gray8};
  font-size: 14px;
  font-weight: 400;
`;

const InfoValue = styled.span`
  color: #333;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
`;

const ColoredValue = styled(InfoValue)`
  color: ${props => props.color || '#333'};
`;

// Original styled components
const InstallationNotCompleteContainer = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
`;

const membraneLife = [
  { name: 'nocomplete', value: 50, fill: Colors.grayChart },
  { name: 'complete', value: 50, fill: Colors.blue },
];

// Add a function to format phone numbers
const formatPhoneNumber = phoneNumber => {
  // Remove all non-digit characters
  const cleaned = (`${ phoneNumber}`).replace(/\D/g, '');

  // Check if the input is valid
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${ match[1] }) ${ match[2] }-${ match[3]}`;
  }

  // If the phone number isn't in a standard format, return original
  return phoneNumber;
};

// Create a styled status label component
const StatusLabel = styled.span`
  color: ${props => (props.installed ? '#4CAF50' : '#FF0000')};
  font-size: 14px;
  font-weight: 500;
`;

// Create a styled Filter component that respects our color preferences
const ColoredFilter = styled(Filter)`
  .filter__control {
    color: ${props => (props.installed ? '#4CAF50' : '#FF0000')} !important;
    font-size: 14px;
    font-weight: 500;
  }

  .filter__single-value {
    color: ${props => (props.installed ? '#4CAF50' : '#FF0000')} !important;
  }

  .filter__option {
    color: #333;
    &.filter__option--is-selected {
      color: ${props => (props.value === 'true' ? '#4CAF50' : '#FF0000')};
    }
  }
`;

const CustomSelect = styled.select`
  background: transparent;
  border: none;
  color: ${props => (props.value === 'true' ? '#4CAF50' : '#FF0000')};
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 0px;
  padding-right: 25px;
  margin-right: -5px;

  &:focus {
    outline: none;
  }

  option {
    color: #333;
  }
`;

// Add a dropdown menu for Quick Actions
const QuickActionsDropdown = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownContent = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
  display: ${props => (props.isOpen ? 'block' : 'none')};
`;

const DropdownItem = styled.div`
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #333;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }

  i {
    margin-right: 8px;
  }
`;

// Update the Row component for the Actions Section
const ActionRow = styled.div`
  display: flex;
  align-items: center;
`;

// Add styled select dropdown for the breadcrumbs
const BreadcrumbWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: ${Colors.black};
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 320px;
  min-width: 150px;
  padding-right: 8px;
`;

const BreadcrumbSelect = styled.select`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
`;

export default class WaterFilterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterHeadSelected: '',
      showContactInfo: false,
      alertsModal: false,
      quickActionsOpen: false,
      editingDeviceId: false,
      newDeviceId: '',
      lastUpdated: null,
      lastUpdatedLoading: false,
      tdsValue: null,
      tdsLoading: false,
      modalDatePickerCsv: false,
      datePickerFromCsv: moment()
        .add(-7, 'days')
        .toDate(),
      datePickerToCsv: moment().toDate(),
      csvType: 1,
      csvTypeString: 'raw_data',
    };
  }

  switchByFilterHead = filterHead => {
    const { location, allAlerts, fetchAllAlerts, setDeviceId } = this.props;

    switch (filterHead) {
      case 'WaterFilterHead':
        return (
          <WF
            location={location}
            allAlerts={allAlerts}
            fetchAllAlerts={fetchAllAlerts}
            renderInstructionsModal={this.renderInstructionsModal}
            setDeviceId={setDeviceId}
            updateLastUpdated={this.updateLastUpdated}
            setLastUpdatedLoading={this.setLastUpdatedLoading}
          />
        );
      case 'ReverseOsmosisFilterHead':
        return (
          <RO
            location={location}
            membraneLife={membraneLife}
            renderInstructionsModal={this.renderInstructionsModal}
            allAlerts={allAlerts}
            fetchAllAlerts={fetchAllAlerts}
            setDeviceId={setDeviceId}
            updateLastUpdated={this.updateLastUpdated}
            setLastUpdatedLoading={this.setLastUpdatedLoading}
            updateTdsValue={this.updateTdsValue}
            setTdsLoading={this.setTdsLoading}
          />
        );
      case 'SoftenerFilterHead':
        return (
          <WS
            location={location}
            renderInstructionsModal={this.renderInstructionsModal}
            allAlerts={allAlerts}
            fetchAllAlerts={fetchAllAlerts}
            setDeviceId={setDeviceId}
            updateLastUpdated={this.updateLastUpdated}
            setLastUpdatedLoading={this.setLastUpdatedLoading}
          />
        );
      default:
        return (
          <InstallationNotCompleteContainer>
            <ListEmpty message="There are no devices configured" />
          </InstallationNotCompleteContainer>
        );
    }
  };

  renderContactInfoDrawer = () => {
    const { showContactInfo, editingDeviceId, newDeviceId } = this.state;
    const { location, role_id, relatedLocations } = this.props;
    const {
      brand_name,
      account_name,
      identificator,
      contact_first_name,
      contact_last_name,
      contact_email,
      contact_phone_number,
      full_address,
      installed,
      installed_date,
      WaterFilterHead,
      ReverseOsmosisFilterHead,
      SoftenerFilterHead,
    } = location;

    // Get the current active filter head for configuration details
    let activeFilterHead = null;
    let configInfo = null;
    let deviceType = null;
    let deviceId = null;
    let membraneChangeDate = null;

    if (ReverseOsmosisFilterHead) {
      deviceType = 'Reverse Osmosis';
      deviceId = ReverseOsmosisFilterHead.device_id;
      configInfo = ReverseOsmosisFilterHead.ReverseOsmosisFilterConfiguration?.name;
      membraneChangeDate = ReverseOsmosisFilterHead.last_membrane_change_date;
      activeFilterHead = ReverseOsmosisFilterHead;
    } else if (WaterFilterHead) {
      deviceType = 'Water Filter';
      deviceId = WaterFilterHead.device_id;
      configInfo = WaterFilterHead.WaterFilterConfiguration?.name;
      activeFilterHead = WaterFilterHead;
    } else if (SoftenerFilterHead) {
      deviceType = 'Softener';
      deviceId = SoftenerFilterHead.device_id;
      configInfo = SoftenerFilterHead.SoftenerConfiguration?.name;
      activeFilterHead = SoftenerFilterHead;
    }

    return (
      <>
        <DrawerOverlay
          isVisible={showContactInfo}
          onClick={() => this.switchModal('showContactInfo')}
        />
        <DrawerContainer isVisible={showContactInfo}>
          <DrawerHeader>
            <H3 color={Colors.black} weight={600}>
              {brand_name}
            </H3>
            <Icon
              name="times"
              color={Colors.gray}
              fontSize={20}
              onClick={() => this.switchModal('showContactInfo')}
              customStyle={{ cursor: 'pointer' }}
            />
          </DrawerHeader>
          <DrawerContent>
            <CardSection>
              <CardTitle>Location Details</CardTitle>

              <InfoRow>
                <InfoLabel>Account Name</InfoLabel>
                <InfoValue>{account_name}</InfoValue>
              </InfoRow>

              <InfoRow>
                <InfoLabel>Location ID</InfoLabel>
                <InfoValue style={{ fontFamily: 'Inter, sans-serif' }}>{identificator}</InfoValue>
              </InfoRow>

              <InfoRow>
                <InfoLabel>Address</InfoLabel>
                <InfoValue>{full_address}</InfoValue>
              </InfoRow>
            </CardSection>

            <CardSection>
              <CardTitle>Contact Information</CardTitle>

              <InfoRow>
                <InfoLabel>Name</InfoLabel>
                <InfoValue>{`${contact_first_name} ${contact_last_name}`}</InfoValue>
              </InfoRow>

              <InfoRow>
                <InfoLabel>Email</InfoLabel>
                <InfoValue>{contact_email}</InfoValue>
              </InfoRow>

              <InfoRow>
                <InfoLabel>Phone</InfoLabel>
                <InfoValue>{formatPhoneNumber(contact_phone_number)}</InfoValue>
              </InfoRow>
            </CardSection>

            <CardSection>
              <CardTitle>Device Status</CardTitle>

              <InfoRow>
                <InfoLabel>Install status</InfoLabel>
                {role_id === 1 ? (
                  <CustomSelect
                    value={installed.toString()}
                    onChange={e =>
                      this.handleChangeInstallationStatus({
                        target: {
                          value: e.target.value,
                          name: 'installationStatus',
                        },
                      })
                    }
                  >
                    <option value="true">Complete</option>
                    <option value="false">Not Complete</option>
                  </CustomSelect>
                ) : (
                  <StatusLabel installed={installed}>
                    {installed ? 'Complete' : 'Not Complete'}
                  </StatusLabel>
                )}
              </InfoRow>

              {deviceId && (
                <InfoRow>
                  <InfoLabel>Device ID</InfoLabel>
                  {editingDeviceId ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        type="text"
                        value={newDeviceId}
                        onChange={this.handleDeviceIdChange}
                        style={{
                          border: `1px solid ${Colors.gray2}`,
                          borderRadius: '4px',
                          padding: '4px 8px',
                          fontSize: '14px',
                        }}
                      />
                      <Icon
                        name="check"
                        color={Colors.green}
                        fontSize={16}
                        customStyle={{ marginLeft: '8px', cursor: 'pointer' }}
                        onClick={this.updateDeviceId}
                      />
                      <Icon
                        name="times"
                        color={Colors.red}
                        fontSize={16}
                        customStyle={{ marginLeft: '8px', cursor: 'pointer' }}
                        onClick={() => this.setState({ editingDeviceId: false })}
                      />
                    </div>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <InfoValue>{deviceId}</InfoValue>
                      {role_id === 1 && (
                        <Icon
                          name="pen"
                          color={Colors.lightBlue}
                          fontSize={14}
                          customStyle={{ marginLeft: '8px', cursor: 'pointer' }}
                          onClick={() =>
                            this.setState({
                              editingDeviceId: true,
                              newDeviceId: deviceId || '',
                            })
                          }
                        />
                      )}
                    </div>
                  )}
                </InfoRow>
              )}

              {installed_date && (
                <InfoRow>
                  <InfoLabel>Installed date</InfoLabel>
                  <InfoValue>{moment(installed_date).format('MMM DD, YYYY')}</InfoValue>
                </InfoRow>
              )}
            </CardSection>

            {installed && activeFilterHead && (
              <CardSection>
                <CardTitle>Configuration Details</CardTitle>

                {deviceType && (
                  <InfoRow>
                    <InfoLabel>Device Type</InfoLabel>
                    <InfoValue>{deviceType}</InfoValue>
                  </InfoRow>
                )}

                {configInfo && (
                  <InfoRow>
                    <InfoLabel>Configuration</InfoLabel>
                    <InfoValue>{configInfo}</InfoValue>
                  </InfoRow>
                )}

                {deviceType === 'Reverse Osmosis' && membraneChangeDate && (
                  <InfoRow>
                    <InfoLabel>Last Membrane Change</InfoLabel>
                    <InfoValue>{moment(membraneChangeDate).format('MMM DD, YYYY')}</InfoValue>
                  </InfoRow>
                )}
              </CardSection>
            )}
          </DrawerContent>
        </DrawerContainer>
      </>
    );
  };

  renderInstructionsModal = (isVisible, onClose, installSteps) => (
    <Modal title="Installation Steps" onClose={onClose} isVisible={isVisible}>
      {installSteps.map(step => (
        <>
          <Row justifyContent="flex-start" alignItems="start" style={{ padding: 10 }}>
            <Col
              xs={step.photo ? 50 : 100}
              md={step.photo ? 50 : 100}
              sm={step.photo ? 50 : 100}
              lg={step.photo ? 50 : 100}
              alignItems="left"
            >
              <H2 color={Colors.gray}>
                Step {step.index + 1} -
                <H2 color={Colors.black} style={{ marginLeft: 5 }}>
                  {step.title}
                </H2>
              </H2>

              <H3 color={Colors.black}>{step.description}</H3>
            </Col>
            {step.photo && (
              <Col xs={50} md={50} sm={50} lg={50}>
                <img width="250" src={step.photo} />
              </Col>
            )}
          </Row>
          <Line />
        </>
      ))}
    </Modal>
  );

  renderAlertsModal = () => {
    const { alertsModal } = this.state;
    const {
      fetchAlerts,
      alerts,
      alertsPages,
      alertsCurrentPage,
      location: { id: location_id },
    } = this.props;

    return (
      <Modal
        title="Alerts"
        onClose={() => this.switchModal('alertsModal')}
        isVisible={alertsModal}
        size="large"
      >
        {alerts && alerts.length > 0 ? (
          <Row>
            {alerts.map(alert => {
              let iconColor;
              switch (alert.type) {
                case 'alert':
                  iconColor = Colors.orange;
                  break;
                case 'warning':
                  iconColor = Colors.red;
                  break;
                default:
                  iconColor = Colors.green;
                  break;
              }
              const iconName = alert.type === 'success' ? 'check-circle' : 'exclamation-circle';

              return (
                <Row>
                  <Col lg={30} sm={30} md={30} alignItems="flex-start">
                    <Row justifyContent="end">
                      <Icon
                        fontSize={30}
                        name={iconName}
                        color={iconColor}
                        customStyle={{ marginRight: 10 }}
                      />
                      <H3 weight="bold">{alert.title}</H3>
                    </Row>
                  </Col>

                  <Col lg={50} sm={50} md={50} alignItems="flex-end">
                    <H3 color={Colors.gray} weight="lighter">
                      {moment(alert.created_at).format('MM/DD/YYYY hh:mm a')}
                    </H3>
                  </Col>

                  <Line />
                </Row>
              );
            })}

            <Row justifyContent="end" style={{ marginTop: 20 }}>
              <PaginationButtons
                currentPage={alertsCurrentPage}
                changePage={page =>
                  fetchAlerts({
                    page,
                    location_id,
                  })
                }
                totalPages={alertsPages}
              />
            </Row>
          </Row>
        ) : (
          <H3 color={Colors.gray}>There are no alerts.</H3>
        )}
      </Modal>
    );
  };

  switchModal = name => {
    const isVisible = this.state[name];

    this.setState({ [name]: !isVisible });
  };

  handleChange = evt => {
    const { name, value } = evt.target;

    this.setState({ [name]: value });
  };

  handleChangeInstallationStatus = evt => {
    const {
      updateInstallationStatus,
      location: { id },
    } = this.props;
    const installed = evt.target.value === 'true';
    updateInstallationStatus(id, { installed });
  };

  toggleQuickActions = () => {
    this.setState(prevState => ({
      quickActionsOpen: !prevState.quickActionsOpen,
    }));
  };

  handleQuickAction = action => {
    const { location, getCsvFiles } = this.props;

    switch (action) {
      case 'export-data':
        // Determine type based on selected filter head
        let type = '';
        if (location.ReverseOsmosisFilterHead) {
          type = 'reverse-osmosis';
        } else if (location.WaterFilterHead) {
          type = 'water-filter';
        } else if (location.SoftenerFilterHead) {
          type = 'softener';
        }

        // Open export modal and fetch CSV files
        this.switchModal('modalDatePickerCsv');
        if (getCsvFiles) {
          getCsvFiles({ type });
        }
        break;
      case 'open-aws':
        let thingName = '';
        if (location.ReverseOsmosisFilterHead) {
          thingName = location.ReverseOsmosisFilterHead.thing_name;
        } else if (location.WaterFilterHead) {
          thingName = location.WaterFilterHead.thing_name;
        } else if (location.SoftenerFilterHead) {
          thingName = location.SoftenerFilterHead.thing_name;
        }
        
        if (thingName) {
          window.open(`https://us-east-1.console.aws.amazon.com/iot/home?region=us-east-1#/thing/${thingName}`, '_blank');
        }
        break;
      case 'generate-report':
        console.log('Generate Report action');
        break;
      case 'configure-device':
        console.log('Configure Device action');
        break;
      default:
        console.log(`Quick action: ${action}`);
    }

    this.setState({ quickActionsOpen: false });
  };

  handleDeviceIdChange = e => {
    this.setState({ newDeviceId: e.target.value });
  };

  updateDeviceId = () => {
    const { setDeviceId, location } = this.props;
    const { newDeviceId } = this.state;
    let type = '';

    if (location.ReverseOsmosisFilterHead) {
      type = 'reverse-osmosis';
    } else if (location.WaterFilterHead) {
      type = 'water-filter';
    } else if (location.SoftenerFilterHead) {
      type = 'softener';
    }

    setDeviceId({
      type,
      device_id: newDeviceId,
      location_id: location.id,
    });

    this.setState({ editingDeviceId: false });
  };

  updateLastUpdated = timestamp => {
    this.setState({
      lastUpdated: timestamp,
      lastUpdatedLoading: false,
    });
  };

  setLastUpdatedLoading = () => {
    this.setState({ lastUpdatedLoading: true });
  };

  updateTdsValue = tdsValue => {
    this.setState({
      tdsValue,
      tdsLoading: false,
    });
  };

  setTdsLoading = () => {
    this.setState({ tdsLoading: true });
  };

  // Add handler for date picker changes
  handleDate = (value, key) => {
    this.setState({ [key]: value });
  };

  // Add handler for CSV type changes
  changeCsvType = (csvType, nro) => {
    const csvTypes = ['raw_data', 'calculated_data', 'all_data'];
    this.setState({ [csvType]: nro, csvTypeString: csvTypes[nro - 1] });
  };

  // Add handler to generate CSV
  generateCsv = () => {
    const {
      location,
      generateSoftenerCsv,
      generateWaterFilterCsv,
      generateReverseOsmosisCsv,
    } = this.props;
    const { csvTypeString, datePickerFromCsv: from, datePickerToCsv: to } = this.state;

    const { id: location_id } = location;
    let deviceId = '';
    let exportFunction = null;

    if (location.SoftenerFilterHead) {
      deviceId = location.SoftenerFilterHead.device_id;
      exportFunction = generateSoftenerCsv;
    } else if (location.WaterFilterHead) {
      deviceId = location.WaterFilterHead.device_id;
      exportFunction = generateWaterFilterCsv;
    } else if (location.ReverseOsmosisFilterHead) {
      deviceId = location.ReverseOsmosisFilterHead.device_id;
      exportFunction = generateReverseOsmosisCsv;
    }

    if (exportFunction && deviceId) {
      exportFunction({
        device_id: deviceId,
        location_id,
        from,
        to,
        csvContains: csvTypeString,
      });
    }

    this.switchModal('modalDatePickerCsv');
  };

  handleLocationChange = (e) => {
    const locationId = e.target.value;
    if (locationId) {
      window.location.href = `/locations/${locationId}`;
    }
  };

  handleBrandClick = () => {
    const { location } = this.props;
    console.log('Brand click - location object:', location);
    console.log('Brand ID:', location?.brand_id);
    
    if (location && location.brand_id) {
      // Navigate to locations list with brand filter applied
      const url = `/locations?brand_id=${location.brand_id}`;
      console.log('Navigating to:', url);
      push(url);
    } else {
      // Fallback to locations page if no brand_id available
      console.log('No brand_id found, navigating to locations page');
      push('/locations');
    }
  };

  handleAccountClick = () => {
    const { location } = this.props;
    console.log('Account click - location object:', location);
    console.log('Account ID:', location?.account_id);
    
    if (location && location.account_id) {
      // Navigate to locations list with account filter applied
      const url = `/locations?account_id=${location.account_id}`;
      console.log('Navigating to:', url);
      push(url);
    } else {
      // Fallback to locations page if no account_id available
      console.log('No account_id found, navigating to locations page');
      push('/locations');
    }
  };

  render() {
    const { filterHeadSelected, quickActionsOpen, lastUpdated, lastUpdatedLoading, tdsValue, tdsLoading } = this.state;
    const { location, alerts, role_id, relatedLocations } = this.props;
    const {
      identificator,
      brand_name,
      account_name,
      full_address,
      WaterFilterHead,
      ReverseOsmosisFilterHead,
      SoftenerFilterHead,
    } = location;

    const filterHeads = [];

    if (WaterFilterHead) {
      filterHeads.push({
        label: `${WaterFilterHead.WaterFilterConfiguration.name} (${WaterFilterHead.chlorine_or_chloramine})`,
        value: 'WaterFilterHead',
      });
    }
    if (ReverseOsmosisFilterHead) {
      filterHeads.push({
        label: `${ReverseOsmosisFilterHead.ReverseOsmosisFilterConfiguration.name}`,
        value: 'ReverseOsmosisFilterHead',
      });
    }
    if (SoftenerFilterHead) {
      filterHeads.push({
        label: `${SoftenerFilterHead.SoftenerConfiguration.name} (${SoftenerFilterHead.chlorine_or_chloramine})`,
        value: 'SoftenerFilterHead',
      });
    }

    const selected = filterHeadSelected || (filterHeads.length ? filterHeads[0].value : '');
    return (
      <Container style={{ paddingLeft: 15, paddingRight: 15 }}>
        {/* Breadcrumbs Navigation */}
        <Row
          justifyContent="space-between"
          alignItems="center"
          style={{
            padding: '5px 0',
            marginBottom: '0px',
            display: 'flex',
            flexWrap: 'nowrap',
          }}
        >
          <Row
            justifyContent="flex-start"
            alignItems="center"
            style={{ flexWrap: 'nowrap', flex: '1 1 auto', overflow: 'hidden' }}
          >
            <Icon
              customStyle={{ marginRight: '8px', cursor: 'pointer' }}
              onClick={() => push('/locations')}
              name="chevron-left"
              color={Colors.blue}
              fontSize={16}
            />

            {/* Vertical divider */}
            <div
              style={{
                width: '1px',
                height: '20px',
                backgroundColor: '#DDDDDD',
                margin: '0 12px',
                display: 'inline-block',
              }}
            />

            <H5
              color={Colors.gray}
              style={{ margin: 0, cursor: 'pointer', whiteSpace: 'nowrap' }}
              onClick={this.handleBrandClick}
            >
              {brand_name}
            </H5>
            <Icon
              name="chevron-right"
              color={Colors.gray}
              fontSize={12}
              customStyle={{ margin: '0 8px' }}
            />
            <H5
              color={Colors.gray}
              style={{ margin: 0, cursor: 'pointer', whiteSpace: 'nowrap' }}
              onClick={this.handleAccountClick}
            >
              {account_name}
            </H5>
            <Icon
              name="chevron-right"
              color={Colors.gray}
              fontSize={12}
              customStyle={{ margin: '0 8px' }}
            />
            {relatedLocations && relatedLocations.length > 0 ? (
              <BreadcrumbWrapper>
                <span style={{ marginRight: '8px', fontFamily: 'Inter, sans-serif' }}>{identificator}</span>
                <Icon name="sort" color={Colors.gray} fontSize={14} />
                <BreadcrumbSelect value={location.id} onChange={this.handleLocationChange}>
                  <option key={location.id} value={location.id}>
                    {location.WaterFilterHead ? "Water Filter: " : 
                      location.ReverseOsmosisFilterHead ? "RO: " : 
                      location.SoftenerFilterHead ? "Softener: " : ""}{identificator}
                  </option>
                  {relatedLocations.map(loc => (
                    <option key={loc.id} value={loc.id}>
                      {loc.WaterFilterHead ? "Water Filter: " : 
                      loc.ReverseOsmosisFilterHead ? "RO: " : 
                      loc.SoftenerFilterHead ? "Softener: " : ""}{loc.identificator}
                    </option>
                  ))}
                </BreadcrumbSelect>
              </BreadcrumbWrapper>
            ) : (
              <H5 color={Colors.black} weight={600} style={{ margin: 0, whiteSpace: 'nowrap', fontFamily: 'Inter, sans-serif' }}>
                {identificator}
              </H5>
            )}
          </Row>

          {/* Actions Section */}
          <div
            style={{
              display: 'flex',
              marginLeft: 'auto',
              gap: 0,
            }}
          >
            <div style={{ marginRight: '6px' }}>
              <IconButton
                name="info"
                size={33}
                fontSize={15}
                onClick={() => this.switchModal('showContactInfo')}
              />
            </div>
            <QuickActionsDropdown>
              <IconButton
                name="ellipsis-v"
                size={33}
                fontSize={15}
                onClick={this.toggleQuickActions}
              />
              <DropdownContent isOpen={quickActionsOpen}>
                <DropdownItem onClick={() => this.handleQuickAction('generate-report')}>
                  <Icon
                    name="chart-bar"
                    color={Colors.gray}
                    fontSize={14}
                    customStyle={{ marginRight: '8px' }}
                  />
                  Generate Report
                </DropdownItem>
                <DropdownItem onClick={() => this.handleQuickAction('export-data')}>
                  <Icon
                    name="file-export"
                    color={Colors.gray}
                    fontSize={14}
                    customStyle={{ marginRight: '8px' }}
                  />
                  Export Data
                </DropdownItem>
                <DropdownItem onClick={() => this.handleQuickAction('configure-device')}>
                  <Icon
                    name="cog"
                    color={Colors.gray}
                    fontSize={14}
                    customStyle={{ marginRight: '8px' }}
                  />
                  Configure Device
                </DropdownItem>
                <DropdownItem onClick={() => this.handleQuickAction('open-aws')}>
                  <Icon
                    name="cloud"
                    color={Colors.gray}
                    fontSize={14}
                    customStyle={{ marginRight: '8px' }}
                  />
                  Open in AWS
                </DropdownItem>
              </DropdownContent>
            </QuickActionsDropdown>
          </div>
        </Row>
        <Line style={{ marginBottom: '10px', marginTop: '5px' }} />
        <Row justifyContent="flex-start" alignItems="center" style={{ marginBottom: '20px' }}>
          <H2 color={Colors.black} weight={600} style={{ fontFamily: 'Inter, sans-serif' }}>
            {identificator}
          </H2>
        </Row>
        <Row justifyContent="flex-start" alignItems="center" style={{ marginBottom: '20px' }}>
          <Icon
            name="map-marker-alt"
            color={Colors.gray8}
            fontSize={16}
            customStyle={{ marginRight: '8px' }}
          />
          <H4 color={Colors.gray8}>{full_address}</H4>

          {ReverseOsmosisFilterHead && (
            <div
              style={{
                marginLeft: '15px',
                background: Colors.gray2,
                padding: '4px 10px',
                borderRadius: '20px',
                display: 'flex',
                alignItems: 'center',
                transition: 'all 0.3s ease',
                position: 'relative',
                overflow: 'hidden',
                width: tdsValue ? 'auto' : '100px',
                maxWidth: tdsValue ? '200px' : '100px',
                height: '28px',
                minWidth: '100px',
              }}
            >
              {tdsLoading && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    height: '100%',
                    background:
                      'linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.5), rgba(255,255,255,0))',
                    animation: 'shimmer 1.5s infinite',
                    zIndex: 1,
                    transform: 'translateX(-100%)',
                  }}
                />
              )}
              <Icon
                name="tint"
                color={Colors.gray8}
                fontSize={12}
                customStyle={{
                  marginRight: '5px',
                  position: 'relative',
                  zIndex: 2,
                }}
              />
              <H5
                color={Colors.gray8}
                style={{
                  margin: 0,
                  position: 'relative',
                  zIndex: 2,
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                TDS:
                {tdsValue !== null ? (
                  <span
                    style={{
                      opacity: tdsValue !== null ? 1 : 0,
                      transition: 'opacity 0.5s ease-in',
                      marginLeft: '4px',
                      display: 'inline-block',
                      color: Colors.green,
                    }}
                  >
                    {tdsValue || 'N/A'}
                  </span>
                ) : (
                  <span
                    style={{
                      opacity: tdsLoading ? 1 : 0,
                      transition: 'opacity 0.5s ease-in',
                      marginLeft: '4px',
                      display: 'inline-block',
                    }}
                  >
                    Loading...
                  </span>
                )}
              </H5>
            </div>
          )}

          <div
            style={{
              marginLeft: '15px',
              background: Colors.gray2,
              padding: '4px 10px',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
              transition: 'all 0.3s ease',
              position: 'relative',
              overflow: 'hidden',
              width: lastUpdated ? 'auto' : '120px',
              maxWidth: lastUpdated ? '320px' : '120px',
              height: '28px',
              minWidth: '120px',
            }}
          >
            {lastUpdatedLoading && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  width: '100%',
                  height: '100%',
                  background:
                    'linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.5), rgba(255,255,255,0))',
                  animation: 'shimmer 1.5s infinite',
                  zIndex: 1,
                  transform: 'translateX(-100%)',
                }}
               />
            )}
            <Icon
              name="sync"
              color={Colors.gray8}
              fontSize={12}
              customStyle={{
                marginRight: '5px',
                animation: lastUpdatedLoading ? 'spin 1.5s linear infinite' : 'none',
                position: 'relative',
                zIndex: 2,
              }}
            />
            <H5
              color={Colors.gray8}
              style={{
                margin: 0,
                position: 'relative',
                zIndex: 2,
                whiteSpace: 'nowrap',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Last update:
              {lastUpdated && (
                <span
                  style={{
                    opacity: lastUpdated ? 1 : 0,
                    transition: 'opacity 0.5s ease-in',
                    marginLeft: '4px',
                    display: 'inline-block',
                  }}
                >
                  {moment.unix(lastUpdated).format('MMM DD, YYYY h:mm a')}
                </span>
              )}
            </H5>
          </div>

          <style jsx="true">{`
            @keyframes shimmer {
              0% {
                transform: translateX(-100%);
              }
              100% {
                transform: translateX(100%);
              }
            }
            @keyframes spin {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
          `}</style>
        </Row>

        {location.installed ? (
          this.switchByFilterHead(selected)
        ) : (
          <InstallationNotCompleteContainer>
            <ListEmpty message="Installation Not Complete" />
          </InstallationNotCompleteContainer>
        )}
        {location.installed && (
          <BG width="100%">
            <Row>
              <Col lg={50} sm={50} md={50} xs={50} alignItems="flex-start">
                <H3>Alerts</H3>
              </Col>
              <Col lg={50} sm={50} md={50} xs={50} alignItems="flex-end">
                <H3
                  onClick={() => this.switchModal('alertsModal')}
                  weight="bold"
                  color={Colors.lightBlue}
                  style={{ cursor: 'pointer' }}
                >
                  Open More
                </H3>
              </Col>
              {alerts && alerts.length > 0 ? (
                alerts.slice(0, 2).map(alert => {
                  let iconColor;
                  switch (alert.type) {
                    case 'alert':
                      iconColor = Colors.orange;
                      break;
                    case 'warning':
                      iconColor = Colors.red;
                      break;
                    default:
                      iconColor = Colors.green;
                      break;
                  }
                  const iconName = alert.type === 'success' ? 'check-circle' : 'exclamation-circle';

                  return (
                    <Row>
                      <Col lg={20} sm={20} md={20} alignItems="flex-start">
                        <Row justifyContent="start">
                          <Icon
                            fontSize={30}
                            name={iconName}
                            color={iconColor}
                            customStyle={{ marginRight: 10 }}
                          />
                          <H3>{alert.title}</H3>
                        </Row>
                      </Col>

                      <Col lg={80} sm={80} md={80} alignItems="flex-end">
                        <H3 color={Colors.gray} weight="lighter">
                          {moment(alert.created_at).format('MM/DD/YYYY hh:mm a')}
                        </H3>
                      </Col>

                      <Line />
                    </Row>
                  );
                })
              ) : (
                <ListEmpty message="There are currently no alerts" />
              )}
            </Row>
          </BG>
        )}
        {this.renderContactInfoDrawer()}
        {this.renderAlertsModal()}

        {/* Add Modal for Export Data */}
        <ModalCsvFiles
          isVisible={this.state.modalDatePickerCsv}
          handleDateFrom={value => this.handleDate(value, 'datePickerFromCsv')}
          handleDateTo={value => this.handleDate(value, 'datePickerToCsv')}
          switchModal={() => this.switchModal('modalDatePickerCsv')}
          dateFrom={this.state.datePickerFromCsv}
          dateTo={this.state.datePickerToCsv}
          changeCsvType={this.changeCsvType}
          csvType={this.state.csvType}
          saveChanges={this.generateCsv}
          csvFiles={this.props.csvFiles}
          csvFilesLoading={this.props.csvFilesLoading}
          minDate={location.installed_date && moment(location.installed_date).toDate()}
        />
      </Container>
    );
  }
}

WaterFilterForm.propTypes = {
  alerts: PropTypes.array,
  alertsCurrentPage: PropTypes.number,
  alertsPages: PropTypes.number,
  allAlerts: PropTypes.array,
  csvFiles: PropTypes.array,
  csvFilesLoading: PropTypes.bool,
  fetchAlerts: PropTypes.func,
  fetchAllAlerts: PropTypes.func,
  generateReverseOsmosisCsv: PropTypes.func,
  generateSoftenerCsv: PropTypes.func,
  generateWaterFilterCsv: PropTypes.func,
  getCsvFiles: PropTypes.func,
  location: PropTypes.object,
  markAlertAsViewed: PropTypes.func,
  role_id: PropTypes.number,
  setDeviceId: PropTypes.func,
  updateInstallationStatus: PropTypes.func,
  relatedLocations: PropTypes.array,
};

WaterFilterForm.defaultProps = {
  relatedLocations: [],
};
