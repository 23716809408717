import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'modules/history';
import { getAlerts } from 'actions/index';
import Logo from 'assets/media/images/logo.png';
import LogoSmall from 'assets/media/images/og-image.png';

const Menu = styled.div`
  width: 250px;
  height: 100vh;
  background: #f9f9f9;
  border-right: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;

  @media (max-width: 700px) {
    width: 60px;
  }
`;

const LogoContainer = styled.div`
  text-align: center;
  padding: 20px 0;
  margin-bottom: 25px;
  border-bottom: 1px solid #e5e5e5;
`;

const BigLogo = styled.img`
  display: none;
  margin: 0 auto;
  @media (min-width: 700px) {
    display: block;
  }
`;

const SmallLogo = styled.img`
  display: none;
  margin: 0 auto;
  @media (max-width: 700px) {
    display: block;
  }
`;

const NavSection = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  flex: ${props => (props.isFooter ? '0' : '1')};
  ${props =>
    props.isFooter &&
    `
    margin-top: auto;
    border-top: 1px solid #e5e5e5;
    padding-top: 20px;
    padding-bottom: 20px;
  `}
`;

const NavItem = styled.li`
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const NavLink = styled.a`
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 42px;
  color: #666;
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.1s linear;
  border-radius: 8px;
  margin: 0 10px;
  width: calc(100% - 20px);

  &:hover {
    background-color: rgba(255, 255, 255, 0.7);

    .nav-label {
      color: #1788c5;
    }

    .nav-icon {
      color: #1788c5;
    }
  }

  &.active {
    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);

    .nav-label {
      color: #1788c5;
      font-weight: 500;
    }

    .nav-icon {
      color: #1788c5;
    }
  }
`;

const NavIcon = styled.i`
  width: 24px;
  height: 24px;
  font-size: 18px;
  color: #555;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const NavLabel = styled.span`
  margin-left: 16px;
  line-height: normal;

  @media (max-width: 700px) {
    display: none;
  }
`;

const AlertBadge = styled.span`
  background-color: #f5333f;
  color: white;
  border-radius: 12px;
  padding: 0 8px;
  font-size: 12px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  min-width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Function to format numbers with commas
const formatNumber = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

class Sidebar extends Component {
  componentDidMount() {
    const { fetchAlerts } = this.props;
    fetchAlerts();
  }

  render() {
    const { path, roleCode, countAlerts } = this.props;

    return (
      <Menu>
        <LogoContainer>
          <BigLogo src={Logo} width="172" alt="Logo" />
          <SmallLogo src={LogoSmall} width="40" alt="Logo small" />
        </LogoContainer>

        <NavSection>
          {['admin', 'general'].includes(roleCode) && (
            <NavItem>
              <NavLink
                onClick={() => push('/libraries')}
                className={path === '/libraries' ? 'active' : ''}
              >
                <NavIcon className="fa fa-cog nav-icon" />
                <NavLabel className="nav-label">Equipment</NavLabel>
              </NavLink>
            </NavItem>
          )}

          <NavItem>
            <NavLink
              onClick={() => push('/locations')}
              className={path === '/locations' || path === '/' ? 'active' : ''}
            >
              <NavIcon className="fa fa-map-marker-alt nav-icon" />
              <NavLabel className="nav-label">Locations</NavLabel>
            </NavLink>
          </NavItem>

          {['admin', 'general'].includes(roleCode) && (
            <NavItem>
              <NavLink
                onClick={() => push('/brands')}
                className={path === '/brands' ? 'active' : ''}
              >
                <NavIcon className="fa fa-tag nav-icon" />
                <NavLabel className="nav-label">Brands</NavLabel>
              </NavLink>
            </NavItem>
          )}

          {['admin', 'general', 'brand_admin'].includes(roleCode) && (
            <NavItem>
              <NavLink
                onClick={() => push('/accounts')}
                className={path === '/accounts' ? 'active' : ''}
              >
                <NavIcon className="fa fa-briefcase nav-icon" />
                <NavLabel className="nav-label">Accounts</NavLabel>
              </NavLink>
            </NavItem>
          )}

          {['admin', 'general'].includes(roleCode) && (
            <NavItem>
              <NavLink onClick={() => push('/users')} className={path === '/users' ? 'active' : ''}>
                <NavIcon className="fa fa-users nav-icon" />
                <NavLabel className="nav-label">Users</NavLabel>
              </NavLink>
            </NavItem>
          )}

          {['admin'].includes(roleCode) && (
            <NavItem>
              <NavLink onClick={() => push('/ota')} className={path === '/ota' ? 'active' : ''}>
                <NavIcon className="fa fa-wifi nav-icon" />
                <NavLabel className="nav-label">OTA</NavLabel>
              </NavLink>
            </NavItem>
          )}
        </NavSection>

        <NavSection isFooter>
          <NavItem>
            <NavLink
              onClick={() => push('/alerts')}
              className={path === '/alerts' ? 'active' : ''}
              style={{ position: 'relative' }}
            >
              <NavIcon className="fa fa-exclamation-circle nav-icon" />
              <NavLabel className="nav-label">Alerts</NavLabel>
              {countAlerts > 0 && <AlertBadge>{formatNumber(countAlerts)}</AlertBadge>}
            </NavLink>
          </NavItem>

          {['admin', 'general'].includes(roleCode) && (
            <NavItem>
              <NavLink
                onClick={() => push('/supports')}
                className={path === '/supports' ? 'active' : ''}
              >
                <NavIcon className="fa fa-question-circle nav-icon" />
                <NavLabel className="nav-label">Support</NavLabel>
              </NavLink>
            </NavItem>
          )}

          <NavItem>
            <NavLink
              onClick={() => push('/settings')}
              className={path === '/settings' ? 'active' : ''}
            >
              <NavIcon className="fa fa-cog nav-icon" />
              <NavLabel className="nav-label">Settings</NavLabel>
            </NavLink>
          </NavItem>
        </NavSection>
      </Menu>
    );
  }
}

Sidebar.propTypes = {
  countAlerts: PropTypes.number,
  fetchAlerts: PropTypes.func.isRequired,
  path: PropTypes.string,
  roleCode: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    countAlerts: state.alerts.count,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAlerts: params => dispatch(getAlerts(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
