import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getLocation,
  getAlerts,
  getAllAlerts,
  setDeviceId,
  updateInstallationStatus,
  markAlertAsViewed,
  getCsvFiles,
  generateSoftenerCsv,
  generateWaterFilterCsv,
  generateReverseOsmosisCsv,
  getLocations,
} from 'actions/index';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import LocationDetailView from './LocationDetailView';

class LocationDetailContainer extends Component {
  state = {
    relatedLocations: [],
    hasRequestedRelatedLocations: false,
  };

  componentDidMount() {
    const {
      fetchLocation,
      fetchAlerts,
      match: { params },
    } = this.props;
    const { id } = params;

    fetchLocation(id);
    fetchAlerts({ location_id: id });
  }

  componentDidUpdate(prevProps) {
    const { 
      location, 
      locationsList, 
      locationsListLoading 
    } = this.props;
    
    const { hasRequestedRelatedLocations } = this.state;

    // When location data is loaded, fetch related locations by account and address
    if (!prevProps.location && location && !hasRequestedRelatedLocations) {
      this.requestRelatedLocations(location);
    }

    // When locations list is loaded, update relatedLocations in state
    if (
      prevProps.locationsListLoading && 
      !locationsListLoading && 
      locationsList && 
      locationsList.rows &&
      location
    ) {
      this.updateRelatedLocations(locationsList.rows, location.id);
    }
  }

  requestRelatedLocations = (location) => {
    const { fetchLocations } = this.props;
    
    if (location && location.Account && location.address_line) {
      // Extract street number and name only from address
      const addressParts = location.address_line.split(' ');
      const streetAddress = addressParts.length >= 2 ? `${addressParts[0]} ${addressParts[1]}` : location.address_line;
      
      console.log('Requesting related locations for:', 
        'Account ID:', location.Account.id, 
        'Address:', streetAddress
      );
      
      // Find locations with same account and address (partial)
      fetchLocations({ 
        account_id: location.Account.id
        // Not sending address parameter to API since we'll filter locally
        // address: streetAddress 
      });
      
      this.setState({ hasRequestedRelatedLocations: true });
    } else {
      console.log('Cannot request related locations - missing data:', 
        'Has Account:', Boolean(location && location.Account),
        'Has Address:', Boolean(location && location.address_line)
      );
    }
  };

  updateRelatedLocations = (locationRows, currentLocationId) => {
    const { location } = this.props;
    
    if (!location || !location.address_line) {
      console.log('Missing location or address data');
      return;
    }
    
    // Extract street number and name only from current location's address
    const addressParts = location.address_line.split(' ');
    const streetAddress = addressParts.length >= 2 ? `${addressParts[0]} ${addressParts[1]}` : location.address_line;
    console.log('Looking for locations with street address:', streetAddress);
    
    // Filter for locations with the same account and address, excluding current location
    const relatedLocations = locationRows.filter(loc => {
      // Skip current location
      if (loc.id === currentLocationId) {
        return false;
      }
      
      // Make sure it's in the same account
      if (loc.Account && location.Account && loc.Account.id !== location.Account.id) {
        console.log('Different account:', loc.identificator, 'Account ID:', loc.Account.id);
        return false;
      }
      
      // Check if this location's address starts with the same street number and name
      if (loc.address_line) {
        const locAddressParts = loc.address_line.split(' ');
        const locStreetAddress = locAddressParts.length >= 2 ? 
          `${locAddressParts[0]} ${locAddressParts[1]}` : loc.address_line;
        
        const isMatch = locStreetAddress.toLowerCase() === streetAddress.toLowerCase();
        if (!isMatch) {
          console.log('Different address:', loc.identificator, 'Address:', loc.address_line, 'Street:', locStreetAddress);
        } else {
          console.log('MATCH found:', loc.identificator, 'Address:', loc.address_line);
        }
        return isMatch;
      }
      
      console.log('Missing address data for location:', loc.identificator);
      return false;
    });
    
    console.log(`Found ${relatedLocations.length} related locations`);
    this.setState({ relatedLocations });
  };

  render() {
    const {
      location,
      loading,
      alerts,
      alertsPages,
      alertsCurrentPage,
      fetchAlerts,
      fetchAllAlerts,
      allAlerts,
      setDeviceId,
      updateInstallationStatus,
      markAlertAsViewed,
      role_id,
      getCsvFiles,
      csvFiles,
      csvFilesLoading,
      generateSoftenerCsv,
      generateWaterFilterCsv,
      generateReverseOsmosisCsv,
    } = this.props;
    const { relatedLocations } = this.state;

    if (loading) {
      return <Loader size={200} block />;
    }

    if (!location) {
      return null;
    }

    return (
      <LocationDetailView
        loading={loading}
        location={location}
        alerts={alerts}
        alertsPages={alertsPages}
        alertsCurrentPage={alertsCurrentPage}
        fetchAlerts={fetchAlerts}
        fetchAllAlerts={fetchAllAlerts}
        allAlerts={allAlerts}
        setDeviceId={setDeviceId}
        updateInstallationStatus={updateInstallationStatus}
        markAlertAsViewed={markAlertAsViewed}
        role_id={role_id}
        getCsvFiles={getCsvFiles}
        csvFiles={csvFiles}
        csvFilesLoading={csvFilesLoading}
        generateSoftenerCsv={generateSoftenerCsv}
        generateWaterFilterCsv={generateWaterFilterCsv}
        generateReverseOsmosisCsv={generateReverseOsmosisCsv}
        relatedLocations={relatedLocations}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.locations.item,
    loading: state.locations.loading,
    allAlerts: state.alerts.allAlerts,
    alerts: state.alerts.list,
    alertsPages: state.alerts.pages,
    alertsCurrentPage: state.alerts.currentPage,
    role_id: state.auth.profile.role_id,
    csvFiles: state.locations.csvFiles,
    csvFilesLoading: state.locations.csvFilesLoading,
    locationsList: state.locations.list ? { 
      rows: state.locations.list, 
      pages: state.locations.pages, 
      currentPage: state.locations.currentPage 
    } : null,
    locationsListLoading: state.locations.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAlerts: params => dispatch(getAlerts(params)),
    fetchAllAlerts: params => dispatch(getAllAlerts(params)),
    fetchLocation: id => dispatch(getLocation(id)),
    fetchLocations: params => dispatch(getLocations(params)),
    setDeviceId: params => dispatch(setDeviceId(params)),
    updateInstallationStatus: (id, data) => dispatch(updateInstallationStatus(id, data)),
    markAlertAsViewed: params => dispatch(markAlertAsViewed(params)),
    getCsvFiles: params => dispatch(getCsvFiles(params)),
    generateSoftenerCsv: params => dispatch(generateSoftenerCsv(params)),
    generateWaterFilterCsv: params => dispatch(generateWaterFilterCsv(params)),
    generateReverseOsmosisCsv: params => dispatch(generateReverseOsmosisCsv(params)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(LocationDetailContainer);

LocationDetailContainer.propTypes = {
  alerts: PropTypes.array,
  alertsCurrentPage: PropTypes.number,
  alertsPages: PropTypes.number,
  allAlerts: PropTypes.array,
  csvFiles: PropTypes.array,
  csvFilesLoading: PropTypes.bool,
  fetchAlerts: PropTypes.func,
  fetchAllAlerts: PropTypes.func,
  fetchLocation: PropTypes.func,
  fetchLocations: PropTypes.func,
  generateReverseOsmosisCsv: PropTypes.func,
  generateSoftenerCsv: PropTypes.func,
  generateWaterFilterCsv: PropTypes.func,
  getCsvFiles: PropTypes.func,
  loading: PropTypes.bool,
  location: PropTypes.object,
  locationsList: PropTypes.object,
  locationsListLoading: PropTypes.bool,
  markAlertAsViewed: PropTypes.func,
  match: PropTypes.object,
  role_id: PropTypes.number,
  setDeviceId: PropTypes.func,
  updateInstallationStatus: PropTypes.func,
};
