import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Colors } from 'modules/theme';

const Text = styled.h3`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-size: 14px;
  margin-vertical: 10px;
  margin-right: ${props => props.marginRight || 0}px;
  color: ${props => props.color || Colors.black};
  text-align: ${props => props.textAlign || 'left'};
  font-weight: ${props => props.weight || '350'};
  ${props => props.uppercase && 'text-transform: uppercase'};
  line-height: ${props => (props.lineHeight ? props.lineHeight : 'initial')};
`;

const H3 = props => <Text {...props}>{props.children}</Text>;

H3.propTypes = {
  children: PropTypes.node,
};

export default H3;
