import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Colors } from 'modules/theme';
import H1 from 'components/H1';
import H2 from 'components/H2';
import H3 from 'components/H3';
import H4 from 'components/H4';
import H5 from 'components/H5';
import DatePickerComponent from 'components/Datepicker/DatePickerComponent';
import VerticalLine from 'components/Line';
import Col from 'components/Col';
import Row from 'components/Row';
import Filter, { FilterGroup, FilterContainer } from 'components/Filter';
import Icon from 'components/Icon';
import ListEmpty from 'components/ListEmpty';
import BG from 'components/BG';
import Modal from 'components/Modal';
import Loader from 'components/Loader';
import Input from 'components/InputText';
import ButtonText from 'components/ButtonText';
import LineGray from 'components/Line';
import QuickStats from '../../components/QuickStats';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Tooltip as TooltipReact } from 'react-tooltip';
import {
  waterFilterSetInitialState,
  getWaterFilterCurrentState,
  getWaterFilterCurrentPressure,
  getWaterFilterConsumption,
  getWaterFilterMaxFlowRate,
  getWaterFilterPressureForFlowRate,
  getWaterFilterPressureForConsumption,
  getWaterFilterBypass,
  getTotalConsumptionWaterFilter,
  generateWaterFilterCsv,
  getCsvFiles,
  getWaterFilterCurrentBypass,
  getTotalConsumptionCartridgeWf,
  getAllTotalConsumptionCartridgeWf,
  replaceWaterFilterCartridges,
} from 'actions/index';
import domtoimage from 'dom-to-image';
import {
  PieChart,
  Pie,
  Sector,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Line,
} from 'recharts';
import PropTypes from 'prop-types';
import { ReportWF } from './ReportWF';
import { ModalDatePicker } from './ModalDatePicker';
import ModalCsvFiles from './ModalCsvFiles';

const Container = styled.div`
  margin: 0px;
  text-align: left;
`;

const GraphContainer = styled.div`
  width: 100%;
  position: relative;
  min-height: ${props => props.height}px;
  display: ${props => props.display ?? 'inherit'};
  justify-content: ${props => props.justifyContent ?? 'center'};
  align-items: ${props => props.alignItems ?? 'center'};
  flex-direction: ${props => props.flexDirection ?? 'row'};
`;

const startDate = moment()
  .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  .add(-7, 'days')
  .toDate();

const endDate = moment().toDate();

class WF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartridgesChangeDate: '',
      showConfiguration: false,
      generatePDF: false,
      downloadPDF: false,
      modalDatePickerConsumption: false,
      datePickerFromConsumption: startDate,
      datePickerToConsumption: endDate,
      catridgeSelectedId: null,
      catridgeSelectedObjet: null,
      catridgeCapacity: 0,
      modalViewInstructions: false,
      range: 'days',
      possibleDayRangesConsumption: ['days'],
      possibleDayRangesBypass: ['days'],
      possibleDayRangesMaxFlowRate: ['days'],
      modalDatePickerCsv: false,
      datePickerFromCsv: startDate,
      datePickerToCsv: endDate,
      csvType: 1,
      csvTypeString: 'raw_data',
      modalDeviceId: false,
      new_device_id: '',
      modalBypass: false,
      datePickerFromBypass: startDate,
      datePickerToBypass: endDate,
      modalDatePickerBypass: false,
      modalCartridges: false,
      datePickerToReport: endDate,
      datePickerFromReport: startDate,
      modalDatePickerReport: false,
      datePickerFromMaxFlowRate: startDate,
      datePickerToMaxFlowRate: endDate,
      modalDatePickerMaxFlowRate: false,
      totalCatridgeCapacity: 0,
      modalPressureDetail: false,
    };
  }

  componentDidMount() {
    const {
      waterFilterSetInitialState,
      location: { WaterFilterHead },
    } = this.props;

    const { FlowMeterOfWaterFilters, installed_date, chlorine_or_chloramine } = WaterFilterHead;
    let accumulator = 0;

    FlowMeterOfWaterFilters?.map(({ SpotsWaterFilter }) => {
      const { Catridge } = SpotsWaterFilter;
      // Only add to total capacity if not a sediment filter
      if (Catridge.sediment !== true) {
        accumulator += this.calculeCatridgeCapacity(Catridge, chlorine_or_chloramine);
      }
    });

    // Set default date range to last 7 days if installed date allows
    const endDate = moment().endOf('day').toDate();
    const startDate = moment().subtract(7, 'days').startOf('day').toDate();
    const fromDate = moment(installed_date).isAfter(moment(startDate)) 
      ? moment(installed_date).toDate() 
      : startDate;

    this.setState({
      totalCatridgeCapacity: accumulator,
      datePickerFromBypass: fromDate,
      datePickerToBypass: endDate,
    });

    waterFilterSetInitialState();
    if (moment(startDate) < moment(installed_date)) {
      this.setState(
        {
          datePickerFromConsumption: moment(installed_date).toDate(),
          datePickerFromCsv: moment(installed_date).toDate(),
          datePickerFromBypass: moment(installed_date).toDate(),
        },
        () => this.getInitialData(),
      );
    } else {
      this.getInitialData();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.wf_for_pfd && this.props.wf_for_pfd) {
      this.putDataInPdf();
    }

    const { waterFilterConsumption, waterFilterConsumptionLoading, updateLastUpdated } = this.props;

    if (
      prevProps.waterFilterConsumptionLoading &&
      !waterFilterConsumptionLoading &&
      waterFilterConsumption &&
      waterFilterConsumption.last_updated &&
      updateLastUpdated
    ) {
      updateLastUpdated(waterFilterConsumption.last_updated);
    }
  }

  putDataInPdf = async () => {
    try {
      const {
        location: { id: location_id },
        fetchAllAlerts,
      } = this.props;
      await fetchAllAlerts({ location_id });

      let totalGaloonsChart = document.getElementById('totalGaloonsChart');
      let exhaustionChart = document.getElementById('exhaustionChart');
      let maxFlowAndPressureChart = document.getElementById('maxFlowAndPressureChart');

      totalGaloonsChart = totalGaloonsChart && (await domtoimage.toPng(totalGaloonsChart));
      exhaustionChart = exhaustionChart && (await domtoimage.toPng(exhaustionChart));
      maxFlowAndPressureChart =
        maxFlowAndPressureChart && (await domtoimage.toPng(maxFlowAndPressureChart));

      this.setState({
        totalGaloonsChart,
        exhaustionChart,
        maxFlowAndPressureChart,
        downloadPDF: true,
      });
    } catch (error) {
      this.setState({ generatePDF: false });
      console.log(error);
    }
  };

  getInitialData = () => {
    const {
      location: { WaterFilterHead },
      getWaterFilterCurrentState,
      getWaterFilterCurrentPressure,
      getTotalConsumptionWaterFilter,
      getTotalConsumptionCartridgeWf,
      getAllTotalConsumptionCartridgeWf,
      getWaterFilterConsumption,
      getWaterFilterMaxFlowRate,
      getWaterFilterPressureForFlowRate,
      getWaterFilterPressureForConsumption,
      getWaterFilterCurrentBypass,
      setLastUpdatedLoading,
    } = this.props;

    const {
      FlowMeterOfWaterFilters,
      device_id,
      chlorine_or_chloramine,
      id: water_filter_head_id,
      installed_date,
    } = WaterFilterHead;

    if (device_id) {
      const {
        datePickerToConsumption,
        datePickerFromConsumption,
        range,
        datePickerFromMaxFlowRate,
        datePickerToMaxFlowRate,
      } = this.state;

      // Set loading state in parent component
      if (setLastUpdatedLoading) {
        setLastUpdatedLoading();
      }

      const catridgeSelectedObjet = FlowMeterOfWaterFilters[0];

      getWaterFilterCurrentBypass({ device_id, from: installed_date });
      getWaterFilterCurrentState({ device_id, from: installed_date });
      getWaterFilterCurrentPressure({ device_id, from: installed_date });
      getTotalConsumptionWaterFilter({ device_id, from: installed_date });
      getWaterFilterConsumption({
        device_id,
        from: datePickerFromConsumption,
        to: datePickerToConsumption,
        range,
      });
      getWaterFilterMaxFlowRate({
        device_id,
        from: datePickerFromMaxFlowRate,
        to: datePickerToMaxFlowRate,
        range,
      });

      getWaterFilterPressureForConsumption({
        device_id,
        from: datePickerFromConsumption,
        to: datePickerToConsumption,
        range,
      });

      getWaterFilterPressureForFlowRate({
        device_id,
        from: datePickerFromConsumption,
        to: datePickerToConsumption,
        range,
      });

      if (catridgeSelectedObjet) {
        const catridgeCapacity = this.calculeCatridgeCapacity(
          catridgeSelectedObjet.SpotsWaterFilter.Catridge,
          chlorine_or_chloramine,
        );

        this.setState({
          catridgeSelectedId: catridgeSelectedObjet.SpotsWaterFilter.Catridge.id,
          catridgeSelectedObjet,
          catridgeCapacity,
        });

        if (catridgeSelectedObjet.last_replaced) {
          getTotalConsumptionCartridgeWf({
            device_id,
            from: catridgeSelectedObjet.last_replaced,
            spot_id: catridgeSelectedObjet.spot_water_filter_head_id,
            water_filter_head_id,
          });

          getAllTotalConsumptionCartridgeWf({
            device_id,
            water_filter_head_id,
          });
        }
      }
    }
  };

  calculeCatridgeCapacity = (catridge, chlorine_or_chloramine) => {
    // If this is a sediment filter, return 0 capacity
    if (catridge.sediment === true) {
      return 0;
    }
    
    const capacitySelected =
      chlorine_or_chloramine === 'Chlorine'
        ? catridge.chlorine_capacity
        : catridge.chloramine_capacity;

    return catridge.scale_control_capacity
      ? capacitySelected && catridge.scale_control_capacity > capacitySelected
        ? capacitySelected
        : catridge.scale_control_capacity
      : capacitySelected;
  };

  cartidgeOnChange = evt => {
    const {
      location: {
        WaterFilterHead: {
          id: water_filter_head_id,
          FlowMeterOfWaterFilters,
          device_id,
          chlorine_or_chloramine,
        },
      },
      getTotalConsumptionCartridgeWf,
      getAllTotalConsumptionCartridgeWf,
    } = this.props;

    const catridgeSelectedObjet = FlowMeterOfWaterFilters.find(wf => wf.id === evt.target.value);

    if (catridgeSelectedObjet) {
      const {
        last_replaced,
        SpotsWaterFilter: { Catridge },
      } = catridgeSelectedObjet;
      // get consuptions of catridge
      if (last_replaced) {
        getTotalConsumptionCartridgeWf({
          device_id,
          from: last_replaced,
          spot_id: catridgeSelectedObjet.spot_water_filter_head_id,
          water_filter_head_id,
        });

        getAllTotalConsumptionCartridgeWf({
          device_id,
          water_filter_head_id,
        });
      }
      const catridgeCapacity = this.calculeCatridgeCapacity(Catridge, chlorine_or_chloramine);

      this.setState({
        catridgeSelectedId: evt.target.value,
        catridgeSelectedObjet,
        catridgeCapacity,
      });
    }
  };

  renderActiveShape = props => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, allSedimentFilters } = props;
    return (
      <g>
        {!allSedimentFilters && (
          <>
            <text
              x={cx}
              y={cy - 8}
              dy={8}
              textAnchor="middle"
              fontFamily="Gotham, Sans Serif"
              fontSize={25}
              fill={Colors.black}
            >
              {payload.value}%
            </text>
            <text
              x={cx}
              y={cy + 15}
              textAnchor="middle"
              fontFamily="Gotham, Sans Serif"
              fontSize={14}
              fill={Colors.gray8}
            >
              remaining
            </text>
          </>
        )}
        {allSedimentFilters && (
          <text
            x={cx}
            y={cy}
            textAnchor="middle"
            fontFamily="Gotham, Sans Serif"
            fontSize={14}
            fill={Colors.gray8}
          >
            N/A Capacity
          </text>
        )}
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  renderPressure = () => {
    const {
      waterFilterCurrentPressure,
      waterFilterCurrentPressureLoading,
      location: {
        WaterFilterHead: { device_id },
      },
    } = this.props;

    const iconTime = <Icon name="weight" color={Colors.yellow} fontSize={22} />;
    return (
      <GraphContainer
        display="flex"
        justifyContent="space-around"
        flexDirection="column"
        height={360}
      >
        {/* INLET pressure */}
        <Col>
          <H2 textAlign="center" style={{ fontWeight: "bold" }}>Inlet Pressure</H2>
          {waterFilterCurrentPressureLoading ? (
            <Loader size={40} block />
          ) : !device_id ? (
            <Col justifyContent="center">
              <ListEmpty message="Installation Not Complete" />
            </Col>
          ) : (
            <>
              {(waterFilterCurrentPressure || []).map((pressure, index) => {
                const inletPressure = pressure && pressure.inletPressure !== undefined && 
                    pressure.inletPressure !== null && !isNaN(parseFloat(pressure.inletPressure)) ? 
                    parseFloat(pressure.inletPressure) : null;
                  
                const outletPressure = pressure && pressure.outletPressure !== undefined && 
                    pressure.outletPressure !== null && !isNaN(parseFloat(pressure.outletPressure)) ? 
                    parseFloat(pressure.outletPressure) : null;
                  
                const differential = (inletPressure !== null && outletPressure !== null) ? 
                    Math.max(0, inletPressure - outletPressure) : null;
                
                return (
                  <Row key={index}>
                    <Col lg={100} md={100} sm={100} xs={100}>
                      <H4>
                        Filter {index + 1}
                        <span style={{ marginLeft: 20, marginRight: 20 }}> {iconTime} </span>
                        <span style={{ color: Colors.blue }}>
                          {inletPressure !== null ? Math.round(inletPressure) : 'N/A'}{' '}
                        </span>
                        PSI
                      </H4>
                    </Col>
                  </Row>
                );
              })}
            </>
          )}
        </Col>
        <VerticalLine style={{ marginTop: 5, marginBottom: 5 }} />
        {/* OUTLET Pressure */}
        <Col>
          <H2 textAlign="center" style={{ fontWeight: "bold" }}>Outlet Pressure</H2>
          {waterFilterCurrentPressureLoading ? (
            <Loader size={40} block />
          ) : !device_id ? (
            <Col justifyContent="center">
              <ListEmpty message="Installation Not Complete" />
            </Col>
          ) : (
            <>
              {(waterFilterCurrentPressure || []).map((pressure, index) => {
                const outletPressure = pressure && pressure.outletPressure !== undefined && 
                    pressure.outletPressure !== null && !isNaN(parseFloat(pressure.outletPressure)) ? 
                    parseFloat(pressure.outletPressure) : null;
                
                return (
                  <Row key={index}>
                    <Col lg={100} md={100} sm={100} xs={100}>
                      <H4>
                        Filter {index + 1}
                        <span style={{ marginLeft: 20, marginRight: 20 }}> {iconTime} </span>
                        <span style={{ color: Colors.blue }}>
                          {outletPressure !== null ? Math.round(outletPressure) : 'N/A'}{' '}
                        </span>
                        PSI
                      </H4>
                    </Col>
                  </Row>
                );
              })}
            </>
          )}
        </Col>
        <VerticalLine style={{ marginTop: 5, marginBottom: 5 }} />
        {/* DIFFERENTIAL Pressure */}
        <Col lg={100} md={100} sm={100} xs={100}>
          <H2 textAlign="center" style={{ fontWeight: "bold" }}>Differential Pressure</H2>
          {waterFilterCurrentPressureLoading ? (
            <Loader size={40} block />
          ) : !device_id ? (
            <Col justifyContent="center">
              <ListEmpty message="Installation Not Complete" />
            </Col>
          ) : (
            <>
              {(waterFilterCurrentPressure || []).map((pressure, index) => {
                const inletPressure = pressure && pressure.inletPressure !== undefined && 
                    pressure.inletPressure !== null && !isNaN(parseFloat(pressure.inletPressure)) ? 
                    parseFloat(pressure.inletPressure) : null;
                    
                const outletPressure = pressure && pressure.outletPressure !== undefined && 
                    pressure.outletPressure !== null && !isNaN(parseFloat(pressure.outletPressure)) ? 
                    parseFloat(pressure.outletPressure) : null;
                    
                const differential = (inletPressure !== null && outletPressure !== null) ? 
                    Math.max(0, inletPressure - outletPressure) : null;
                    
                const textColor = differential !== null && differential > 25 ? Colors.red : Colors.blue;
                
                return (
                  <Row key={index}>
                    <Col lg={100} md={100} sm={100} xs={100}>
                      <H4>
                        Filter {index + 1}
                        <span style={{ marginLeft: 20, marginRight: 20 }}> {iconTime} </span>
                        <span style={{ color: textColor }}>
                          {differential !== null ? Math.round(differential) : 'N/A'}{' '}
                        </span>
                        PSI
                      </H4>
                    </Col>
                  </Row>
                );
              })}
            </>
          )}
        </Col>
      </GraphContainer>
    );
  };

  renderBypassFrequency = () => {
    const {
      waterFilterCurrentBypassLoading,
      waterFilterCurrentBypass,
      location: {
        WaterFilterHead: { device_id },
      },
    } = this.props;

    return (
      <Col lg={50} md={50} sm={100} xs={100}>
        <H2 style={{ fontWeight: "bold" }}>Bypass Status</H2>
        {waterFilterCurrentBypassLoading ? (
          <Loader size={60} block />
        ) : !device_id ? (
          <Col justifyContent="center">
            <ListEmpty message="Installation Not Complete" />
          </Col>
        ) : (
          <>
            <Row justifyContent="center">
              {!waterFilterCurrentBypass || waterFilterCurrentBypass === 0 ? (
                <H1 color={Colors.green}>Off</H1>
              ) : (
                <H1 color={Colors.red}>On</H1>
              )}
            </Row>
            <H3
              lineHeight="0"
              color={Colors.lightBlue}
              textAlign="center"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.switchModal('modalBypass');
                this.calculeWaterFilterBypass();
              }}
            >
              Open More
            </H3>
          </>
        )}
      </Col>
    );
  };

  renderFilterCartridges = flowMeters => {
    const {
      cartridgeAllTotalConsumptionWf,
      cartridgeTotalConsumptionWfLoading,
      cartridgeAllTotalConsumptionWfLoading,
    } = this.props;
    const { totalCatridgeCapacity } = this.state;

    // Assuming all cartridges are replaced together and have the same status
    if (flowMeters && flowMeters.length > 0 && flowMeters[0].last_replaced) {
      const firstCartridge = flowMeters[0];
      
      const {
        SpotsWaterFilter: {
          Catridge: { warranty: warrantyYears },
        },
      } = firstCartridge;
      
      // Calculate weeks to expire
      const filterWeeksDuration = 52 * warrantyYears;
      const lastReplacedMoment = moment(firstCartridge.last_replaced);
      const replacedWeeks = moment().diff(lastReplacedMoment, 'weeks');

      // Check if all filters are sediment filters
      const allSedimentFilters = flowMeters.every(meter => 
        meter.SpotsWaterFilter.Catridge.sediment === true
      );
      
      // Check if some filters are sediment filters
      const hasSedimentFilters = flowMeters.some(meter => 
        meter.SpotsWaterFilter.Catridge.sediment === true
      );

      // Check for data error: totalCatridgeCapacity is 0 but flow data exists and we don't have all sediment filters
      const hasCapacityDataError = !allSedimentFilters && totalCatridgeCapacity === 0 && cartridgeAllTotalConsumptionWf > 0;
      
      // Calculate flow capacity remaining
      const usedCapacityPercent = hasCapacityDataError
        ? 100 // Assume 100% used if we have a data error
        : totalCatridgeCapacity === 0
          ? 0
          : Math.round((cartridgeAllTotalConsumptionWf * 100) / totalCatridgeCapacity);
      
      // Remaining capacity (for the pie chart)
      const remainingCapacityPercent = 100 - usedCapacityPercent;
      
      const weeksRemaining = filterWeeksDuration - replacedWeeks;
      
      // Check if capacity is exceeded by EITHER time OR flow
      const timeExceeded = weeksRemaining <= 0;
      const flowExceeded = usedCapacityPercent >= 100;
      const capacityExceeded = timeExceeded || flowExceeded;
      
      // Status can be based on either time or flow, whichever is more critical
      let healthStatus;
      if (allSedimentFilters) {
        healthStatus = 'sediment';
      } else if (hasCapacityDataError) {
        healthStatus = 'dataError';
      } else if (capacityExceeded) {
        healthStatus = 'critical';
      } else if (remainingCapacityPercent <= 20 || weeksRemaining <= 3) {
        healthStatus = 'critical';
      } else if (remainingCapacityPercent <= 40 || weeksRemaining <= 10) {
        healthStatus = 'warning';
      } else {
        healthStatus = 'good';
      }
      
      const healthColor = healthStatus === 'sediment' ? Colors.gray8 :
                         healthStatus === 'dataError' ? Colors.gray8 : 
                         healthStatus === 'good' ? Colors.green : 
                         healthStatus === 'warning' ? Colors.yellow : Colors.red;

      const pieData = [
        {
          name: 'Used',
          value: usedCapacityPercent,
        },
        {
          name: 'Remaining',
          value: remainingCapacityPercent > 0 ? remainingCapacityPercent : 0,
        },
      ];
      
      return (
        <div style={{ width: '100%' }}>
          <Row style={{ display: 'flex', width: '100%', margin: 0 }}>
            <Col xs={50} sm={50} md={50} lg={50} style={{ padding: 0 }}>
              <div style={{ 
                display: 'flex', 
                flexDirection: 'column',
                height: '100%',
                padding: '10px',
                alignItems: 'center'
              }}>
                {/* Pie Chart Container */}
                <div style={{ 
                  width: '200px',
                  height: '200px',
                  position: 'relative'
                }}>
                  {cartridgeTotalConsumptionWfLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                      <Loader size={30} />
                    </div>
                  ) : (
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart>
                        <Pie
                          data={pieData}
                          cx="50%"
                          cy="50%"
                          innerRadius={60}
                          outerRadius={80}
                          startAngle={90}
                          endAngle={-270}
                          dataKey="value"
                          activeShape={props => this.renderActiveShape({...props, allSedimentFilters})}
                          activeIndex={1}
                        >
                          {pieData.map((entry, index) => (
                            <Cell 
                              key={`cell-${index}`} 
                              fill={index === 0 ? Colors.gray3 : healthColor}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  )}
                </div>
                
                {!cartridgeAllTotalConsumptionWfLoading && (
                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <div style={{ 
                      display: 'inline-block',
                      background: '#f5f5f5',
                      padding: '5px 10px',
                      borderRadius: '4px',
                    }}>
                      {allSedimentFilters ? (
                        <H5 color={Colors.gray8} style={{ margin: 0, textAlign: 'center' }}>
                          
                        </H5>
                      ) : hasCapacityDataError ? (
                        <H5 color={Colors.gray8} style={{ margin: 0, textAlign: 'center' }}>
                          <span style={{ fontWeight: 'bold', color: Colors.gray8 }}>
                            Error
                          </span> in capacity data
                        </H5>
                      ) : (
                        <H5 color={Colors.gray8} style={{ margin: 0, textAlign: 'center' }}>
                          <span style={{ 
                            fontWeight: 'bold', 
                            color: capacityExceeded ? Colors.red : healthColor === Colors.yellow ? '#FFA000' : healthColor 
                          }}>
                            {remainingCapacityPercent}%
                          </span> capacity remaining
                        </H5>
                      )}
                      <H5 color={Colors.gray8} style={{ margin: '5px 0 0 0', textAlign: 'center', fontSize: '16px', fontFamily: 'Inter, sans-serif', fontWeight: 600 }}>
                        {cartridgeAllTotalConsumptionWf} {allSedimentFilters ? 'gal since last replaced' : `/ ${totalCatridgeCapacity || '?'} gal`}
                      </H5>
                    </div>
                  </div>
                )}
              </div>
            </Col>
            
            <Col xs={50} sm={50} md={50} lg={50} style={{ padding: 0 }}>
              <div style={{ 
                padding: '10px', 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}>
                {cartridgeTotalConsumptionWfLoading ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Loader size={30} />
                  </div>
                ) : (
                  <>
                    <div>
                      <div style={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        marginBottom: '15px',
                        borderLeft: `4px solid ${allSedimentFilters ? Colors.gray8 : hasCapacityDataError ? Colors.gray8 : capacityExceeded ? Colors.red : healthColor === Colors.yellow ? '#FFA500' : healthColor}`,
                        paddingLeft: '10px'
                      }}>
                        <H4 color={Colors.black} weight={500} style={{ margin: 0 }}>
                          Filter Status
                        </H4>
                      </div>
                      
                      <div style={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        gap: '8px',
                        marginBottom: '15px'
                      }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <H5 color={Colors.gray8}>Last Replaced:</H5>
                          <H5 color={Colors.black} weight={500}>
                            {moment(firstCartridge.last_replaced).format('MMM D, YYYY')}
                          </H5>
                        </div>
                        
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <H5 color={Colors.gray8}>Installed For:</H5>
                          <H5 color={Colors.black} weight={500}>
                            {replacedWeeks} weeks
                          </H5>
                        </div>
                        
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <H5 color={Colors.gray8}>Capacity Remaining:</H5>
                          <H5 color={allSedimentFilters ? Colors.gray8 : hasCapacityDataError ? Colors.gray8 : flowExceeded ? Colors.red : Colors.black} weight={500}>
                            {allSedimentFilters ? 'N/A' : hasCapacityDataError ? `${cartridgeAllTotalConsumptionWf} gal` : `${remainingCapacityPercent}%`}
                          </H5>
                        </div>
                      </div>
                    </div>
                    
                    <div style={{
                      background: allSedimentFilters ? '#F5F5F5' :
                        hasCapacityDataError ? '#F5F5F5' : 
                        capacityExceeded ? '#FFEBEE' : 
                        healthColor === Colors.yellow ? '#FFF8E1' : 
                        healthColor === Colors.green ? '#E8F5E9' : '#FFEBEE',
                      padding: '10px',
                      borderRadius: '4px',
                      textAlign: 'center'
                    }}>
                      <H3 
                        color={allSedimentFilters ? Colors.gray8 :
                               hasCapacityDataError ? Colors.gray8 : 
                               capacityExceeded ? Colors.red : 
                               healthColor === Colors.yellow ? '#FFA000' : healthColor} 
                        weight={600} 
                        style={{ margin: 0 }}
                      >
                        {allSedimentFilters ? 'Sediment filters do not have a gallon capacity - exhaustion is based on pressure drop.' :
                         hasCapacityDataError ? 'Data Error - Contact Support' :
                         capacityExceeded ? 'Please Replace Filters' : 
                         `${weeksRemaining} weeks remaining`}
                      </H3>
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Row>
          
          {/* Filter Cartridges List */}
          {flowMeters && flowMeters.length > 0 && (
            <Row style={{ marginTop: '15px', width: '100%', margin: '15px 0 0 0' }}>
              <Col xs={100} sm={100} md={100} lg={100} style={{ padding: 0 }}>
                <LineGray style={{ marginBottom: '10px' }} />
                <H4 color={Colors.black} weight={500} style={{ marginBottom: '15px' }}>
                  Installed Cartridges
                </H4>
                <Row style={{ 
                  width: '100%', 
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  margin: 0
                }}>
                  {flowMeters.map((meter, index) => {
                    // Calculate column width based on number of filters to ensure even spacing
                    const colWidth = flowMeters.length <= 3 ? Math.floor(100 / flowMeters.length) : 33;
                    const isSediment = meter.SpotsWaterFilter.Catridge.sediment === true;
                    
                    return (
                      <Col 
                        key={index} 
                        xs={100} 
                        sm={50} 
                        md={colWidth} 
                        lg={colWidth} 
                        style={{ 
                          marginBottom: 16,
                          paddingLeft: 8,
                          paddingRight: 8
                        }}
                      >
                        <div style={{ 
                          minHeight: 95, 
                          width: '100%', 
                          padding: '15px', 
                          backgroundColor: '#f8fbff',
                          borderRadius: '8px',
                          border: '1px solid #edf2f7',
                          boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          position: 'relative',
                        }}>
                          {isSediment && (
                            <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                              <a
                                data-tooltip-id={`sediment-tooltip-${index}`}
                                data-tooltip-content="Sediment filters (SED) do not have a chlorine reduction gallon capacity."
                              >
                                <Icon name="info-circle" color={Colors.gray8} fontSize={14} />
                              </a>
                              <TooltipReact id={`sediment-tooltip-${index}`} place="top" />
                            </div>
                          )}
                          <div style={{ marginRight: '15px' }}>
                            {capacityExceeded || healthStatus === 'critical' ? (
                              <Icon name="exclamation-circle" color={Colors.red} fontSize={30} />
                            ) : (
                              <Icon name="check-circle" color={Colors.green} fontSize={30} />
                            )}
                          </div>
                          <div>
                            <H4 style={{ margin: 0, fontSize: '16px', fontWeight: 500, color: '#333', marginBottom: 5, textAlign: 'left' }}>
                              {meter.SpotsWaterFilter.Catridge.name}
                            </H4>
                            <H5 style={{ margin: 0, color: Colors.gray8, fontSize: '12px', textAlign: 'left' }}>
                              {meter.SpotsWaterFilter.position} {isSediment && '(Sediment)'}
                            </H5>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          )}
        </div>
      );
    }
    
    return (
      <div style={{ 
        padding: '20px', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        height: '180px',
        background: '#f9f9f9',
        borderRadius: '4px'
      }}>
        <ListEmpty message="The cartridges have not been replaced yet" />
      </div>
    );
  };

  renderConfigurationModal = () => {
    const { showConfiguration } = this.state;
    const {
      location: {
        WaterFilterHead: {
          WaterFilterConfiguration,
          chlorine_or_chloramine,
          FlowMeterOfWaterFilters,
        },
      },
    } = this.props;
    const { name } = WaterFilterConfiguration;

    return (
      <Modal
        title="Configuration"
        onClose={() => this.switchModal('showConfiguration')}
        isVisible={showConfiguration}
      >
        <H4 lineHeight={2} textAlign="left" color={Colors.gray8} weight={325} style={{ margin: 0 }}>
          Configuration name
        </H4>
        <H4 lineHeight={2} color={Colors.black} weight={500} style={{ marginTop: 5 }}>
          {name}
        </H4>
        <H4 lineHeight={2} color={Colors.black} weight={500} style={{ marginTop: 5 }}>
          <Icon name="check-circle" color={Colors.lightBlue} customStyle={{ marginRight: 5 }} />
          {chlorine_or_chloramine}
        </H4>

        {FlowMeterOfWaterFilters.map(({ SpotsWaterFilter, type, name }) => {
          const { WaterFilter, Catridge } = SpotsWaterFilter;
          return (
            <>
              <H4 lineHeight={2} color={Colors.lightBlue} weight="bold" style={{ marginTop: 5 }}>
                {WaterFilter.name}
              </H4>

              <Row alignItems="left" lg={100} md={100} sm={100} xs={100}>
                <Col lg={30} md={30} sm={30} xs={30} alignItems="left">
                  <H4 lineHeight={2} weight="bold" style={{ marginTop: 5 }}>
                    {SpotsWaterFilter.position} -{Catridge.name}
                  </H4>
                </Col>
                <Col lg={70} md={70} sm={70} xs={70} alignItems="left">
                  <H4 lineHeight={2} weight="bold" style={{ marginTop: 5 }}>
                    Capacity: {this.calculeCatridgeCapacity(Catridge, chlorine_or_chloramine)} gal
                  </H4>
                </Col>
                <>
                  <Col lg={30} md={30} sm={30} xs={30} alignItems="left">
                    <H4
                      lineHeight={2}
                      textAlign="left"
                      color={Colors.gray8}
                      weight={325}
                      style={{ margin: 0 }}
                    >
                      Type
                    </H4>

                    <H4 lineHeight={2} weight="bold" style={{ marginTop: 5 }}>
                      {type}
                    </H4>
                  </Col>
                  <Col lg={70} md={70} sm={70} xs={70} alignItems="left">
                    <H4
                      lineHeight={2}
                      textAlign="left"
                      color={Colors.gray8}
                      weight={325}
                      style={{ margin: 0 }}
                    >
                      Name
                    </H4>
                    <H4 lineHeight={2} weight="bold" style={{ marginTop: 5 }}>
                      {name}
                    </H4>
                  </Col>

                  {Catridge.photo && (
                    <Col alignItems="start" xs={100} md={100} sm={100} lg={100}>
                      <img width={200} src={Catridge.photo} />
                    </Col>
                  )}
                  <VerticalLine />
                </>
              </Row>
            </>
          );
        })}
      </Modal>
    );
  };

  renderBypassConsumptions = () => {
    const {
      waterFilterBypass: { consumption = [], total_flow = 0, last_updated },
      waterFilterBypassLoading,
      location: {
        WaterFilterHead: { device_id },
      },
    } = this.props;

    const { possibleDayRangesBypass, datePickerFromBypass, datePickerToBypass } = this.state;

    const CustomTooltip = ({ payload, active }) => {
      if (active && payload && payload[0]) {
        const currentPayload = payload[0].payload;
        return (
          <div
            style={{
              backgroundColor: 'white',
              padding: 1,
              borderRadius: 5,
              borderColor: 'gray',
              borderStyle: 'solid',
              borderWidth: 1,
            }}
          >
            <p style={{ color: 'gray' }}>{currentPayload.tooltipLabel}</p>
            <p style={{ color: 'gray' }}>Flow:{Math.round(currentPayload.gal)} Gal</p>
          </div>
        );
      }

      return null;
    };

    // Create empty data for graph if no consumption data
    const displayConsumption = consumption && consumption.length > 0 ? consumption : 
      Array.from({ length: 7 }, (_, i) => {
        const date = moment(datePickerFromBypass).add(i, 'days');
        return {
          name: date.format('MM/DD'),
          gal: 0,
          tooltipLabel: date.format('MM/DD/YYYY')
        };
      });

    // Ensure total_flow is a positive number
    const displayTotalFlow = total_flow && !isNaN(parseFloat(total_flow)) && parseFloat(total_flow) > 0 
      ? Math.round(parseFloat(total_flow)) 
      : 0;

    return (
      <>
        {waterFilterBypassLoading ? (
          <GraphContainer height={450}>
            <Loader size={160} block />
          </GraphContainer>
        ) : (
          <GraphContainer height={450}>
            <>
              <Col style={{ height: 300 }} justifyContent="center">
                <Row style={{ padding: '2%' }}>
                  <Col lg={40} md={40} sm={40} xs={40}>
                    <Row
                      justifyContent="flex-start"
                      alignItems="center"
                      data
                      style={{ cursor: 'pointer' }}
                    >
                      {possibleDayRangesBypass.map(range => (
                        <H5
                          key={range}
                          cursor="pointer"
                          color={range === this.state.range ? Colors.lightBlue : Colors.gray}
                          style={{ marginLeft: 5, marginRight: 5 }}
                          onClick={() => this.calculeWaterFilterBypass(range)}
                        >
                          {range}
                        </H5>
                      ))}
                    </Row>
                  </Col>
                  <Col lg={60} md={60} sm={60} xs={60}>
                    <Row
                      justifyContent="flex-end"
                      alignItems="center"
                      data
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.switchModal('modalDatePickerBypass')}
                    >
                      <H5 color={Colors.lightBlue} style={{ marginLeft: 5, marginRight: 5 }}>
                        {moment(datePickerFromBypass).format('MM/DD/YYYY')} -
                        {moment(datePickerToBypass).format('MM/DD/YYYY')}
                      </H5>
                      <Icon
                        name="calendar-alt"
                        color={Colors.lightBlue}
                        customStyle={{ fontSize: '20px' }}
                      />
                    </Row>
                  </Col>
                </Row>
                <ResponsiveContainer width="100%">
                  {!device_id ? (
                    <Col style={{ height: 300 }} justifyContent="center">
                      <ListEmpty message="Installation Not Complete" />
                    </Col>
                  ) : (
                    <ComposedChart
                      data={displayConsumption}
                      margin={{ right: 20, left: -15, bottom: 0 }}
                      barSize={20}
                    >
                      <XAxis
                        dataKey="name"
                        stroke={Colors.gray}
                        tick={{ fontSize: 12 }}
                        scale="point"
                        padding={{ left: 15, top: 15 }}
                      />

                      <YAxis type="number" unit="" stroke={Colors.gray} tick={{ fontSize: 12 }} />
                      <Tooltip content={<CustomTooltip />} />

                      <CartesianGrid strokeDasharray="3 3" />

                      <Bar dataKey="gal">
                        {displayConsumption.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={Colors.lightBlue}
                            background={{ fill: 'transparent' }}
                          />
                        ))}
                      </Bar>
                    </ComposedChart>
                  )}
                </ResponsiveContainer>
              </Col>
              {last_updated && (
                <H5 color={Colors.gray8}>
                  Last updated: {moment.unix(last_updated).format('h:mma MM/DD/YYYY')}
                </H5>
              )}
            </>
            <VerticalLine style={{ marginTop: 15, marginBottom: 15 }} />
            <Row>
              <Col xs={100} md={100} sm={40} lg={40}>
                <H4
                  textAlign="center"
                  weight="bold"
                  marginTop={5}
                  style={{ letterSpacing: 1, lineHeight: 1 }}
                >
                  Total Flow
                </H4>
                <H5 weight="bold" color={Colors.lightBlue} marginTop={5}>
                  since installation
                </H5>
                <H2 color={Colors.blue}>{displayTotalFlow === 0 ? 'N/A' : displayTotalFlow}</H2>
                {displayTotalFlow !== 0 && <H2>gal</H2>}
              </Col>
            </Row>
          </GraphContainer>
        )}
      </>
    );
  };

  renderFilterConsumptions = () => {
    const {
      waterFilterConsumption: { consumption, last_updated, total_flow },
      waterFilterPressureForConsumption: { pressures },
      waterFilterConsumptionLoading,
      waterFilterPressureForConsumptionLoading,
      location: {
        WaterFilterHead: { device_id },
      },
      totalConsumptionWaterFilterLoading,
      waterFilterTotalConsumption,
    } = this.props;
    const {
      datePickerFromConsumption,
      datePickerToConsumption,
      possibleDayRangesConsumption,
    } = this.state;
    const CustomTooltip = ({ payload, active }) => {
      if (active && payload && payload[0]) {
        const currentPayload = payload[0].payload;
        return (
          <div
            style={{
              backgroundColor: 'white',
              padding: 10,
              borderRadius: 5,
              borderColor: 'gray',
              borderStyle: 'solid',
              borderWidth: 1,
              boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
            }}
          >
            <p style={{ color: 'gray', margin: '5px 0', fontWeight: 'bold' }}>{currentPayload.tooltipLabel}</p>
            <p style={{ color: 'gray', margin: '5px 0' }}>{Math.round(currentPayload.gal)} Gal</p>
            {currentPayload.psi_0 !== undefined ? (
              <p style={{ color: 'gray', margin: '5px 0' }}>
                Filter 1 Max PD: {Math.round(currentPayload.psi_0)} PSI
              </p>
            ) : null}
            {currentPayload.psi_1 !== undefined ? (
              <p style={{ color: 'gray', margin: '5px 0' }}>
                Filter 2 Max PD: {Math.round(currentPayload.psi_1)} PSI
              </p>
            ) : null}
            {currentPayload.psi_2 !== undefined ? (
              <p style={{ color: 'gray', margin: '5px 0' }}>
                Filter 3 Max PD: {Math.round(currentPayload.psi_2)} PSI
              </p>
            ) : null}
          </div>
        );
      }

      return null;
    };

    const dataChart = consumption.map((consumption, index) => ({
      ...pressures[index],
      ...consumption,
    }));

    // Helper function to check if a specific range is in the available ranges
    const isRangeAvailable = (range) => {
      return possibleDayRangesConsumption.includes(range);
    };

   

    return (
      <GraphContainer height={540}>
        <Col>
          {/* Flow Volume Header Row */}
          <Row justifyContent="space-between" alignItems="center" style={{ marginBottom: 20 }}>
            <H2 style={{ margin: 0, fontWeight: 600, fontSize: '24px', fontFamily: 'Inter, sans-serif' }}>Flow Volume</H2>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/* Date range selector */}
              <div
                style={{ 
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  padding: '8px 15px',
                  borderRadius: 5,
                  backgroundColor: '#e8f4fd',
                  transition: 'all 0.2s ease'
                }}
                onClick={() => this.switchModal('modalDatePickerConsumption')}
              >
                <H5 style={{ margin: 0, marginRight: 8, color: '#1a73e8', fontWeight: 400 }}>
                  {moment(datePickerFromConsumption).format('MM/DD/YYYY')} - {moment(datePickerToConsumption).format('MM/DD/YYYY')}
                </H5>
                <Icon
                  name="calendar-alt"
                  color="#1a73e8"
                  fontSize={16}
                />
              </div>

              {/* Info tooltip */}
              <div style={{ marginLeft: 10 }}>
                <a
                  data-tooltip-id="flow-volume-tooltip"
                  data-tooltip-html="<strong>Flow Volume Graph</strong> <br>Displays total flow volume by time period."
                >
                  <Icon
                    name="info-circle"
                    color={Colors.gray8}
                    fontSize={16}
                  />
                </a>
                <TooltipReact id="flow-volume-tooltip" />
              </div>
            </div>
          </Row>

          <Row>
            
          </Row>
          {/* Graph Row */}
          <Col style={{ height: 300, marginBottom: 10 }} justifyContent="center">
            {!device_id ? (
              <Col style={{ height: 300 }} justifyContent="center">
                <ListEmpty message="Installation Not Complete" />
              </Col>
            ) : (
              <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                {waterFilterConsumptionLoading && (
                  <div style={{ 
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    zIndex: 10,
                    borderRadius: '5px'
                  }}>
                    <div style={{ 
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center', 
                      backgroundColor: 'white',
                      padding: '15px',
                      borderRadius: '8px',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                    }}>
                      <Loader size={30} />
                      <H5 color={Colors.gray8} style={{ marginTop: 10, margin: 0 }}>Loading chart data...</H5>
                    </div>
                  </div>
                )}
                <ResponsiveContainer width="100%" id="totalGaloonsChart">
                  <ComposedChart
                    data={waterFilterConsumptionLoading ? [] : dataChart}
                    margin={{ right: 20, left: -15, bottom: 0 }}
                    barSize={20}
                  >
                    <XAxis
                      dataKey="name"
                      stroke={Colors.gray}
                      tick={{ fontSize: 12 }}
                      scale="point"
                      padding={{ left: 15, top: 15 }}
                    />

                    <YAxis type="number" unit="" stroke={Colors.gray} tick={{ fontSize: 12 }} />
                    <Tooltip content={<CustomTooltip />} />

                    <CartesianGrid strokeDasharray="3 3" />

                    <Bar
                      dataKey="gal"
                      legendType="none"
                      tooltipType="none"
                      fill={Colors.lightBlue}
                    >
                      {(waterFilterConsumptionLoading ? [] : consumption).map((entry, index) => (
                        <Cell key={`cell-${index}`} background={{ fill: 'transparent' }} />
                      ))}
                    </Bar>
                    {!waterFilterConsumptionLoading && !waterFilterPressureForConsumptionLoading && (
                      <>
                        <Line dataKey="psi_0" stroke="#003cff" animationDuration={3000} />
                        <Line dataKey="psi_1" stroke="#35d419" animationDuration={3500} />
                        <Line dataKey="psi_2" stroke="#ffaa00" animationDuration={4000} />
                      </>
                    )}
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            )}
            {last_updated && !waterFilterConsumptionLoading && (
              <H5 color={Colors.gray8} style={{ textAlign: 'right', marginTop: 5 }}>
                Last updated: {moment.unix(last_updated).format('h:mma MM/DD/YYYY')}
              </H5>
            )}
          </Col>

          {/* Stats Row */}
          <Row style={{ width: '100%' }}>
            {/* Flow for Selected Period */}
            <Col xs={50} md={50} sm={50} lg={50} style={{ paddingRight: 8 }}>
              <div style={{ 
                minHeight: 95, 
                width: '100%', 
                padding: '15px', 
                backgroundColor: '#f8fbff',
                borderRadius: '8px',
                border: '1px solid #edf2f7',
                boxShadow: '0 1px 3px rgba(0,0,0,0.05)'
              }}>
                <H4 weight="bold" style={{ margin: 0, fontSize: '16px', fontWeight: 500, color: '#333' }}>
                  Flow for Selected Period
                </H4>
                <div style={{ marginTop: 8, textAlign: 'right' }}>
                  {waterFilterConsumptionLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                      <Loader size={15} />
                      <H5 color={Colors.gray8} style={{ marginLeft: 10, margin: 0 }}>Loading...</H5>
                    </div>
                  ) : (
                    <div style={{ textAlign: 'left' }}>
                      <span style={{ fontSize: '28px', fontWeight: 600, color: '#1a73e8' }}>
                        {total_flow === 0 ? 'N/A' : Math.round(total_flow).toLocaleString()}
                      </span>
                      {total_flow !== 0 && (
                        <span style={{ color: '#333', marginLeft: 5, fontSize: '16px' }}> gal</span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Col>

            {/* Total Flow Since Installation */}
            <Col xs={50} md={50} sm={50} lg={50} style={{ paddingLeft: 8 }}>
              <div style={{ 
                minHeight: 95, 
                width: '100%', 
                padding: '15px', 
                backgroundColor: '#f8fbff',
                borderRadius: '8px',
                border: '1px solid #edf2f7',
                boxShadow: '0 1px 3px rgba(0,0,0,0.05)'
              }}>
                <H4 weight="bold" style={{ margin: 0, fontSize: '16px', fontWeight: 500, color: '#333' }}>
                  Total Flow Since Installation
                </H4>
                <div style={{ marginTop: 8, textAlign: 'right' }}>
                  {totalConsumptionWaterFilterLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                      <Loader size={15} />
                      <H5 color={Colors.gray8} style={{ marginLeft: 10, margin: 0 }}>Loading...</H5>
                    </div>
                  ) : (
                    <div style={{ textAlign: 'left' }}>
                      <span style={{ fontSize: '28px', fontWeight: 600, color: '#1a73e8' }}>
                        {waterFilterTotalConsumption === 0 ? 'N/A' : Math.round(waterFilterTotalConsumption).toLocaleString()}
                      </span>
                      {waterFilterTotalConsumption !== 0 && (
                        <span style={{ color: '#333', marginLeft: 5, fontSize: '16px' }}> gal</span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </GraphContainer>
    );
  };

  renderMaxFlowAndPressures = () => {
    const {
      waterFilterMaxFlowRate: { consumption },
      waterFilterPressureForFlowRate: { pressures },
      waterFilterMaxFlowRateLoading,
      waterFilterPressureForFlowRateLoading,
      location: {
        WaterFilterHead: { device_id },
      },
    } = this.props;

    const {
      possibleDayRangesMaxFlowRate,
      datePickerFromMaxFlowRate,
      datePickerToMaxFlowRate,
    } = this.state;

    const CustomTooltip = ({ payload, active }) => {
      if (active && payload && payload[0]) {
        const currentPayload = payload[0].payload;
        return (
          <div
            style={{
              backgroundColor: 'white',
              padding: 1,
              borderRadius: 5,
              borderColor: 'gray',
              borderStyle: 'solid',
              borderWidth: 1,
            }}
          >
            <p style={{ color: 'gray' }}>{currentPayload.tooltipLabel}</p>
            <p style={{ color: 'gray' }}>Max Flow Rate:{currentPayload.gal.toFixed(2)} Gal</p>

            {currentPayload.psi_0 !== undefined ? (
              <p style={{ color: 'gray' }}>
                Filter 1 Max PD: {currentPayload.psi_0.toFixed(2)} PSI
              </p>
            ) : null}
            {currentPayload.psi_1 !== undefined ? (
              <p style={{ color: 'gray' }}>
                Filter 2 Max PD: {currentPayload.psi_1.toFixed(2)} PSI
              </p>
            ) : null}
            {currentPayload.psi_2 !== undefined ? (
              <p style={{ color: 'gray' }}>
                Filter 3 Max PD: {currentPayload.psi_2.toFixed(2)} PSI
              </p>
            ) : null}
          </div>
        );
      }

      return null;
    };

    const dataChart = consumption.map((consumption, index) => ({
      ...pressures[index],
      ...consumption,
    }));

    return (
      <GraphContainer height={360}>
        <H2 textAlign="center" style={{ fontWeight: "bold" }}>Max Pressure Differential & Flow Rate</H2>
        {waterFilterMaxFlowRateLoading ? (
          <GraphContainer>
            <Loader size={100} block />
          </GraphContainer>
        ) : (
          <>
            <Row style={{ marginBottom: 15 }}>
              <Col lg={40} md={40} sm={40} xs={40}>
                <Row
                  justifyContent="flex-start"
                  alignItems="center"
                  data
                  style={{ cursor: 'pointer' }}
                >
                  {possibleDayRangesMaxFlowRate.map(range => (
                    <H5
                      cursor="pointer"
                      color={range === this.state.range ? Colors.lightBlue : Colors.gray}
                      style={{ marginLeft: 5, marginRight: 5 }}
                      onClick={() => this.calculeWaterFilterMaxFlowRate(range)}
                    >
                      {range}
                    </H5>
                  ))}
                </Row>
              </Col>
              <Col lg={60} md={60} sm={60} xs={100}>
                <Row>
                  <div
                    style={{ cursor: 'pointer' }}
                    justifyContent="flex-end"
                    alignItems="center"
                    data
                    onClick={() => this.switchModal('modalDatePickerMaxFlowRate')}
                  >
                    <Row>
                      <H5 color={Colors.lightBlue} style={{ marginLeft: 5, marginRight: 5 }}>
                        {moment(datePickerFromMaxFlowRate).format('MM/DD/YYYY')} -
                        {moment(datePickerToMaxFlowRate).format('MM/DD/YYYY')}
                      </H5>
                      <Icon
                        name="calendar-alt"
                        color={Colors.lightBlue}
                        customStyle={{ fontSize: '20px' }}
                      />
                    </Row>
                  </div>

                  <div>
                    <a
                      data-tooltip-id="my-tooltip"
                      data-tooltip-html="<strong>Filter Pressure Differential & Flow Rate Graph:</strong> <br>Displays daily max flow rate and daily max pressure differential. <br>A 'potential filter clog alert' is sent when pressure differential meets or exceeds 25PSI."
                    >
                      <Icon name="info-circle" customStyle={{ marginLeft: 5 }} />
                    </a>
                    <TooltipReact id="my-tooltip" />
                  </div>
                </Row>
              </Col>
            </Row>

            <GraphContainer>
              <Col style={{ height: 300 }} justifyContent="center">
                {waterFilterPressureForFlowRateLoading && (
                  <Row style={{ width: 200, margin: 'auto' }}>
                    <Loader size={15} block />
                    <H5 color={Colors.lightBlue} marginTop={5}>
                      Loading max pressures...
                    </H5>
                  </Row>
                )}
                <ResponsiveContainer width="100%" id="maxFlowAndPressureChart">
                  {!device_id ? (
                    <Col style={{ height: 300 }} justifyContent="center">
                      <ListEmpty message="Installation Not Complete" />
                    </Col>
                  ) : (
                    <ComposedChart
                      data={dataChart}
                      margin={{ right: 20, left: -15, bottom: 0 }}
                      barSize={20}
                    >
                      <XAxis
                        dataKey="name"
                        stroke={Colors.gray}
                        tick={{ fontSize: 12 }}
                        scale="point"
                        padding={{ left: 15, top: 15 }}
                      />

                      <YAxis type="number" unit="" stroke={Colors.gray} tick={{ fontSize: 12 }} />
                      <Tooltip content={<CustomTooltip />} />

                      <CartesianGrid strokeDasharray="3 3" />

                      <Line
                        dataKey="gal"
                        legendType="none"
                        tooltipType="none"
                        fill={Colors.lightBlue}
                      >
                        {consumption.map((entry, index) => (
                          <Cell key={`cell-${index}`} background={{ fill: 'transparent' }} />
                        ))}
                      </Line>
                      {!waterFilterPressureForFlowRateLoading && (
                        <Line dataKey="psi_0" stroke="#003cff" animationDuration={3000} />
                      )}
                      {!waterFilterPressureForFlowRateLoading && (
                        <Line dataKey="psi_1" stroke="#35d419" animationDuration={3500} />
                      )}
                      {!waterFilterPressureForFlowRateLoading && (
                        <Line dataKey="psi_2" stroke="#ffaa00" animationDuration={4000} />
                      )}
                    </ComposedChart>
                  )}
                </ResponsiveContainer>
              </Col>
            </GraphContainer>
          </>
        )}
      </GraphContainer>
    );
  };

  switchModal = modalName => {
    this.setState({ [modalName]: !this.state[modalName] });
  };

  handleChangeDeviceId = evt => {
    this.setState({ new_device_id: evt.target.value });
  };

  generateWaterFilterCsv = () => {
    const {
      generateWaterFilterCsv,
      location: {
        id,
        WaterFilterHead: { device_id },
      },
    } = this.props;
    const { csvTypeString } = this.state;
    const { datePickerFromCsv: from, datePickerToCsv: to } = this.state;
    generateWaterFilterCsv({ device_id, location_id: id, from, to, csvContains: csvTypeString });
  };

  changeCsvType = (csvType, nro) => {
    const csvTypes = ['raw_data', 'calculated_data', 'all_data'];

    this.setState({ [csvType]: nro, csvTypeString: csvTypes[nro - 1] });
  };

  calculeWaterFilterConsumption = (range, wf_for_pfd) => {
    const {
      getWaterFilterConsumption,
      getWaterFilterPressureForConsumption,
      location: {
        WaterFilterHead: { device_id },
      },
    } = this.props;
    const { datePickerFromConsumption: from, datePickerToConsumption: to } = this.state;

    // Calculate possible ranges but default to 'days'
    const possibleDayRangesConsumption = this.calculeDaysRange(from, to);
    
    // If no specific range is provided or the provided range is not available, use 'days'
    const currentRange = range || 'days';
    
    // Call API with the determined range
    getWaterFilterConsumption({ device_id, from, to, range: currentRange, wf_for_pfd });
    getWaterFilterPressureForConsumption({ device_id, from, to, range: currentRange, wf_for_pfd });

    this.setState({ possibleDayRangesConsumption, range: currentRange });
  };

  calculeWaterFilterMaxFlowRate = (range, wf_for_pfd) => {
    const {
      getWaterFilterMaxFlowRate,
      getWaterFilterPressureForFlowRate,
      location: {
        WaterFilterHead: { device_id },
      },
    } = this.props;
    const { datePickerFromMaxFlowRate: from, datePickerToMaxFlowRate: to } = this.state;

    const possibleDayRangesMaxFlowRate = this.calculeDaysRange(from, to);
    const currentRange = range || possibleDayRangesMaxFlowRate[0];
    getWaterFilterMaxFlowRate({ device_id, from, to, range: currentRange, wf_for_pfd });
    getWaterFilterPressureForFlowRate({ device_id, from, to, range: currentRange, wf_for_pfd });

    this.setState({ possibleDayRangesMaxFlowRate, range: currentRange });
  };

  calculeWaterFilterBypass = range => {
    const {
      getWaterFilterBypass,
      location: {
        WaterFilterHead: { device_id, installed_date },
      },
    } = this.props;
    const { datePickerFromBypass: from, datePickerToBypass: to } = this.state;

    const possibleDayRangesBypass = this.calculeDaysRange(from, to);

    const currentRange = range || possibleDayRangesBypass[0] || 'days';
    getWaterFilterBypass({ device_id, from, to, installed_date, range: currentRange });
    this.setState({ possibleDayRangesBypass, range: currentRange });
  };

  calculeDaysRange = (from, to) => {
    const possibleDayRanges = [];
    const fromDate = moment(from);
    const toDate = moment(to);
    const daysRange = toDate.diff(fromDate, 'days');

    // The maximum of days chosen in each condition is the equivalent of the maximum of 15 bars on the chart
    if (daysRange <= 1) possibleDayRanges.push('hours');
    if (daysRange >= 1 && daysRange < 15) possibleDayRanges.push('days');
    if (daysRange >= 14 && daysRange < 105) possibleDayRanges.push('weeks');
    if (daysRange >= 30 && daysRange < 450) possibleDayRanges.push('months');
    if (daysRange >= 365) possibleDayRanges.push('years');
    return possibleDayRanges;
  };

  replaceWaterFilterCartridgesFunc = () => {
    const {
      location: {
        WaterFilterHead: { id: water_filter_head_id },
      },
      replaceWaterFilterCartridges,
    } = this.props;

    const { cartridgesChangeDate } = this.state;
    const cartridgesChangeDateUTC = moment(cartridgesChangeDate)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');

    replaceWaterFilterCartridges({ water_filter_head_id, new_date: cartridgesChangeDateUTC });
  };

  handleDate = (value, key) => {
    this.setState({ [key]: value });
  };

  generateReport = async () => {
    try {
      const { datePickerFromReport, datePickerToReport } = this.state;

      this.setState(
        {
          datePickerFromConsumption: datePickerFromReport,
          datePickerToConsumption: datePickerToReport,
          datePickerFromMaxFlowRate: datePickerFromReport,
          datePickerToMaxFlowRate: datePickerToReport,
          generatePDF: true,
        },
        () => {
          this.calculeWaterFilterConsumption(false, true);
          this.calculeWaterFilterMaxFlowRate(false, true);
        },
      );
    } catch (error) {
      this.setState({ generatePDF: false });
      console.log(error);
    }
  };

  downloadPDF = () => {
    const {
      totalGaloonsChart,
      exhaustionChart,
      maxFlowAndPressureChart,
      downloadPDF,
      datePickerFromConsumption,
      datePickerToConsumption,
      catridgeSelectedObjet,
      catridgeCapacity,
      totalCatridgeCapacity,
    } = this.state;
    const {
      location,
      allAlerts,
      waterFilterConsumption,
      waterFilterTotalConsumption,
      waterFilterCurrentPressure,
      cartridgeTotalConsumptionWf,
      cartridgeAllTotalConsumptionWf,
      waterFilterCurrentBypass,
    } = this.props;

    if (downloadPDF) {
      return (
        <PDFDownloadLink
          document={
            <ReportWF
              datePickerFrom={datePickerFromConsumption}
              datePickerTo={datePickerToConsumption}
              totalGaloonsChart={totalGaloonsChart}
              exhaustionChart={exhaustionChart}
              maxFlowAndPressureChart={maxFlowAndPressureChart}
              location={location}
              allAlerts={allAlerts}
              waterFilterConsumption={waterFilterConsumption}
              waterFilterTotalConsumption={waterFilterTotalConsumption}
              catridgeSelectedObjet={catridgeSelectedObjet}
              cartridgeTotalConsumptionWf={cartridgeTotalConsumptionWf}
              cartridgeAllTotalConsumptionWf={cartridgeAllTotalConsumptionWf}
              totalCatridgeCapacity={totalCatridgeCapacity}
              pressures={waterFilterCurrentPressure}
              catridgeCapacity={catridgeCapacity}
              waterFilterCurrentBypass={waterFilterCurrentBypass}
            />
          }
          fileName="ReportWF.pdf"
        >
          {({ loading }) => (
            <H4
              color={Colors.lightBlue}
              style={{ cursor: 'pointer' }}
              onClick={() => this.setState({ generatePDF: false, downloadPDF: false })}
            >
              <Icon
                name={loading ? 'far fa-spinner' : 'far fa-file-download'}
                color={Colors.lightBlue}
                customStyle={{ marginRight: '5px' }}
              />
              {loading ? 'Loading' : 'Download'} Report
            </H4>
          )}
        </PDFDownloadLink>
      );
    }
    return (
      <Row>
        <Loader size={35} block />
        <H4 color={Colors.lightBlue} marginTop={5}>
          Generating PDF ...
        </H4>
      </Row>
    );
  };

  // Add this method after the calculeWaterFilterBypass method
  switchToPressureDetail = () => {
    this.setState({ modalPressureDetail: true });
  };

  // Add this method to render detailed pressure information for all filters
  renderPressureDetailModal = () => {
    const {
      waterFilterCurrentPressure,
      waterFilterCurrentPressureLoading,
      location: {
        WaterFilterHead: { device_id },
      },
    } = this.props;

    const { modalPressureDetail } = this.state;

    return (
      <Modal
        title="Filter Pressure Details"
        onClose={() => this.setState({ modalPressureDetail: false })}
        isVisible={modalPressureDetail}
        size="medium"
      >
        {waterFilterCurrentPressureLoading ? (
          <GraphContainer height={300}>
            <Loader size={120} block />
          </GraphContainer>
        ) : !device_id ? (
          <Col justifyContent="center">
            <ListEmpty message="Installation Not Complete" />
          </Col>
        ) : (
          <div style={{ padding: '10px' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={{ padding: '10px', borderBottom: '1px solid #ECEBF0', textAlign: 'left' }}>Filter</th>
                  <th style={{ padding: '10px', borderBottom: '1px solid #ECEBF0', textAlign: 'center' }}>Inlet (PSI)</th>
                  <th style={{ padding: '10px', borderBottom: '1px solid #ECEBF0', textAlign: 'center' }}>Outlet (PSI)</th>
                  <th style={{ padding: '10px', borderBottom: '1px solid #ECEBF0', textAlign: 'center' }}>Differential (PSI)</th>
                </tr>
              </thead>
              <tbody>
                {waterFilterCurrentPressure.map((pressure, index) => {
                  const inletPressure = pressure && pressure.inletPressure !== undefined && 
                       pressure.inletPressure !== null && !isNaN(parseFloat(pressure.inletPressure)) ? 
                       parseFloat(pressure.inletPressure) : null;
                       
                  const outletPressure = pressure && pressure.outletPressure !== undefined && 
                       pressure.outletPressure !== null && !isNaN(parseFloat(pressure.outletPressure)) ? 
                       parseFloat(pressure.outletPressure) : null;
                       
                  const differential = (inletPressure !== null && outletPressure !== null) ? 
                       Math.max(0, inletPressure - outletPressure) : null;
                  
                  return (
                    <tr key={index}>
                      <td style={{ padding: '12px', borderBottom: '1px solid #ECEBF0', fontWeight: 500 }}>Filter {index + 1}</td>
                      <td style={{ padding: '12px', borderBottom: '1px solid #ECEBF0', textAlign: 'center', color: Colors.blue }}>
                        {inletPressure !== null ? Math.round(inletPressure) : 'N/A'}
                      </td>
                      <td style={{ padding: '12px', borderBottom: '1px solid #ECEBF0', textAlign: 'center', color: Colors.blue }}>
                        {outletPressure !== null ? Math.round(outletPressure) : 'N/A'}
                      </td>
                      <td style={{ padding: '12px', borderBottom: '1px solid #ECEBF0', textAlign: 'center', color: differential !== null && differential > 25 ? Colors.red : Colors.blue }}>
                        {differential !== null ? Math.round(differential) : 'N/A'}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div style={{ marginTop: '15px', padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '4px' }}>
              <H5 color={Colors.gray8}>
                <span style={{ color: Colors.red, fontWeight: 500 }}>Note:</span> A differential pressure above 25 PSI may indicate a clogged filter that needs replacement.
              </H5>
            </div>
          </div>
        )}
      </Modal>
    );
  };

  render() {
    const {
      location,
      location: { WaterFilterHead, id: location_id },
      waterFilterConsumption,
      waterFilterConsumptionLoading,
      totalConsumptionWaterFilterLoading,
      waterFiltercurrentStateLoading,
      cartridgeTotalConsumptionWfLoading,
      waterFilterBypass,
      waterFilterBypassLoading,
      getCsvFiles,
      csvFiles,
      csvFilesLoading,
      setDeviceId,
      role_id,
      waterFilterCurrentPressure,
      waterFilterCurrentPressureLoading,
      waterFilterCurrentBypass,
      waterFilterCurrentBypassLoading,
      waterFilterCartridges,
      waterFilterCartridgesLoading,
      waterFilterMaxFlowRate,
      waterFilterMaxFlowRateLoading,
      waterFilterPressureForConsumptionLoading,
      waterFilterPressureForFlowRateLoading,
      waterFilterBypassSetting,
      renderInstructionsModal,
    } = this.props;

    const {
      cartridgesChangeDate,
      showConfiguration,
      generatePDF,
      modalDatePickerConsumption,
      modalCartridges,
      datePickerFromConsumption,
      datePickerToConsumption,
      catridgeSelectedId,
      catridgeSelectedObjet,
      modalDatePickerCsv,
      datePickerFromCsv,
      datePickerToCsv,
      csvType,
      new_device_id,
      modalDeviceId,
      modalBypass,
      datePickerFromBypass,
      datePickerToBypass,
      modalDatePickerBypass,
      datePickerFromReport,
      datePickerToReport,
      modalDatePickerReport,
      modalDatePickerMaxFlowRate,
      datePickerFromMaxFlowRate,
      datePickerToMaxFlowRate,
      possibleDayRangesConsumption,
    } = this.state;

    const {
      WaterFilterConfiguration,
      FlowMeterOfWaterFilters,
      device_id,
      installed_date,
    } = WaterFilterHead;
    
    const { WatherFilterInstallSteps } = WaterFilterConfiguration || {};
    const cartridgesOfWFChanges = FlowMeterOfWaterFilters?.[0]?.CartridgesOfWFChanges;

    const getDataCompleted =
      !waterFilterConsumptionLoading &&
      !totalConsumptionWaterFilterLoading &&
      !waterFiltercurrentStateLoading &&
      !waterFilterCurrentBypassLoading &&
      !cartridgeTotalConsumptionWfLoading &&
      !waterFilterMaxFlowRateLoading &&
      !waterFilterPressureForConsumptionLoading &&
      !waterFilterPressureForFlowRateLoading;
    
    // Calculate average pressures across filters
    let avgInletPressure = 0;
    let avgOutletPressure = 0;
    let avgDifferentialPressure = 0;
    let multipleFilters = false;
    
    if (waterFilterCurrentPressure && waterFilterCurrentPressure.length > 0) {
      multipleFilters = waterFilterCurrentPressure.length > 1;
      
      // Count only valid filter values
      let validInletFilters = 0;
      let validOutletFilters = 0;
      let validDiffFilters = 0;
      
      // Calculate sums with validation
      const inletSum = waterFilterCurrentPressure.reduce((sum, filter) => {
        // Check if the value is a number and not NaN/undefined/null
        if (filter && filter.inletPressure !== undefined && 
            filter.inletPressure !== null && 
            !isNaN(parseFloat(filter.inletPressure))) {
          validInletFilters++;
          return sum + parseFloat(filter.inletPressure);
        }
        return sum;
      }, 0);
      
      const outletSum = waterFilterCurrentPressure.reduce((sum, filter) => {
        if (filter && filter.outletPressure !== undefined && 
            filter.outletPressure !== null && 
            !isNaN(parseFloat(filter.outletPressure))) {
          validOutletFilters++;
          return sum + parseFloat(filter.outletPressure);
        }
        return sum;
      }, 0);
      
      const diffSum = waterFilterCurrentPressure.reduce((sum, filter) => {
        if (filter && filter.inletPressure !== undefined && filter.inletPressure !== null && 
            !isNaN(parseFloat(filter.inletPressure)) && 
            filter.outletPressure !== undefined && filter.outletPressure !== null && 
            !isNaN(parseFloat(filter.outletPressure))) {
          validDiffFilters++;
          const diff = Math.max(0, parseFloat(filter.inletPressure) - parseFloat(filter.outletPressure));
          return sum + diff;
        }
        return sum;
      }, 0);
      
      // Calculate averages only if we have valid data
      avgInletPressure = validInletFilters > 0 ? inletSum / validInletFilters : 0;
      avgOutletPressure = validOutletFilters > 0 ? outletSum / validOutletFilters : 0;
      avgDifferentialPressure = validDiffFilters > 0 ? diffSum / validDiffFilters : 0;
    }
    
    // Format bypass status
    const bypassStatus = waterFilterCurrentBypass === 1;
    const bypassStatusText = bypassStatus ? 'On' : 'Off';

    return (
      <Container>
        <Row>
          <Col lg={50} md={100} sm={100} xs={100}>
            <BG>{this.renderFilterConsumptions(catridgeSelectedObjet)}</BG>
          </Col>

          <Col lg={50} md={100} sm={100} xs={100}>
            <BG>
              <GraphContainer height={540}>
                <Col>
                  {/* Filter Capacities Header Row */}
                  <Row justifyContent="space-between" alignItems="center" style={{ marginBottom: 20 }}>
                    <H2 style={{ margin: 0, fontWeight: 600, fontSize: '24px', fontFamily: 'Inter, sans-serif' }}>Filter Capacities</H2>
                    <div 
                      style={{ 
                        backgroundColor: '#1498CB', 
                        borderRadius: '5px', 
                        padding: '8px 16px',
                        display: 'inline-flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        transition: 'background-color 0.2s ease'
                      }}
                      onClick={() => {
                        this.setState({ cartridgesChangeDate: new Date() });
                        this.switchModal('modalCartridges');
                      }}
                      onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#0e80ad'}
                      onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#1498CB'}
                    >
                      <Icon
                        name="sync"
                        color="#FFFFFF"
                        fontSize={16}
                        customStyle={{ marginRight: '8px' }}
                      />
                      <H5 color="#FFFFFF" style={{ margin: 0, cursor: 'pointer' }}>Replace Cartridges</H5>
                    </div>
                  </Row>
                  
                  {/* Filter Cartridges Content */}
                  <Col lg={100} md={100} sm={100} xs={100} style={{ width: '100%', padding: 0 }}>
                    {this.renderFilterCartridges(FlowMeterOfWaterFilters)}
                  </Col>
                </Col>
              </GraphContainer>
            </BG>
          </Col>
        </Row>

        {/* System Stats Section Header */}
        <Row style={{ 
          width: '100%', 
          display: 'flex', 
          justifyContent: 'flex-start',
          textAlign: 'left',
          marginLeft: 0,
          marginRight: 0
        }}>
          <Col lg={100} md={100} sm={100} xs={100} style={{ 
            marginTop: 24, 
            marginBottom: 8, 
            padding: '0 8px',
            textAlign: 'left',
            alignItems: 'flex-start'
          }}>
            <H2 style={{ 
              margin: 0, 
              fontWeight: 600, 
              fontSize: '24px', 
              fontFamily: 'Inter, sans-serif', 
              textAlign: 'left', 
              width: '100%',
              display: 'block'
            }}>System Stats</H2>
          </Col>
        </Row>

        {/* Quick Stats Row */}
        <Row style={{ marginTop: 16, marginBottom: 24 }}>
          {/* Connected pressure cards */}
          <Col lg={75} md={75} sm={75} xs={100} style={{ padding: '0 8px', marginBottom: 16 }}>
            <div style={{ display: 'flex', width: '100%', gap: 0 }}>
              <div style={{ flex: 1 }}>
                <QuickStats
                  icon="tachometer-alt"
                  title="Inlet Pressure"
                  value={!isNaN(avgInletPressure) ? Math.round(avgInletPressure) : 0}
                  unit="PSI"
                  isLoading={waterFilterCurrentPressureLoading}
                  notInstalled={!device_id}
                  borderRadius="8px 0 0 8px"
                />
              </div>
              <div style={{ flex: 1 }}>
                <QuickStats
                  icon="tachometer-alt"
                  title="Outlet Pressure"
                  value={!isNaN(avgOutletPressure) ? Math.round(avgOutletPressure) : 0}
                  unit="PSI"
                  isLoading={waterFilterCurrentPressureLoading}
                  notInstalled={!device_id}
                  borderRadius="0"
                />
              </div>
              <div style={{ flex: 1, position: 'relative' }}>
                <QuickStats
                  icon="tachometer-alt"
                  title="Differential Pressure"
                  value={!isNaN(avgDifferentialPressure) ? Math.round(avgDifferentialPressure) : 0}
                  unit="PSI"
                  isLoading={waterFilterCurrentPressureLoading}
                  notInstalled={!device_id}
                  borderRadius="0 8px 8px 0"
                />
                {multipleFilters && (
                  <div 
                    style={{ 
                      position: 'absolute', 
                      bottom: 8, 
                      right: 16, 
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px'
                    }}
                    onClick={this.switchToPressureDetail}
                  >
                    <H5 color={Colors.lightBlue}>Details</H5>
                    <Icon name="chevron-right" color={Colors.lightBlue} fontSize={12} />
                  </div>
                )}
              </div>
            </div>
          </Col>

          {/* Bypass status */}
          <Col lg={25} md={25} sm={25} xs={100} style={{ padding: '0 8px', marginBottom: 16 }}>
            <QuickStats
              icon="exchange-alt"
              iconColor={bypassStatus ? Colors.red : Colors.green}
              iconBgColor={bypassStatus ? "rgba(235, 87, 87, 0.1)" : "rgba(39, 174, 96, 0.1)"}
              title="Bypass Status"
              value={bypassStatusText}
              valueColor={bypassStatus ? Colors.red : Colors.green}
              isLoading={waterFilterCurrentBypassLoading}
              notInstalled={!device_id}
              onClick={() => {
                this.switchModal('modalBypass');
                this.calculeWaterFilterBypass();
              }}
            />
          </Col>
        </Row>

        <Row style={{ marginBottom: 5, marginTop: 5 }}>
          <Col lg={100} md={100} sm={100} xs={100}>
            <BG>{this.renderMaxFlowAndPressures()}</BG>
          </Col>
        </Row>

        {this.renderConfigurationModal()}
        {this.renderPressureDetailModal()}
        
        <ModalCsvFiles
          minDate={moment(installed_date).toDate()}
          isVisible={modalDatePickerCsv}
          handleDateFrom={value => this.handleDate(value, 'datePickerFromCsv')}
          handleDateTo={value => this.handleDate(value, 'datePickerToCsv')}
          switchModal={() => this.switchModal('modalDatePickerCsv')}
          dateFrom={datePickerFromCsv}
          dateTo={datePickerToCsv}
          changeCsvType={this.changeCsvType}
          csvType={csvType}
          saveChanges={() => {
            this.switchModal('modalDatePickerCsv');
            this.generateWaterFilterCsv();
          }}
          csvFiles={csvFiles}
          csvFilesLoading={csvFilesLoading}
        />

        <Modal
          title="Replace Cartridges"
          onClose={() => this.switchModal('modalCartridges')}
          isVisible={modalCartridges}
          size="small"
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <H4 color={Colors.gray4}>Changed date</H4>
            <DatePickerComponent
              minDate={moment(installed_date).toDate()}
              date={cartridgesChangeDate}
              onChange={value => this.handleDate(value, 'cartridgesChangeDate')}
              style={{ width: '100%' }}
            />
          </div>
          <Row
            style={{
              marginTop: 30,
              marginBottom: 10,
              gap: 10,
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <ButtonText
              text="Cancel"
              theme="secondary"
              type="submit"
              onClick={() => this.switchModal('modalCartridges')}
            />
            <ButtonText
              text="Confirm"
              theme="primary"
              type="submit"
              onClick={() => {
                this.replaceWaterFilterCartridgesFunc();
                this.switchModal('modalCartridges');
              }}
            />
          </Row>

          {cartridgesOfWFChanges && cartridgesOfWFChanges.length > 0 && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 5,
                }}
              >
                <H2 color={Colors.blue}>Change History</H2>
              </div>

              <Row style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                {cartridgesOfWFChanges.map(cartridge => (
                  <Col
                    key={cartridge.id}
                    style={{ display: 'flex', alignItems: 'start', width: '70%' }}
                  >
                    <LineGray />
                    <Row
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: 5,
                        width: '100%',
                      }}
                    >
                      <H5 color={Colors.blue}>
                        {moment(cartridge.created_at).format('MM/DD/YYYY')}
                      </H5>
                      <H5 style={{ marginLeft: 3 }} color={Colors.blue}>
                        {cartridge.User ? cartridge.User.email : 'Unknown'}
                      </H5>
                    </Row>
                  </Col>
                ))}
              </Row>
            </>
          )}
        </Modal>

        <ModalDatePicker
          minDate={moment(installed_date).toDate()}
          isVisible={modalDatePickerConsumption}
          handleDateFrom={value => this.handleDate(value, 'datePickerFromConsumption')}
          handleDateTo={value => this.handleDate(value, 'datePickerToConsumption')}
          switchModal={() => this.switchModal('modalDatePickerConsumption')}
          dateFrom={datePickerFromConsumption}
          dateTo={datePickerToConsumption}
          saveChanges={() => {
            this.switchModal('modalDatePickerConsumption');
            this.calculeWaterFilterConsumption();
          }}
        />

        <ModalDatePicker
          minDate={moment(installed_date).toDate()}
          isVisible={modalDatePickerMaxFlowRate}
          handleDateFrom={value => this.handleDate(value, 'datePickerFromMaxFlowRate')}
          handleDateTo={value => this.handleDate(value, 'datePickerToMaxFlowRate')}
          switchModal={() => this.switchModal('modalDatePickerMaxFlowRate')}
          dateFrom={datePickerFromMaxFlowRate}
          dateTo={datePickerToMaxFlowRate}
          saveChanges={() => {
            this.switchModal('modalDatePickerMaxFlowRate');
            this.calculeWaterFilterMaxFlowRate();
          }}
        />

        <ModalDatePicker
          minDate={moment(installed_date).toDate()}
          isVisible={modalDatePickerBypass}
          handleDateFrom={value => this.handleDate(value, 'datePickerFromBypass')}
          handleDateTo={value => this.handleDate(value, 'datePickerToBypass')}
          switchModal={() => this.switchModal('modalDatePickerBypass')}
          dateFrom={datePickerFromBypass}
          dateTo={datePickerToBypass}
          saveChanges={() => {
            this.switchModal('modalDatePickerBypass');
            this.calculeWaterFilterBypass();
          }}
        />

        <ModalDatePicker
          minDate={moment(installed_date).toDate()}
          isVisible={modalDatePickerReport}
          handleDateFrom={value => this.handleDate(value, 'datePickerFromReport')}
          handleDateTo={value => this.handleDate(value, 'datePickerToReport')}
          switchModal={() => this.switchModal('modalDatePickerReport')}
          dateFrom={datePickerFromReport}
          dateTo={datePickerToReport}
          saveChanges={() => {
            this.switchModal('modalDatePickerReport');
            this.generateReport();
          }}
        />

        <Modal
          title="Update Device ID (Serial Number)"
          onClose={() => this.switchModal('modalDeviceId')}
          isVisible={modalDeviceId}
          size="small"
        >
          <Row>
            <Col>
              <Input
                placeholder="Device ID"
                value={new_device_id}
                onChange={this.handleChangeDeviceId}
                style={{ marginBottom: '20px' }}
              />
              <ButtonText
                text="Update"
                theme="primary"
                onClick={() =>
                  setDeviceId({ type: 'water-filter', device_id: new_device_id, location_id })
                }
              />
            </Col>
          </Row>
        </Modal>

        <Modal
          title="Bypass Flow"
          onClose={() => this.switchModal('modalBypass')}
          isVisible={modalBypass}
          size="medium"
        >
          {this.renderBypassConsumptions()}
        </Modal>

        {renderInstructionsModal(
          this.state.modalViewInstructions,
          () => this.switchModal('modalViewInstructions'),
          WatherFilterInstallSteps,
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    waterFilterCurrentState: state.locations.waterFilterCurrentState,
    waterFilterConsumption: state.locations.waterFilterConsumption,
    waterFilterConsumptionLoading: state.locations.waterFilterConsumptionLoading,
    waterFilterMaxFlowRate: state.locations.waterFilterMaxFlowRate,
    waterFilterMaxFlowRateLoading: state.locations.waterFilterMaxFlowRateLoading,
    waterFilterPressureForFlowRate: state.locations.waterFilterPressureForFlowRate,
    waterFilterPressureForFlowRateLoading: state.locations.waterFilterPressureForFlowRateLoading,
    waterFilterPressureForConsumption: state.locations.waterFilterPressureForConsumption,
    waterFilterPressureForConsumptionLoading:
      state.locations.waterFilterPressureForConsumptionLoading,
    totalConsumptionWaterFilterLoading: state.locations.totalConsumptionWaterFilterLoading,
    waterFiltercurrentStateLoading: state.locations.waterFiltercurrentStateLoading,
    waterFilterTotalConsumption: state.locations.waterFilterTotalConsumption,
    waterFilterBypass: state.locations.waterFilterBypass,
    waterFilterBypassLoading: state.locations.waterFilterBypassLoading,
    csvFiles: state.locations.csvFiles,
    csvFilesLoading: state.locations.csvFilesLoading,
    waterFilterCurrentPressure: state.locations.waterFilterCurrentPressure,
    waterFilterCurrentPressureLoading: state.locations.waterFilterCurrentPressureLoading,
    waterFilterCurrentBypass: state.locations.waterFilterCurrentBypass,
    waterFilterCurrentBypassLoading: state.locations.waterFilterCurrentBypassLoading,
    cartridgeTotalConsumptionWf: state.locations.cartridgeTotalConsumptionWf,
    cartridgeAllTotalConsumptionWf: state.locations.cartridgeAllTotalConsumptionWf,
    cartridgeTotalConsumptionWfLoading: state.locations.cartridgeTotalConsumptionWfLoading,
    cartridgeAllTotalConsumptionWfLoading: state.locations.cartridgeAllTotalConsumptionWfLoading,
    wf_for_pfd: state.locations.wf_for_pfd,
    role_id: state.auth.profile.role_id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    waterFilterSetInitialState: () => dispatch(waterFilterSetInitialState()),
    getWaterFilterCurrentState: params => dispatch(getWaterFilterCurrentState(params)),
    getWaterFilterCurrentPressure: params => dispatch(getWaterFilterCurrentPressure(params)),
    getWaterFilterConsumption: params => dispatch(getWaterFilterConsumption(params)),
    getWaterFilterMaxFlowRate: params => dispatch(getWaterFilterMaxFlowRate(params)),
    getWaterFilterPressureForConsumption: params =>
      dispatch(getWaterFilterPressureForConsumption(params)),
    getWaterFilterPressureForFlowRate: params =>
      dispatch(getWaterFilterPressureForFlowRate(params)),
    getWaterFilterBypass: params => dispatch(getWaterFilterBypass(params)),
    getTotalConsumptionWaterFilter: params => dispatch(getTotalConsumptionWaterFilter(params)),
    generateWaterFilterCsv: params => dispatch(generateWaterFilterCsv(params)),
    getCsvFiles: params => dispatch(getCsvFiles(params)),
    getWaterFilterCurrentBypass: params => dispatch(getWaterFilterCurrentBypass(params)),
    getTotalConsumptionCartridgeWf: params => dispatch(getTotalConsumptionCartridgeWf(params)),
    getAllTotalConsumptionCartridgeWf: params =>
      dispatch(getAllTotalConsumptionCartridgeWf(params)),
    replaceWaterFilterCartridges: params => dispatch(replaceWaterFilterCartridges(params)),
  };
}

WF.propTypes = {
  active: PropTypes.bool,
  allAlerts: PropTypes.array,
  cartridgeAllTotalConsumptionWf: PropTypes.array,
  cartridgeAllTotalConsumptionWfLoading: PropTypes.bool,
  cartridgeTotalConsumptionWf: PropTypes.array,
  cartridgeTotalConsumptionWfLoading: PropTypes.bool,
  csvFiles: PropTypes.array,
  csvFilesLoading: PropTypes.bool,
  device_id: PropTypes.string,
  fetchAllAlerts: PropTypes.func,
  generateWaterFilterCsv: PropTypes.func,
  getAllTotalConsumptionCartridgeWf: PropTypes.func,
  getCsvFiles: PropTypes.func,
  getTotalConsumptionCartridgeWf: PropTypes.func,
  getTotalConsumptionWaterFilter: PropTypes.func,
  getWaterFilterBypass: PropTypes.func,
  getWaterFilterConsumption: PropTypes.func,
  getWaterFilterCurrentBypass: PropTypes.func,
  getWaterFilterCurrentPressure: PropTypes.func,
  getWaterFilterCurrentState: PropTypes.func,
  getWaterFilterMaxFlowRate: PropTypes.func,
  getWaterFilterPressureForConsumption: PropTypes.func,
  getWaterFilterPressureForFlowRate: PropTypes.func,
  location: PropTypes.object,
  payload: PropTypes.array,
  renderInstructionsModal: PropTypes.func,
  replaceWaterFilterCartridges: PropTypes.func,
  role_id: PropTypes.number,
  setDeviceId: PropTypes.func,
  totalConsumptionWaterFilterLoading: PropTypes.bool,
  waterFilterBypass: PropTypes.array,
  waterFilterBypassLoading: PropTypes.bool,
  waterFilterConsumption: PropTypes.array,
  waterFilterConsumptionLoading: PropTypes.bool,
  waterFilterCurrentBypass: PropTypes.array,
  waterFilterCurrentBypassLoading: PropTypes.bool,
  waterFilterCurrentPressure: PropTypes.array,
  waterFilterCurrentPressureLoading: PropTypes.bool,
  waterFiltercurrentStateLoading: PropTypes.bool,
  waterFilterMaxFlowRate: PropTypes.array,
  waterFilterMaxFlowRateLoading: PropTypes.bool,
  waterFilterPressureForConsumption: PropTypes.array,
  waterFilterPressureForConsumptionLoading: PropTypes.bool,
  waterFilterPressureForFlowRate: PropTypes.array,
  waterFilterPressureForFlowRateLoading: PropTypes.bool,
  waterFilterSetInitialState: PropTypes.func,
  waterFilterTotalConsumption: PropTypes.number,
  wf_for_pfd: PropTypes.bool,
};
export default connect(mapStateToProps, mapDispatchToProps)(WF);
