import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import H1 from 'components/H1';
import H4 from 'components/H4';
import H5 from 'components/H5';
import Icon from 'components/Icon';
import Col from 'components/Col';
import ListEmpty from 'components/ListEmpty';

const StatCard = styled.div`
  background: white;
  border-radius: ${props => props.borderRadius || '12px'};
  padding: 20px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  border: 1px solid #ecebf0;

  &:hover {
    box-shadow: ${props =>
      props.onClick ? '0 4px 12px rgba(0, 0, 0, 0.1)' : '0 1px 6px rgba(0, 0, 0, 0.05)'};
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const StatTitle = styled(H4)`
  color: ${Colors.gray8};
  margin: 0 0 8px 0;
  font-weight: 400;
  font-size: 16px;
`;

const SubTitle = styled.span`
  color: ${Colors.gray6};
  font-size: 12px;
  font-weight: 400;
  margin-left: 6px;
`;

const StatValue = styled(H1)`
  font-size: 36px;
  line-height: 1;
  margin: 0;
  font-weight: 600;
  color: ${props => props.valueColor || Colors.black};
  display: inline-block;
`;

const StatPercent = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${props =>
    props.increase ? 'rgba(39, 174, 96, 0.1)' : 'rgba(235, 87, 87, 0.1)'};
  color: ${props => (props.increase ? Colors.green : Colors.red)};
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;
  vertical-align: middle;
`;

const StatPrevious = styled(H5)`
  color: ${Colors.gray7};
  margin: 6px 0 0 0;
  font-weight: 400;
  font-size: 14px;
`;

const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.bgColor || 'rgba(100, 161, 242, 0.1)'};
  margin-right: 16px;
  flex-shrink: 0;
`;

const DetailsLink = styled(H5)`
  color: ${Colors.lightBlue};
  text-decoration: none;
  margin-top: auto;
  padding-top: 5px;
  text-align: right;
  font-size: 14px;
`;

const QuickStats = ({
  icon,
  iconBgColor,
  title,
  subtitle,
  value,
  unit,
  percentChange,
  previousValue,
  previousLabel = 'Previous week',
  isLoading,
  isInstalled = true,
  onClick,
  borderRadius,
}) => {
  const isPercentPositive = percentChange > 0;

  return (
    <StatCard onClick={onClick} borderRadius={borderRadius}>
      {isLoading ? (
        <Col justifyContent="center" alignItems="center" style={{ height: '100%', width: '100%' }}>
          <Icon name="spinner" customStyle={{ fontSize: '30px' }} spin />
        </Col>
      ) : !isInstalled ? (
        <Col justifyContent="center" style={{ width: '100%' }}>
          <ListEmpty message="Installation Not Complete" />
        </Col>
      ) : (
        <>
          <IconContainer bgColor={iconBgColor}>
            <Icon name={icon} color={Colors.lightBlue} customStyle={{ fontSize: '20px' }} />
          </IconContainer>

          <ContentWrapper>
            <StatTitle>
              {title}
              {subtitle && <SubTitle>({subtitle})</SubTitle>}
            </StatTitle>

            <div>
              <StatValue>
                {value !== null ? value : 'N/A'}
                {unit && value !== null ? ` ${unit}` : ''}
              </StatValue>
              {percentChange !== undefined && (
                <StatPercent increase={isPercentPositive}>
                  {isPercentPositive ? '+' : ''}
                  {percentChange}%
                </StatPercent>
              )}
            </div>

            {previousValue !== undefined && (
              <StatPrevious>
                {previousLabel}: {previousValue}
              </StatPrevious>
            )}

            {onClick && <DetailsLink>Details →</DetailsLink>}
          </ContentWrapper>
        </>
      )}
    </StatCard>
  );
};

QuickStats.propTypes = {
  borderRadius: PropTypes.string,
  icon: PropTypes.string.isRequired,
  iconBgColor: PropTypes.string,
  isInstalled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  percentChange: PropTypes.number,
  previousLabel: PropTypes.string,
  previousValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  unit: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default QuickStats;
