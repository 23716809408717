import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Colors } from 'modules/theme';
import H1 from 'components/H1';
import H2 from 'components/H2';
import H3 from 'components/H3';
import H4 from 'components/H4';
import H5 from 'components/H5';
import VerticalLine from 'components/Line';
import Modal from 'components/Modal';
import Col from 'components/Col';
import Row from 'components/Row';
import Icon from 'components/Icon';
import BG from 'components/BG';
import Loader from 'components/Loader';
import ListEmpty from 'components/ListEmpty';
import Input from 'components/InputText';
import ButtonText from 'components/ButtonText';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  getSoftenerCurrentState,
  getSoftenerConsumption,
  getSoftenerPressure,
  getSoftenerBypass,
  getSoftenerWaste,
  softenerSetInitialState,
  generateSoftenerCsv,
  getCsvFiles,
  getConsumptionFromInstallSo,
  getSoftenerCurrentPressure,
} from 'actions/index';
import domtoimage from 'dom-to-image';
import {
  BarChart,
  Sector,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import PropTypes from 'prop-types';
import { ReportWS } from './ReportWS';
import { ModalDatePicker } from './ModalDatePicker';
import ModalCsvFiles from './ModalCsvFiles';

const Container = styled.div`
  margin: 0px;
`;

const GraphContainer = styled.div`
  width: 100%;
  position: relative;
  height: ${props => props.height}px;
`;

const startDate = moment()
  .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  .add(-7, 'days')
  .toDate();

const endDate = moment().toDate();

class WS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalWaterChart: '',
      wasteWaterChart: '',
      saltChart: '',
      generatePDF: false,
      downloadPDF: false,
      showConfiguration: false,
      datePickerFromConsumption: startDate,
      datePickerToConsumption: endDate,
      datePickerFromWaste: startDate,
      datePickerToWaste: endDate,
      modalDatePickerConsumption: false,
      modalDatePickerWaste: false,
      rangeConsumption: 'days',
      possibleDayRangesConsumption: ['days'],
      rangeWaste: 'days',
      possibleDayRangesWaste: ['days'],
      modalDatePickerCsv: false,
      datePickerFromCsv: startDate,
      datePickerToCsv: endDate,
      csvType: 1,
      csvTypeString: 'raw_data',
      modalDeviceId: false,
      new_device_id: '',
    };
  }

  componentDidMount() {
    const {
      location: {
        SoftenerFilterHead: { installed_date },
      },
      softenerSetInitialState,
    } = this.props;

    softenerSetInitialState();

    if (moment(startDate) < moment(installed_date)) {
      this.setState(
        {
          datePickerFromConsumption: moment(installed_date).toDate(),
          datePickerFromCsv: moment(installed_date).toDate(),
          datePickerFromWaste: moment(installed_date).toDate(),
        },
        () => this.getInitialData(),
      );
    } else {
      this.getInitialData();
    }
  }

  componentDidUpdate(prevProps) {
    const { softenerConsumption, softenerConsumptionLoading, updateLastUpdated } = this.props;

    if (
      prevProps.softenerConsumptionLoading &&
      !softenerConsumptionLoading &&
      softenerConsumption &&
      softenerConsumption.last_updated &&
      updateLastUpdated
    ) {
      updateLastUpdated(softenerConsumption.last_updated);
    }
  }

  getInitialData = () => {
    const {
      location: { SoftenerFilterHead },
      getSoftenerCurrentState,
      getSoftenerConsumption,
      getSoftenerPressure,
      getSoftenerWaste,
      getConsumptionFromInstallSo,
      getSoftenerCurrentPressure,
      setLastUpdatedLoading,
    } = this.props;
    const {
      device_id,
      SoftenerConfiguration: {
        Softener: { bypass_key },
      },
      installed_date,
    } = SoftenerFilterHead;

    if (device_id) {
      const {
        datePickerFromConsumption,
        datePickerToConsumption,
        datePickerFromWaste,
        datePickerToWaste,
        rangeWaste,
        rangeConsumption,
      } = this.state;

      if (setLastUpdatedLoading) {
        setLastUpdatedLoading();
      }

      getSoftenerCurrentPressure({ device_id, from: installed_date });
      getSoftenerCurrentState({ device_id, from: installed_date });
      getConsumptionFromInstallSo({ device_id, from: installed_date });

      getSoftenerConsumption({
        device_id,
        from: datePickerFromConsumption,
        to: datePickerToConsumption,
        range: rangeConsumption,
      });

      getSoftenerPressure({
        device_id,
        from: datePickerFromConsumption,
        to: datePickerToConsumption,
        range: rangeConsumption,
      });

      if (bypass_key !== 'N/A') {
        getSoftenerWaste({
          device_id,
          from: datePickerFromWaste,
          to: datePickerToWaste,
          range: rangeWaste,
        });
      }
    }
  };

  handleDate = (value, key) => {
    this.setState({ [key]: value });
  };

  possibleRanges = daysRange => {
    const possibleDayRanges = [];
    if (daysRange <= 1) possibleDayRanges.push('hours');
    if (daysRange >= 1 && daysRange < 15) possibleDayRanges.push('days');
    if (daysRange >= 14 && daysRange < 105) possibleDayRanges.push('weeks');
    if (daysRange >= 30 && daysRange < 450) possibleDayRanges.push('months');
    if (daysRange >= 365) possibleDayRanges.push('years');

    return possibleDayRanges;
  };

  generateSoftenerCsv = () => {
    const {
      generateSoftenerCsv,
      location: {
        id,
        SoftenerFilterHead: { device_id },
      },
    } = this.props;
    const { csvTypeString } = this.state;

    const { datePickerFromCsv: from, datePickerToCsv: to } = this.state;
    generateSoftenerCsv({ device_id, location_id: id, from, to, csvContains: csvTypeString });
  };

  changeCsvType = (csvType, nro) => {
    const csvTypes = ['raw_data', 'calculated_data', 'all_data'];

    this.setState({ [csvType]: nro, csvTypeString: csvTypes[nro - 1] });
  };

  calculeSoftenerConsumption = range => {
    const {
      getSoftenerConsumption,
      getSoftenerPressure,
      location: {
        SoftenerFilterHead: { device_id },
      },
    } = this.props;
    const { datePickerFromConsumption: from, datePickerToConsumption: to } = this.state;
    const fromDate = moment(from);
    const toDate = moment(to);
    const daysRange = toDate.diff(fromDate, 'days');
    const possibleDayRanges = this.possibleRanges(daysRange);

    const currentRange = range || possibleDayRanges[0];
    getSoftenerConsumption({ device_id, from, to, range: currentRange });
    getSoftenerPressure({ device_id, from, to, range: currentRange });

    this.setState({
      possibleDayRangesConsumption: possibleDayRanges,
      rangeConsumption: currentRange,
    });
  };

  calculeSoftenerBypass = range => {
    const {
      getSoftenerBypass,
      location: {
        SoftenerFilterHead: { device_id, installed_date },
      },
    } = this.props;
    const { datePickerFromBypass: from, datePickerToBypass: to } = this.state;

    const fromDate = moment(from);
    const toDate = moment(to);
    const daysRange = toDate.diff(fromDate, 'days');
    const possibleDayRangesBypass = this.possibleRanges(daysRange);

    const currentRange = range || possibleDayRangesBypass[0];
    getSoftenerBypass({ device_id, from, to, installed_date, range: currentRange });
    this.setState({ possibleDayRangesBypass, range: currentRange });
  };

  calculeSoftenerWaste = range => {
    const {
      getSoftenerWaste,
      location: {
        SoftenerFilterHead: { device_id },
      },
    } = this.props;
    const { datePickerFromWaste: from, datePickerToWaste: to } = this.state;
    const fromDate = moment(from);
    const toDate = moment(to);
    const daysRange = toDate.diff(fromDate, 'days');
    const possibleDayRanges = this.possibleRanges(daysRange);

    const currentRange = range || possibleDayRanges[0];
    getSoftenerWaste({ device_id, from, to, range: currentRange });
    this.setState({
      possibleDayRangesWaste: possibleDayRanges,
      rangeWaste: currentRange,
    });
  };

  downloadPDF = () => {
    const {
      totalWaterChart,
      saltChart,
      wasteWaterChart,
      totalGaloonsSum,
      downloadPDF,
      datePickerFromConsumption,
      datePickerToConsumption,
      datePickerFromWaste,
      datePickerToWaste,
    } = this.state;
    const {
      location,
      allAlerts,
      softenerCurrentState,
      softenerConsumption,
      softenerCurrentPressure,
    } = this.props;

    if (downloadPDF) {
      return (
        <PDFDownloadLink
          document={
            <ReportWS
              totalWaterChart={totalWaterChart}
              saltChart={saltChart}
              wasteWaterChart={wasteWaterChart}
              totalGaloonsSum={totalGaloonsSum}
              location={location}
              allAlerts={allAlerts}
              datePickerFromConsumption={datePickerFromConsumption}
              datePickerToConsumption={datePickerToConsumption}
              datePickerFromWaste={datePickerFromWaste}
              datePickerToWaste={datePickerToWaste}
              softenerConsumption={softenerConsumption}
              softenerCurrentState={softenerCurrentState}
              pressure={softenerCurrentPressure}
              saltLevelRemaining={this.calculeDataSaltPercent()}
            />
          }
          fileName="ReportWS.pdf"
        >
          {({ loading }) => (
            <H4
              color={Colors.lightBlue}
              style={{ cursor: 'pointer' }}
              onClick={() => this.setState({ generatePDF: false, downloadPDF: false })}
            >
              <Icon
                name={loading ? 'far fa-spinner' : 'far fa-file-download'}
                color={Colors.lightBlue}
                customStyle={{ marginRight: '5px' }}
              />
              {loading ? 'Loading' : 'Download'} Report
            </H4>
          )}
        </PDFDownloadLink>
      );
    }
    return (
      <Row>
        <Loader size={35} block />
        <H4 color={Colors.lightBlue} marginTop={5}>
          Generating PDF ...
        </H4>
      </Row>
    );
  };

  switchModal = name => {
    const isVisible = this.state[name];
    this.setState({ [name]: !isVisible });
  };

  handleChangeDeviceId = evt => {
    this.setState({ new_device_id: evt.target.value });
  };

  generateReport = async () => {
    try {
      this.setState({ generatePDF: true });

      let totalWaterChart = document.getElementById('totalWaterChart');
      let saltChart = document.getElementById('saltChart');
      let wasteWaterChart = document.getElementById('wasteWaterChart');
      let totalGaloonsSum = document.getElementById('totalGaloonsSum');

      totalWaterChart = totalWaterChart && (await domtoimage.toPng(totalWaterChart));
      saltChart = saltChart && (await domtoimage.toPng(saltChart));
      wasteWaterChart = wasteWaterChart && (await domtoimage.toPng(wasteWaterChart));
      totalGaloonsSum = totalGaloonsSum && (await domtoimage.toPng(totalGaloonsSum));

      this.setState({
        totalWaterChart,
        saltChart,
        wasteWaterChart,
        totalGaloonsSum,
        downloadPDF: true,
      });
    } catch (error) {
      this.setState({ generatePDF: false });
      console.log(error);
    }
  };

  chosseSaltColor = () => {
    const {
      location: {
        SoftenerFilterHead: { FlowMeterOfSofteners },
      },
      softenerCurrentState: { distance },
    } = this.props;
    const { good_salt_level, poor_salt_level } = FlowMeterOfSofteners[0].BrineDrum;

    if (distance <= good_salt_level) {
      return { color: Colors.green, icon: 'check-circle' };
    }
    if (distance > good_salt_level && distance < poor_salt_level) {
      return { color: Colors.yellow, icon: 'exclamation-circle' };
    }
    if (distance >= poor_salt_level) {
      return { color: Colors.red, icon: 'times-circle' };
    }
    return { color: Colors.green, icon: 'check-circle' };
  };

  calculeDataSaltPercent = () => {
    const {
      location: {
        SoftenerFilterHead: { FlowMeterOfSofteners },
      },
      softenerCurrentState: { distance },
    } = this.props;
    const { poor_salt_level } = FlowMeterOfSofteners[0].BrineDrum;
    const saltPercent = +((distance * 100) / poor_salt_level).toFixed(2);
    return (100 - saltPercent).toFixed(2);
  };

  calculeDataSaltGraph = () => {
    const {
      location: {
        SoftenerFilterHead: { FlowMeterOfSofteners },
      },
      softenerCurrentState: { distance },
    } = this.props;
    const { poor_salt_level } = FlowMeterOfSofteners[0].BrineDrum;

    let incomplete = +((distance * 100) / poor_salt_level).toFixed(2);

    let complete = 100 - incomplete >= 0 ? 100 - incomplete : 0;

    if (incomplete >= 100) {
      complete = 100;
      incomplete = 0;
    }

    return [{ name: 'saltLevel', complete, incomplete }];
  };

  renderActiveShape = props => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;
    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fontFamily="Gotham, Sans Serif"
          fontSize={25}
          fill={Colors.black}
        >
          {100 - payload.value}%
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  renderPressure = () => {
    const {
      location: {
        SoftenerFilterHead: { device_id },
      },
    } = this.props;

    // if (softenerPressureLoading) {
    //   return <Loader size={200} block />;
    // }

    if (device_id) {
      return (
        <></>
        /* <Col lg={50} md={50} sm={100} xs={100}>
          <H2 marginBottom={10}>Inlet Pressure</H2>
          <Row justifyContent="center">
            {/* <H1 color={Colors.blue} marginRight={15}>
              {softenerPressure}
            </H1>
            <H1 color={Colors.black}>PSI</H1>
            <ListEmpty message="" />
          </Row>
        </Col> */
      );
    }
    return (
      <Col lg={50} md={50} sm={100} xs={100}>
        <H2 marginLeft={10}>Pressure</H2>
        <Col justifyContent="center">
          <ListEmpty message="Installation Not Complete" />
        </Col>
      </Col>
    );
  };

  renderPressureeComented = () => {
    const {
      softenerCurrentPressure,
      softenerCurrentPressureLoading,
      location: {
        SoftenerFilterHead: { device_id },
      },
    } = this.props;

    const iconTime = <Icon name="weight" color={Colors.yellow} fontSize={22} />;
    return (
      <GraphContainer>
        <Row>
          {/* INLET pressure */}
          <Col lg={33} md={33} sm={100} xs={100} divisor>
            <H2 textAlign="center">Inlet Pressure</H2>
            {softenerCurrentPressureLoading ? (
              <Loader size={100} block />
            ) : !device_id ? (
              <Col justifyContent="center">
                <ListEmpty message="Installation Not Complete" />
              </Col>
            ) : (
              <Row>
                <Col lg={100} md={100} sm={100} xs={100}>
                  <H4>
                    <span style={{ marginRight: 20 }}> {iconTime} </span>
                    <span style={{ color: Colors.blue }}>
                      {Math.round(softenerCurrentPressure.inletPressure)}{' '}
                    </span>
                    PSI
                  </H4>
                </Col>
              </Row>
            )}
          </Col>
          {/* OUTLET Pressure */}
          <Col lg={33} md={33} sm={100} xs={100} divisor>
            <H2 textAlign="center">Outlet Pressure</H2>
            {softenerCurrentPressureLoading ? (
              <Loader size={100} block />
            ) : !device_id ? (
              <Col justifyContent="center">
                <ListEmpty message="Installation Not Complete" />
              </Col>
            ) : (
              <Row>
                <Col lg={100} md={100} sm={100} xs={100}>
                  <H4>
                    <span style={{ marginRight: 20 }}> {iconTime} </span>
                    <span style={{ color: Colors.blue }}>
                      {Math.round(softenerCurrentPressure.outletPressure)}{' '}
                    </span>
                    PSI
                  </H4>
                </Col>
              </Row>
            )}
          </Col>
          {/* DIFFERENTIAL Pressure */}
          <Col lg={33} md={33} sm={100} xs={100}>
            <H2 textAlign="center">Differential Pressure</H2>
            {softenerCurrentPressureLoading ? (
              <Loader size={100} block />
            ) : !device_id ? (
              <Col justifyContent="center">
                <ListEmpty message="Installation Not Complete" />
              </Col>
            ) : (
              <Row>
                <Col lg={100} md={100} sm={100} xs={100}>
                  <H4>
                    <span style={{ marginRight: 20 }}> {iconTime} </span>
                    <span style={{ color: Colors.blue }}>
                      {softenerCurrentPressure.inletPressure -
                        softenerCurrentPressure.outletPressure >=
                      0
                        ? Math.round(
                            (
                              softenerCurrentPressure.inletPressure -
                              softenerCurrentPressure.outletPressure
                            ).toFixed(2),
                          )
                        : 0}{' '}
                    </span>
                    PSI
                  </H4>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </GraphContainer>
    );
  };

  renderConfigurationModal = () => {
    const { showConfiguration } = this.state;
    const {
      location: {
        SoftenerFilterHead: { SoftenerConfiguration, description },
      },
    } = this.props;
    const { name } = SoftenerConfiguration;

    return (
      <Modal
        title="Configuration"
        onClose={() => this.switchModal('showConfiguration')}
        isVisible={showConfiguration}
      >
        <H4 lineHeight={2} textAlign="left" color={Colors.gray8} weight={325} style={{ margin: 0 }}>
          Configuration name
        </H4>
        <H4 lineHeight={2} color={Colors.black} weight={500} style={{ marginTop: 5 }}>
          {name}
        </H4>
        <H4 lineHeight={2} textAlign="left" color={Colors.gray8} weight={325} style={{ margin: 0 }}>
          Description
        </H4>
        <H4 lineHeight={2} color={Colors.black} weight={500} style={{ marginTop: 5 }}>
          {description}
        </H4>
      </Modal>
    );
  };

  

  renderConsumptions = () => (
    /*  const {
      location: { SoftenerFilterHead },
      softenerConsumption: { consumption },
      softenerPressure: { pressures },
    } = this.props;
 */
    /*  const CustomTooltip = ({ payload, active }) => {
      if (active && payload && payload[0]) {
        const currentPayload = payload[0].payload;
        return (
          <div
            style={{
              backgroundColor: 'white',
              padding: 1,
              borderRadius: 5,
              borderColor: 'gray',
              borderStyle: 'solid',
              borderWidth: 1,
            }}
          >
            <p style={{ color: 'gray' }}>{currentPayload.tooltipLabel}</p>
            <p style={{ color: 'gray' }}>{Math.round(currentPayload.gal)} Gal</p>
            <p style={{ color: 'gray' }}>
              Max PD: {Math.round(currentPayload.pressureDiferential)} PSI
            </p>
          </div>
        );
      }

      return null;
    }; */

    /*   const dataChart = consumption.map((consumption, index) => ({
      ...pressures[index],
      ...consumption,
    }));
 */
    <></>
    // coming soon
    /* <GraphContainer>
        <Row style={{ minHeight: 60, width: '50%', margin: 'auto' }}>
          <Col xs={100} md={100} sm={40} lg={40}>
            <H4 weight="bold" marginTop={5} style={{ letterSpacing: 1, lineHeight: 1 }}>
              Flow
            </H4>
            <H5 weight="bold" color={Colors.lightBlue} marginTop={5}>
              Period selected
            </H5>
          </Col>
          <Col xs={100} md={100} sm={40} lg={40}>
            <ListEmpty message="" />
          </Col>
        </Row>

        <Col style={{ height: 400 }} justifyContent="center">
          <ResponsiveContainer width="100%" height={285} id="totalWaterChart">
            <ListEmpty message="" />
          </ResponsiveContainer>
        </Col>
      </GraphContainer>/ */
    // end coming soon

    // <>
    //   {softenerConsumptionLoading ? (
    //     <GraphContainer style={{ height: 450 }}>
    //       <Loader size={200} block />
    //     </GraphContainer>
    //   ) : (
    //     <GraphContainer>
    //       <Row style={{ minHeight: 60, width: '50%', margin: 'auto' }}>
    //         <Col xs={100} md={100} sm={40} lg={40}>
    //           <H4 weight="bold" marginTop={5} style={{ letterSpacing: 1, lineHeight: 1 }}>
    //             Flow
    //           </H4>
    //           <H5 weight="bold" color={Colors.lightBlue} marginTop={5}>
    //             Period selected
    //           </H5>
    //         </Col>
    //         <Col xs={100} md={100} sm={40} lg={40}>
    //           <H2 color={Colors.blue}>{total_flow === 0 ? 'N/A' : total_flow}</H2>
    //         </Col>
    //         <Col xs={100} md={100} sm={20} lg={20}>
    //           <H2>gal</H2>
    //         </Col>
    //       </Row>

    //       {softenerPressureLoading && (
    //         <Row style={{ width: 200, margin: 'auto', height: 10 }}>
    //           <Loader size={15} block />
    //           <H5 color={Colors.lightBlue}>Loading max pressures...</H5>
    //         </Row>
    //       )}

    //       <Col style={{ height: 400 }} justifyContent="center">
    //         <ResponsiveContainer width="100%" height={285} id="totalWaterChart">
    //           {!device_id ? (
    //             <Col style={{ height: 400 }} justifyContent="center">
    //               <ListEmpty message="Installation Not Complete" />
    //             </Col>
    //           ) : (
    //             <ComposedChart
    //               data={dataChart}
    //               margin={{ right: 20, left: -15, bottom: 0 }}
    //               barSize={20}
    //             >
    //               <XAxis
    //                 dataKey="name"
    //                 stroke={Colors.gray}
    //                 tick={{ fontSize: 12 }}
    //                 scale="point"
    //                 padding={{ left: 15, top: 15 }}
    //               />
    //               <YAxis type="number" unit="" stroke={Colors.gray} tick={{ fontSize: 12 }} />
    //               <Tooltip content={<CustomTooltip />} />
    //               <CartesianGrid strokeDasharray="3 3" />
    //               <Bar dataKey="gal">
    //                 {consumption.map((entry, index) => (
    //                   <Cell
    //                     key={`cell-${index}`}
    //                     fill={Colors.lightBlue}
    //                     background={{ fill: 'transparent' }}
    //                   />
    //                 ))}
    //               </Bar>
    //               <Line dataKey="pressureDiferential" stroke="#006aff" animationDuration={3000} />
    //             </ComposedChart>
    //           )}
    //         </ResponsiveContainer>
    //         {last_updated && (
    //           <H5 color={Colors.gray8}>
    //             Last updated: {moment.unix(last_updated).format('h:mma MM/DD/YYYY')}
    //           </H5>
    //         )}
    //       </Col>
    //     </GraphContainer>
    //   )}
    // </>
  );

  render() {
    const {
      location: { SoftenerFilterHead, id: location_id },
      renderInstructionsModal,
      softenerConsumptionLoading,
      softenerWasteLoading,
      softenerCurrentStateLoading,
      softenerCurrentState,
      getCsvFiles,
      csvFiles,
      csvFilesLoading,
      setDeviceId,
      role_id,
    } = this.props;
    const { SoftenerConfiguration, device_id, installed_date } = SoftenerFilterHead;
    const {
      SoftenerInstallSteps,
      Softener: { bypass_key },
      name,
    } = SoftenerConfiguration;
    const {
      generatePDF,
      datePickerFromConsumption,
      datePickerToConsumption,
      modalDatePickerConsumption,
      datePickerFromWaste,
      datePickerToWaste,
      modalDatePickerWaste,
      modalDatePickerCsv,
      datePickerFromCsv,
      datePickerToCsv,
      csvType,
      new_device_id,
      modalDeviceId,
    } = this.state;

    const getDataCompleted =
      !softenerCurrentStateLoading && !softenerConsumptionLoading && !softenerWasteLoading;

    return (
      <Container>
        <Row>
          <Col lg={100} md={100} sm={100} xs={100}>
            <BG>
              <Row justifyContent="space-between">
                <H2 marginLeft={15}>Salt Level</H2>
                <Icon
                  name={this.chosseSaltColor().icon}
                  color={this.chosseSaltColor().color}
                  fontSize={25}
                />
              </Row>
              <GraphContainer style={{ minHeight: 110 }}>
                {softenerCurrentStateLoading ? (
                  <Loader size={200} block />
                ) : (
                  <Row>
                    {!device_id ? (
                      <Col justifyContent="center">
                        <ListEmpty message="Installation Not Complete" />
                      </Col>
                    ) : (
                      <>
                        <Col lg={50} md={50} sm={100} xs={100}>
                          <Row style={{ justifyContent: 'flex-start', paddingLeft: '10px' }}>
                            <ResponsiveContainer width={50} height={100} id="saltChart">
                              <BarChart
                                width={45}
                                height={110}
                                data={this.calculeDataSaltGraph()}
                                margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                              >
                                <Bar
                                  dataKey="complete"
                                  fill={this.chosseSaltColor().color}
                                  stackId="stack"
                                />
                                <Bar dataKey="incomplete" fill={Colors.grayChart} stackId="stack" />
                              </BarChart>
                            </ResponsiveContainer>
                            <H1
                              color={Colors.blue}
                              style={{ marginLeft: '20px', marginRight: '20px' }}
                            >
                              {softenerCurrentState.distance}
                            </H1>
                            <H1>inches</H1>
                          </Row>
                        </Col>
                        <Col lg={50} md={50} sm={100} xs={100}>
                          <Row justifyContent="center">
                            <H1 color={this.chosseSaltColor().color} marginRight={15}>
                              {this.calculeDataSaltPercent()}%
                            </H1>
                            <H2 color={Colors.gray}>remaining</H2>
                          </Row>
                        </Col>
                      </>
                    )}
                  </Row>
                )}
              </GraphContainer>
            </BG>
            
          </Col>
        </Row>

        {/* <BG>{this.renderPressureeComented()}</BG> */}

        <ModalDatePicker
          minDate={moment(installed_date).toDate()}
          isVisible={modalDatePickerConsumption}
          handleDateFrom={value => this.handleDate(value, 'datePickerFromConsumption')}
          handleDateTo={value => this.handleDate(value, 'datePickerToConsumption')}
          switchModal={() => this.switchModal('modalDatePickerConsumption')}
          dateFrom={datePickerFromConsumption}
          dateTo={datePickerToConsumption}
          saveChanges={() => {
            this.switchModal('modalDatePickerConsumption');
            this.calculeSoftenerConsumption();
          }}
        />

        <ModalDatePicker
          minDate={moment(installed_date).toDate()}
          isVisible={modalDatePickerWaste}
          handleDateFrom={value => this.handleDate(value, 'datePickerFromWaste')}
          handleDateTo={value => this.handleDate(value, 'datePickerToWaste')}
          switchModal={() => this.switchModal('modalDatePickerWaste')}
          dateFrom={datePickerFromWaste}
          dateTo={datePickerToWaste}
          changeCsvType={this.changeCsvType}
          csvType={csvType}
          saveChanges={() => {
            this.switchModal('modalDatePickerWaste');
            this.calculeSoftenerWaste();
          }}
        />

        <ModalCsvFiles
          minDate={moment(installed_date).toDate()}
          isVisible={modalDatePickerCsv}
          handleDateFrom={value => this.handleDate(value, 'datePickerFromCsv')}
          handleDateTo={value => this.handleDate(value, 'datePickerToCsv')}
          switchModal={() => this.switchModal('modalDatePickerCsv')}
          dateFrom={datePickerFromCsv}
          dateTo={datePickerToCsv}
          changeCsvType={this.changeCsvType}
          csvType={csvType}
          saveChanges={() => {
            this.switchModal('modalDatePickerCsv');
            this.generateSoftenerCsv();
          }}
          csvFiles={csvFiles}
          csvFilesLoading={csvFilesLoading}
        />

        <Modal
          title="Update Device ID (Serial Number)"
          onClose={() => this.switchModal('modalDeviceId')}
          isVisible={modalDeviceId}
          size="small"
        >
          <Row>
            <Col>
              <Input
                placeholder="Device ID"
                value={new_device_id}
                onChange={this.handleChangeDeviceId}
                style={{ marginBottom: '20px' }}
              />
              <ButtonText
                text="Update"
                theme="primary"
                onClick={() =>
                  setDeviceId({ type: 'softener', device_id: new_device_id, location_id })
                }
              />
            </Col>
          </Row>
        </Modal>

        {this.renderConfigurationModal()}
        {renderInstructionsModal(
          this.state.modalViewInstructions,
          () => this.switchModal('modalViewInstructions'),
          SoftenerInstallSteps,
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    softenerCurrentState: state.locations.softenerCurrentState,
    softenerCurrentStateLoading: state.locations.softenerCurrentStateLoading,
    softenerConsumption: state.locations.softenerConsumption,
    softenerConsumptionLoading: state.locations.softenerConsumptionLoading,
    softenerPressure: state.locations.softenerPressure,
    softenerPressureLoading: state.locations.softenerPressureLoading,
    softenerBypass: state.locations.softenerBypass,
    softenerBypassLoading: state.locations.softenerBypassLoading,
    softenerWaste: state.locations.softenerWaste.waste,
    softenerWasteLoading: state.locations.softenerWasteLoading,
    csvFiles: state.locations.csvFiles,
    csvFilesLoading: state.locations.csvFilesLoading,
    consumptionFromInstallSoLoading: state.locations.consumptionFromInstallSoLoading,
    consumptionFromInstallSo: state.locations.consumptionFromInstallSo,
    softenerCurrentPressure: state.locations.softenerCurrentPressure,
    softenerCurrentPressureLoading: state.locations.softenerCurrentPressureLoading,
    role_id: state.auth.profile.role_id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSoftenerCurrentState: params => dispatch(getSoftenerCurrentState(params)),
    getSoftenerConsumption: params => dispatch(getSoftenerConsumption(params)),
    getSoftenerPressure: params => dispatch(getSoftenerPressure(params)),
    getSoftenerBypass: params => dispatch(getSoftenerBypass(params)),
    getSoftenerWaste: params => dispatch(getSoftenerWaste(params)),
    softenerSetInitialState: () => dispatch(softenerSetInitialState()),
    generateSoftenerCsv: params => dispatch(generateSoftenerCsv(params)),
    getCsvFiles: params => dispatch(getCsvFiles(params)),
    getConsumptionFromInstallSo: params => dispatch(getConsumptionFromInstallSo(params)),
    getSoftenerCurrentPressure: params => dispatch(getSoftenerCurrentPressure(params)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(WS);

WS.propTypes = {
  active: PropTypes.bool,
  allAlerts: PropTypes.array,
  csvFiles: PropTypes.array,
  csvFilesLoading: PropTypes.bool,
  generateSoftenerCsv: PropTypes.func,
  getConsumptionFromInstallSo: PropTypes.func,
  getCsvFiles: PropTypes.func,
  getSoftenerBypass: PropTypes.func,
  getSoftenerConsumption: PropTypes.func,
  getSoftenerCurrentPressure: PropTypes.func,
  getSoftenerCurrentState: PropTypes.func,
  getSoftenerPressure: PropTypes.func,
  getSoftenerWaste: PropTypes.func,
  location: PropTypes.object,
  payload: PropTypes.object,
  renderInstructionsModal: PropTypes.func,
  role_id: PropTypes.number,
  setDeviceId: PropTypes.func,
  softenerBypass: PropTypes.object,
  softenerBypassLoading: PropTypes.bool,
  softenerConsumption: PropTypes.object,
  softenerConsumptionLoading: PropTypes.bool,
  softenerCurrentPressure: PropTypes.object,
  softenerCurrentPressureLoading: PropTypes.bool,
  softenerCurrentState: PropTypes.object,
  softenerCurrentStateLoading: PropTypes.bool,
  softenerSetInitialState: PropTypes.func,
  softenerWasteLoading: PropTypes.bool,
};
