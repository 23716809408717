import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Colors } from 'modules/theme';
import Input from 'components/InputText';
import Select from 'components/Select';
import ButtonText from 'components/ButtonText';
import H2 from 'components/H2';
import H4 from 'components/H4';
import H5 from 'components/H5';
import FormError from 'components/FormError';
import Line from 'components/Line';
import PropTypes from 'prop-types';
import Switch from 'components/Switch';
import ListEmpty from '../../components/ListEmpty';
import Icon from '../../components/Icon';

const InputContainer = styled.div`
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Column = styled.div`
  flex-direction: column;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

// Add a styled container for the Select field animations
const SelectWrapper = styled.div`
  position: relative;

  &.highlight-active {
    & > div {
      border: 1px solid #4caf50;
      box-shadow: 0 0 5px #4caf50;
      transition: all 0.3s ease-in;
    }
  }

  &.highlight-inactive > div {
    transition: all 1s ease-out;
  }
`;

const FixedFooter = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: white;
  padding: 15px 30px;
  border-top: 1px solid ${Colors.gray2};
  display: flex;
  justify-content: flex-end;
  z-index: 10;
`;

export default class DetailsForm extends Component {
  state = {
    selectedUser: 'new_user',
    highlightState: false,
    highlightCity: false,
  };

  handleSelectChange = event => {
    const { value } = event.target;
    this.setState({ selectedUser: value });
  };

  // Function to trigger animation with a more gradual fade
  highlightField = fieldName => {
    if (fieldName === 'state_id') {
      this.setState({ highlightState: true });
      setTimeout(() => this.setState({ highlightState: false }), 2000);
    } else if (fieldName === 'city_id') {
      this.setState({ highlightCity: true });
      setTimeout(() => this.setState({ highlightCity: false }), 2000);
    }
  };

  handleZipCodeChange = (event, setFieldValue, handleChange) => {
    // Call the regular onChange handler first
    handleChange(event);

    const zipCode = event.target.value;

    // Only proceed if we have a valid 5-digit zip code
    if (zipCode && zipCode.length === 5 && /^\d{5}$/.test(zipCode)) {
      // Create mapping of state abbreviations to full names
      const stateMap = {
        AL: 'Alabama',
        AK: 'Alaska',
        AZ: 'Arizona',
        AR: 'Arkansas',
        CA: 'California',
        CO: 'Colorado',
        CT: 'Connecticut',
        DE: 'Delaware',
        FL: 'Florida',
        GA: 'Georgia',
        HI: 'Hawaii',
        ID: 'Idaho',
        IL: 'Illinois',
        IN: 'Indiana',
        IA: 'Iowa',
        KS: 'Kansas',
        KY: 'Kentucky',
        LA: 'Louisiana',
        ME: 'Maine',
        MD: 'Maryland',
        MA: 'Massachusetts',
        MI: 'Michigan',
        MN: 'Minnesota',
        MS: 'Mississippi',
        MO: 'Missouri',
        MT: 'Montana',
        NE: 'Nebraska',
        NV: 'Nevada',
        NH: 'New Hampshire',
        NJ: 'New Jersey',
        NM: 'New Mexico',
        NY: 'New York',
        NC: 'North Carolina',
        ND: 'North Dakota',
        OH: 'Ohio',
        OK: 'Oklahoma',
        OR: 'Oregon',
        PA: 'Pennsylvania',
        RI: 'Rhode Island',
        SC: 'South Carolina',
        SD: 'South Dakota',
        TN: 'Tennessee',
        TX: 'Texas',
        UT: 'Utah',
        VT: 'Vermont',
        VA: 'Virginia',
        WA: 'Washington',
        WV: 'West Virginia',
        WI: 'Wisconsin',
        WY: 'Wyoming',
        DC: 'District of Columbia',
      };

      // Use fetch to get state/city from a free ZIP code API
      fetch(`https://api.zippopotam.us/us/${zipCode}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('ZIP code not found');
          }
          return response.json();
        })
        .then(data => {
          if (data && data.places && data.places.length > 0) {
            // Get state and city info from the API response
            const { states, fetchCities } = this.props;
            const stateAbbr = data.places[0]['state abbreviation'];
            const stateName = stateMap[stateAbbr] || '';
            const cityName = data.places[0]['place name'];

            // Find the state in the options by matching either the label or value
            const stateObj = states.find(state => {
              if (state.abbreviation && state.abbreviation === stateAbbr) return true;
              if (state.label && state.label.includes(stateAbbr)) return true;
              if (state.label && stateName && state.label.includes(stateName)) return true;
              return false;
            });

            if (stateObj) {
              // Set the state value
              setFieldValue('state_id', stateObj.value);

              // Trigger animation for state field
              this.highlightField('state_id');

              // Fetch cities for the selected state
              fetchCities(stateObj.value);

              // Wait a moment for cities to be fetched then find and set the city
              setTimeout(() => {
                const { cities } = this.props;

                // Try to find the city by name
                const cityObj = cities.find(
                  city => city.label.toLowerCase() === cityName.toLowerCase(),
                );

                if (cityObj) {
                  setFieldValue('city_id', cityObj.value);

                  // Trigger animation for city field
                  this.highlightField('city_id');
                }
              }, 1000);
            }
          }
        })
        .catch(error => {
          console.error('Error fetching ZIP code data:', error);
        });
    }
  };

  componentDidMount() {
    const { initialValues, isEditing } = this.props;

    this.setState({
      selectedUser: isEditing
        ? initialValues.admins.filter(admin => !admin.deleted).length === 0
          ? 'add_later'
          : ''
        : 'new_user',
    });
  }

  submit = values => {
    const { onSubmit } = this.props;
    const activeTab = 'Configuration';

    onSubmit(values, activeTab);
  };

  render() {
    const {
      initialValues,
      brands,
      accounts,
      cities,
      states,
      fetchCities,
      fetchAccounts,
      isEditing,
      getAdminsBrandsLocations,
      additionalOptions,
    } = this.props;
    const { selectedUser, highlightState, highlightCity } = this.state;

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={this.submit}
        validationSchema={Yup.object().shape({
          account_id: Yup.string().required('Required'),
          address_line: Yup.string().required('Required'),
          admin_email: Yup.string().when('createNewUser', {
            is: true,
            then: Yup.string()
              .required('Required')
              .email('Invalid Format'),
            otherwise: Yup.string().notRequired(),
          }),
          admin_first_name: Yup.string().when('createNewUser', {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().notRequired(),
          }),
          admin_last_name: Yup.string().when('createNewUser', {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().notRequired(),
          }),
          admin_phone_number: Yup.string().when('createNewUser', {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().notRequired(),
          }),
          admin_position: Yup.string().when('createNewUser', {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().notRequired(),
          }),
          brand_id: Yup.string().required('Required'),
          city_id: Yup.string().required('Required'),
          contact_email: Yup.string().when('isPointOfContact', {
            is: false,
            then: Yup.string()
              .required('Required')
              .email('Invalid Format'),
            otherwise: Yup.string().email('Invalid Format'),
          }),
          contact_first_name: Yup.string().when('isPointOfContact', {
            is: false,
            then: Yup.string().required('Required'),
            otherwise: Yup.string(),
          }),
          contact_last_name: Yup.string().when('isPointOfContact', {
            is: false,
            then: Yup.string().required('Required'),
            otherwise: Yup.string(),
          }),
          contact_phone_number: Yup.string().when('isPointOfContact', {
            is: false,
            then: Yup.string().required('Required'),
            otherwise: Yup.string(),
          }),
          contact_position: Yup.string().when('isPointOfContact', {
            is: false,
            then: Yup.string().required('Required'),
            otherwise: Yup.string(),
          }),
          createNewUser: Yup.boolean(),
          identificator: Yup.string().required('Required'),
          isPointOfContact: Yup.boolean(),
          state_id: Yup.string().required('Required'),
          zip_code: Yup.string()
            .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, {
              message: 'Invalid zipcode',
            })
            .required('Required'),
        })}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
          <Form style={{ paddingBottom: '80px' }}>
            <Row style={{ marginTop: '20px' }}>
              <Column style={{ marginRight: '15px' }} />
              <Column style={{ marginLeft: '15px' }}>
                <H2 inputLabel color={Colors.lightBlue}>
                  Location admin
                </H2>
                <H5
                  color={Colors.blue}
                  textAlign="left"
                  style={{
                    padding: '5px',
                    paddingLeft: '15px',
                    backgroundColor: '#F0F6F9',
                    marginTop: '5px',
                  }}
                >
                  <span style={{ fontWeight: 'bold' }}>
                    The location admin would have access to the administrator platform to check the
                    devices status.
                  </span>
                  You can add the location admin later.
                </H5>
              </Column>
            </Row>
            <Row>
              <Column style={{ marginRight: '15px' }}>
                <Row>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Location ID
                    </H4>
                    <Input
                      placeholder="Location ID"
                      name="identificator"
                      value={values.identificator}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="bookmark"
                    />
                    <FormError
                      text={errors.identificator && touched.identificator && errors.identificator}
                    />
                  </InputContainer>
                </Row>
                <Row>
                  <Column style={{ paddingRight: '10px', paddingTop: '15px' }}>
                    <H4 inputLabel color={Colors.gray8}>
                      Brand
                    </H4>
                    <Select
                      placeholder="Brand"
                      name="brand_id"
                      options={brands}
                      value={values.brand_id}
                      onChange={evt => {
                        fetchAccounts({ brands: [evt.target.value] });
                        handleChange(evt);
                      }}
                      onBlur={handleBlur}
                    />
                    <FormError text={errors.brand_id && touched.brand_id && errors.brand_id} />
                  </Column>
                  <Column style={{ marginLeft: '10px', paddingTop: '15px' }}>
                    <H4 inputLabel color={Colors.gray8}>
                      Account
                    </H4>
                    <Select
                      placeholder="Account"
                      name="account_id"
                      options={accounts}
                      value={values.account_id}
                      onChange={evt => {
                        getAdminsBrandsLocations(evt.target.value);
                        handleChange(evt);
                      }}
                      onBlur={handleBlur}
                    />
                    <FormError
                      text={errors.account_id && touched.account_id && errors.account_id}
                    />
                  </Column>
                  <Row />
                </Row>
                <Row>
                  <InputContainer style={{ marginLeft: '10px', paddingTop: '15px' }}>
                    <H4 inputLabel color={Colors.gray8}>
                      Address line
                    </H4>
                    <Input
                      placeholder="Address line"
                      name="address_line"
                      value={values.address_line}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="map-marker-alt"
                    />
                    <FormError
                      text={errors.address_line && touched.address_line && errors.address_line}
                    />
                  </InputContainer>
                </Row>
                <Row>
                  <InputContainer style={{ marginLeft: '10px', paddingTop: '15px' }}>
                    <H4 inputLabel color={Colors.gray8}>
                      Address line 2
                    </H4>
                    <Input
                      placeholder="Address line 2"
                      name="address_line_1"
                      value={values.address_line_1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="map-marker-alt"
                    />
                  </InputContainer>
                </Row>
                <Row>
                  <Column style={{ marginLeft: '10px', paddingTop: '15px' }}>
                    <H4 inputLabel color={Colors.gray8}>
                      State
                    </H4>
                    <SelectWrapper
                      className={highlightState ? 'highlight-active' : 'highlight-inactive'}
                    >
                      <Select
                        placeholder="State"
                        name="state_id"
                        options={states}
                        value={values.state_id}
                        onChange={evt => {
                          fetchCities(evt.target.value);
                          handleChange(evt);
                        }}
                        onBlur={handleBlur}
                      />
                    </SelectWrapper>
                    <FormError text={errors.state_id && touched.state_id && errors.state_id} />
                  </Column>
                  <Column style={{ marginLeft: '10px', paddingTop: '15px' }}>
                    <H4 inputLabel color={Colors.gray8}>
                      City
                    </H4>
                    <SelectWrapper
                      className={highlightCity ? 'highlight-active' : 'highlight-inactive'}
                    >
                      <Select
                        placeholder="City"
                        name="city_id"
                        options={cities}
                        value={values.city_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </SelectWrapper>
                    <FormError text={errors.city_id && touched.city_id && errors.city_id} />
                  </Column>
                </Row>
                <Row>
                  <Column style={{ marginLeft: '10px', paddingTop: '15px' }}>
                    <H4 inputLabel color={Colors.gray8}>
                      ZIP
                    </H4>
                    <Input
                      placeholder="ZIP"
                      name="zip_code"
                      type="number"
                      value={values.zip_code}
                      onChange={e => this.handleZipCodeChange(e, setFieldValue, handleChange)}
                      onBlur={handleBlur}
                      icon="map-marker-alt"
                    />
                    <FormError text={errors.zip_code && touched.zip_code && errors.zip_code} />
                  </Column>
                </Row>
              </Column>
              <Column style={{ marginLeft: '15px' }}>
                <div style={{ paddingTop: '26px' }}>
                  {isEditing ? (
                    <FieldArray
                      name="admins"
                      render={arrayHelpers => (
                        <div>
                          {values.admins.map((admin, index) => {
                            if (admin.deleted) return false;
                            return (
                              <Row
                                key={index}
                                style={{
                                  padding: '5px 0',
                                }}
                              >
                                <Column
                                  style={{
                                    width: '90%',
                                  }}
                                >
                                  <Input disabled value={admin.label} />
                                </Column>
                                <Column
                                  style={{
                                    width: '10%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Icon
                                    color={Colors.red}
                                    name="trash"
                                    style={{ cursor: 'pointer' }}
                                    fontSize={17}
                                    onClick={() => {
                                      const currentAdmins = values.admins;
                                      if (admin.new) {
                                        arrayHelpers.remove(index);
                                      } else {
                                        arrayHelpers.replace(index, {
                                          ...admin,
                                          deleted: true,
                                        });
                                      }
                                      const hasActiveAdmins = currentAdmins.some(
                                        (admin, i) => i !== index && !admin.deleted,
                                      );
                                      if (!hasActiveAdmins) {
                                        setFieldValue('isPointOfContact', false);
                                      }
                                    }}
                                  />
                                </Column>
                              </Row>
                            );
                          })}
                          {values.admins.filter(admin => !admin.deleted).length === 0 && (
                            <Select
                              placeholder="Select option..."
                              options={[
                                { label: 'New User', value: 'new_user' },
                                { label: 'Add later', value: 'add_later' },
                                ...additionalOptions,
                              ]}
                              name="select_user"
                              value={selectedUser}
                              onChange={event => {
                                this.handleSelectChange(event);
                                const optionSelected = [event.target.value];
                                if (optionSelected.includes('new_user')) {
                                  setFieldValue('createNewUser', true);
                                } else if (optionSelected.includes('add_later')) {
                                  setFieldValue('createNewUser', false);
                                  setFieldValue('isPointOfContact', false);
                                  setFieldValue('location_admin_id', null);
                                } else {
                                  setFieldValue('createNewUser', false);
                                  setFieldValue(
                                    'location_admin_id',
                                    parseInt(event.target.value, 10),
                                  );
                                }
                              }}
                              onBlur={handleBlur}
                            />
                          )}
                        </div>
                      )}
                    />
                  ) : (
                    <Select
                      placeholder="Select option..."
                      options={[
                        { label: 'New User', value: 'new_user' },
                        { label: 'Add later', value: 'add_later' },
                        ...additionalOptions,
                      ]}
                      name="select_user"
                      value={selectedUser}
                      onChange={event => {
                        this.handleSelectChange(event);
                        const optionSelected = [event.target.value];
                        if (optionSelected.includes('new_user')) {
                          setFieldValue('createNewUser', true);
                        } else if (optionSelected.includes('add_later')) {
                          setFieldValue('createNewUser', false);
                          setFieldValue('isPointOfContact', false);
                          setFieldValue('location_admin_id', null);
                        } else {
                          setFieldValue('createNewUser', false);
                          setFieldValue('location_admin_id', parseInt(event.target.value, 10));
                        }
                      }}
                      onBlur={handleBlur}
                    />
                  )}
                </div>
                {selectedUser === 'new_user' && (
                  <>
                    <H4 inputLabel color={Colors.gray8}>
                      First name
                    </H4>
                    <Input
                      placeholder="First name"
                      name="admin_first_name"
                      value={values.admin_first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="user"
                    />
                    <FormError
                      text={
                        errors.admin_first_name &&
                        touched.admin_first_name &&
                        errors.admin_first_name
                      }
                    />
                    <H4 inputLabel color={Colors.gray8} style={{ paddingTop: '15px' }}>
                      Last name
                    </H4>
                    <Input
                      placeholder="Last name"
                      name="admin_last_name"
                      value={values.admin_last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="user"
                    />
                    <FormError
                      text={
                        errors.admin_last_name && touched.admin_last_name && errors.admin_last_name
                      }
                    />
                    <H4 inputLabel color={Colors.gray8} style={{ paddingTop: '15px' }}>
                      Email
                    </H4>
                    <Input
                      placeholder="Email"
                      name="admin_email"
                      value={values.admin_email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="envelope"
                    />
                    <FormError
                      text={errors.admin_email && touched.admin_email && errors.admin_email}
                    />
                    <H4 inputLabel color={Colors.gray8} style={{ paddingTop: '15px' }}>
                      Phone number
                    </H4>
                    <Input
                      placeholder="Phone number"
                      name="admin_phone_number"
                      value={values.admin_phone_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="phone"
                    />
                    <FormError
                      text={
                        errors.admin_phone_number &&
                        touched.admin_phone_number &&
                        errors.admin_phone_number
                      }
                    />
                    <H4 inputLabel color={Colors.gray8} style={{ paddingTop: '15px' }}>
                      Position
                    </H4>
                    <Input
                      placeholder="Position"
                      name="admin_position"
                      value={values.admin_position}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="stream"
                    />
                    <FormError
                      text={
                        errors.admin_position && touched.admin_position && errors.admin_position
                      }
                    />
                  </>
                )}

                <div style={{ marginTop: '15px', padding: '15px', backgroundColor: '#FBFBFB' }}>
                  <H4 style={{ fontWeight: 'bold', paddingBottom: '10px' }} color={Colors.black2}>
                    Point of contact
                  </H4>
                  {selectedUser === 'add_later' ? (
                    <H5
                      color={Colors.blue}
                      textAlign="left"
                      style={{
                        padding: '5px',
                        paddingLeft: '15px',
                        backgroundColor: '#F0F6F9',
                        marginTop: '5px',
                      }}
                    >
                      If you are not creating a location admin, location point of contact
                      information must be entered.
                    </H5>
                  ) : (
                    <Switch
                      name="isPointOfContact"
                      isChecked={values.isPointOfContact}
                      titleChecked="Use location admin as point of contact"
                      titleUnchecked="Use location admin as point of contact"
                      onChange={handleChange}
                    />
                  )}

                  {!values.isPointOfContact && (
                    <div style={{ paddingTop: '15px' }}>
                      <H4 inputLabel color={Colors.gray8}>
                        First name
                      </H4>
                      <Input
                        placeholder="First name"
                        name="contact_first_name"
                        value={values.contact_first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        icon="user"
                      />
                      <FormError
                        text={
                          errors.contact_first_name &&
                          touched.contact_first_name &&
                          errors.contact_first_name
                        }
                      />

                      <H4 inputLabel color={Colors.gray8} style={{ paddingTop: '15px' }}>
                        Last name
                      </H4>
                      <Input
                        placeholder="Last name"
                        name="contact_last_name"
                        value={values.contact_last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        icon="user"
                      />
                      <FormError
                        text={
                          errors.contact_last_name &&
                          touched.contact_last_name &&
                          errors.contact_last_name
                        }
                      />

                      <H4 inputLabel color={Colors.gray8} style={{ paddingTop: '15px' }}>
                        Email
                      </H4>
                      <Input
                        placeholder="Email"
                        name="contact_email"
                        value={values.contact_email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        icon="envelope"
                      />
                      <FormError
                        text={errors.contact_email && touched.contact_email && errors.contact_email}
                      />

                      <H4 inputLabel color={Colors.gray8} style={{ paddingTop: '15px' }}>
                        Phone number
                      </H4>
                      <Input
                        placeholder="Phone number"
                        name="contact_phone_number"
                        value={values.contact_phone_number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        icon="phone"
                      />
                      <FormError
                        text={
                          errors.contact_phone_number &&
                          touched.contact_phone_number &&
                          errors.contact_phone_number
                        }
                      />
                      <H4 inputLabel color={Colors.gray8} style={{ paddingTop: '15px' }}>
                        Position
                      </H4>
                      <Input
                        placeholder="Position"
                        name="contact_position"
                        value={values.contact_position}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        icon="stream"
                      />
                      <FormError
                        text={
                          errors.contact_position &&
                          touched.contact_position &&
                          errors.contact_position
                        }
                      />
                    </div>
                  )}
                </div>
              </Column>
            </Row>
            <Row style={{ marginTop: '30px' }}>
              <Line />
            </Row>
            <FixedFooter>
              <ButtonText text="Next" theme="primary" type="submit" width="120px" />
            </FixedFooter>
          </Form>
        )}
      </Formik>
    );
  }
}

DetailsForm.propTypes = {
  accounts: PropTypes.array,
  brands: PropTypes.array,
  cities: PropTypes.array,
  fetchAccounts: PropTypes.func,
  fetchCities: PropTypes.func,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  states: PropTypes.array,
};
